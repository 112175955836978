import { render, staticRenderFns } from "./PhoneNumberText.vue?vue&type=template&id=627c2602&scoped=true"
import script from "./PhoneNumberText.vue?vue&type=script&lang=js"
export * from "./PhoneNumberText.vue?vue&type=script&lang=js"
import style0 from "./PhoneNumberText.vue?vue&type=style&index=0&id=627c2602&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "627c2602",
  null
  
)

export default component.exports