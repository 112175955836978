
  import Vue from 'vue';

  export default Vue.extend({
    props: {
      value: { type: String, default: null },
      rounded: { type: Boolean, default: true },
    },
    data() {
      return {
        months: [
          { name: this.$t('months.january').toString(), value: '01' },
          { name: this.$t('months.february').toString(), value: '02' },
          { name: this.$t('months.march').toString(), value: '03' },
          { name: this.$t('months.april').toString(), value: '04' },
          { name: this.$t('months.may').toString(), value: '05' },
          { name: this.$t('months.june').toString(), value: '06' },
          { name: this.$t('months.july').toString(), value: '07' },
          { name: this.$t('months.august').toString(), value: '08' },
          { name: this.$t('months.september').toString(), value: '09' },
          { name: this.$t('months.october').toString(), value: '10' },
          { name: this.$t('months.november').toString(), value: '11' },
          { name: this.$t('months.december').toString(), value: '12' }
        ],
        translations: {
          selectMonth: this.$t('filters.messages.selectMonth').toString(),
        }
      }
    },
    computed: {
    selectedMonth: {
      get(): string | null {
        return this.value;
      },
      set(value: string | null): void {
        this.$emit("input", value);
      },
    },
  },
  })
  