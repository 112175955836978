export default {
  pointOfSale: {
    data: {
      id: "Point of sale {pointOfSaleId}",
      title: {
        pointOfSale: "Point of Sale",
        pointsOfSale: "Points of Sale"
      }
    },
    posManagement: {
      help: {
        title: "Point of Sale Management",
        description: `
        <p>
          On this page, you can view and manage the Points of Sale (POS) and payment terminals associated with your environment. The page is divided into two main tabs: Points of Sale and Terminals.
        </p>
        <p>
          In the <strong>Points of Sale</strong> tab, you will find the complete list of registered POS, including the POS ID, payment system, POS code, machine model, responsible customer, location, distribution center, creation date and serial number. You can also edit or delete the POS information in addition to downloading this information in Excel.
        </p>
        <p>
          In the <strong>Terminals</strong> tab, all registered payment terminals are displayed, even if they are not associated with a POS. The information includes the machine ID, associated POS (if any), machine model, version (for machines linked to a POS), serial number, and terminal password. Use the edit or delete features to efficiently manage your terminals.
        </p>`,
      },
      remove:"Are you sure you want to remove the point of sale?",
      incomplete: "This POS has pending registration",
      feedbackError: "The POS could not be removed",
    },
    boxes:{
      title: "Boxes",
      notFoundMasterBarcode: "Admin code not found",
    }
  },
};
