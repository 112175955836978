export default{
  ruptureReports:{
    help:{
      description:`
      <p>
        Aqui são exibidos os relatórios de rupturas, por produto pontos de vendas e ocorrências relacionadas a cada item de inventário. Independente das caracteristicas individuais de cada relatório, é exibida uma <strong>estimativa</strong> de possíveis vendas perdidas, em <strong>unidades</strong> e <strong>valor</strong>.
      </p>
      <p>
        O intervalo de <strong>datas</strong> presente no filtro, determinará em qual intervalo de datas se encontra o <strong>início</strong> da ruptura.
      </p>
      <p>
        Cada <strong>ruptura</strong>(ocorrência), que posteriormente é tratada em diferentes relatórios, aplica-se a seguinte fórmula:
      </p>
      <ul align="justify">
        <li>
          O momento em que se <strong>inicia</strong> a ruptura, é determinado por uma movimentação de estoque de venda, em que o resultado final do estoque do item é zero.
        </li>
        <li>
          O momento em que a ruptura é <strong>finalizada</strong>, é determinado por uma movimentação de estoque que resulte em uma quantidade maior do que zero (pode ser inventário ou abastecimento). Destaca-se, que se caso o item não tenha sido abastecido, tal fim é o exato presente.
        </li>
        <li>
          Com o início e fim da ruptura, calcula-se o tempo de <strong>indisponibilidade</strong> do item.
        </li>
        <li>
          A quantidade de possíveis vendas <strong>perdidas</strong>, é calculada com base na média dos últimos 7 dias que antecedem o início de cada ruptura. Tal valor, é multiplicado pelo tempo de <strong>indisponibilidade</strong>. Destaca-se, que na situação que ocorrer uma média que não complete um valor inteiro, será considerado <strong>Vendas Perdidas</strong> como zero.
        </li>
        <li>
          O <strong>valor</strong> de possíveis vendas perdidas, é a multiplicação de unidades que deixou de vender, pelo valor praticado no último planograma em que o item se encontra.
        </li>
      </ul>
      <p></p>`
    },
    title: "Relatórios de Rupturas",
    products:{
      title: "Produtos",
      help:{
        description:`
        <p>
          Aqui são exibidas as ocorrências <strong>agrupadas por produto</strong>. Sendo para cada um destes, é  exibida uma <strong>estimativa</strong> de possíveis vendas perdidas.
        </p>
        <p>A <strong>pesquisa</strong>, filtra por: Id, Código e Descrição.</p>
        <p>
          <strong>Tabela:</strong>
        </p>
        <ul align="justify">
          <li><strong>Id</strong>: indica o id do produto.</li>
          <li><strong>Código</strong>: indica o código cadastrado junto ao produto.</li>
          <li><strong>Descrição</strong>: é a descrição cadastrada junto ao produto.</li>
          <li><strong>Ocorrências</strong>: representa o total de vezes, em que o produto teve alguma ruptura em algum estoque.</li>
          <li><strong>Total Tempo(Dias)</strong>: representa o total de tempo de indisponibilidade do produto. Tal valor, é a soma de todos os intervalos das rupturas de tal produto. </li>
          <li><strong>Total Perdas(Un.)</strong>: é a soma da estimativa de possíveis vendas perdidas em unidades de cada ocorrência.</li>
          <li><strong>Total Perdas({sign})</strong>: é a soma da estimativa de possíveis venda perdidas em valor de cada ocorrência.</li>
        </ul>`
      }
    },
    pointOfSales:{
      title: "PDVs",
      help:{
        description:`
        <p>
          Aqui são exibidas as ocorrências <strong>agrupadas por PDVs</strong>. Sendo para cada um destes, é exibida uma <strong>estimativa</strong> de possíveis vendas perdidas.
        </p>
        <p>A <strong>pesquisa</strong>, filtra por: Informações PDV.</p>
        <p>
          <strong>Tabela:</strong>
        </p>
        <ul align="justify">
          <li> <strong>Informações PDV</strong>: representa as informações do PDV, da seguinte maneira: Id do PDV - Cliente - Local.</li>
          <li><strong>Ocorrências</strong>: representa o total de vezes, em que o produto teve alguma ruptura em algum estoque.</li>
          <li><strong>Total Tempo(Dias)</strong>: representa o total de tempo de indisponibilidade do produto. Tal valor, é a soma de todos os intervalos das rupturas de tal produto.</li>
          <li><strong>Total Perdas(Un.)</strong>: é a soma da estimativa de possíveis vendas perdidas em unidades de cada ocorrência.</li>
          <li> <strong>Total Perdas({sign})</strong>: é a soma da estimativa de possíveis venda perdidas em valor de cada ocorrência. </li>
        </ul>`
      }
    },
    occurrences:{
      title: "Ocorrências",
      help:{
        description:`
        <p>
          Aqui são exibidas as ocorrência por item de inventário de estoque. Sendo para cada um destes, é exibida uma <strong>estimativa</strong> de possíveis vendas perdidas.
        </p>
        <p>A <strong>pesquisa</strong>, filtra por: Id. Item, Cód. Produto, Descrição e Estoque. </p>
        <p>Os botões para filtro de rupturas <strong>Fechadas</strong> e <strong>Abertas</strong>, foram adicionados por comodidade para uma rapida pesquisa de rupturas abertas e fechadas. Para o mesmo efeito no relaório de Excel, sugere-se utilizar o filtro de Data-Fim.</p>
        <p><strong>Tabela:</strong></p>
        <ul align="justify">
          <li><strong>Id. Item</strong>: indica o item de estoque.</li>
          <li><strong>Cód. Produto</strong>: indica o código cadastrado junto ao produto.</li>
          <li> <strong>Descrição</strong>: é a descrição cadastrada junto ao produto.</li>
          <li><strong>Estoque</strong>: é o nome do estoque do PDV.</li>
          <li><strong>Data-Início</strong>: indica o início da ruptura (quando o estoque do item, teve resultado 0).</li>
          <li><strong>Data-Fim</strong>: indica o fim da ruptura, caso ainda esteja aberta, estará vazio e para fim de calculos é usado o momento atual.</li>
          <li><strong>Tempo(Dias)</strong>: representa a diferença em dias (de forma decimal), entre data de início e fim da ruptura.</li>
          <li><strong>Perdas(Un.)</strong>: é a estimativa de possíveis vendas perdidas em unidades.</li>
          <li><strong>Perdas({sign})</strong>: é a estimativa de possíveis venda perdidas em valor.</li>
        </ul>`
      },
      status:{
        closed: "Fechadas",
        open: "Abertas"
      },
    }
  },
}
