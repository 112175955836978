import Vue from 'vue';
import VueRouter from 'vue-router';
import Transactions from '@/views/transactions/Transactions.vue';
import Telemetry from '@/views/Telemetry.vue';
import ManageMachine from '@/views/posRegistration/PosMaster.vue';
import PointOfSale from '@/views/PointOfSale.vue';
import Swagger from '@/components/Swagger.vue';
import NewBox from '@/components/NewBox.vue';
import Alerts from '@/views/alerts/Alerts.vue';
import Charts from '@/report/Charts.vue';
import PagSeguro from '@/views/PagSeguro.vue';
import ProductsMaster from '@/views/products/ProductsMaster.vue';
import InventoryMaster from '@/views/inventory/InventoryMaster.vue';
import Purchases from '@/views/purchases/Purchases.vue';
import Contracts from '@/views/Contracts.vue';
import PlanogramMaster from '@/planogram/PlanogramMaster.vue';
import PickLists from '@/views/picklist/PickLists.vue';
import DiscountsMaster from '@/discounts/DiscountsMaster.vue';
import Operations from '@/views/operations/Operations.vue';
import Losses from '@/views/losses/Losses.vue';
import Orders from '@/views/orders/Orders.vue';
import AccessControle from '@/views/accesscontrol/AccessControl.vue';
import Support from '@/views/support/Support.vue';
import SuspiciousMaster from '@/views/suspicious/SuspiciousMaster.vue';
import Users from '@/views/users/Users.vue';
import TransactionsReports from '@/views/dynamicReports/TransactionsReports.vue';
import InventoryReports from '@/views/dynamicReports/InventoryReports.vue';
import Downloads from '@/views/Downloads.vue';
import TaxingBrazil from '@/views/taxings/Taxing.vue';
import TaxGroupProducts from '@/views/taxings/TaxGroupProducts.vue';
import UsersFeedbacksMaster from '@/views/UsersFeedback/UsersFeedbacksMaster.vue';
import Notifications from '@/views/Notifications.vue';
import MobileUsers from '@/views/mobileUsers/MobileUsers.vue';
import Ruptures from '@/views/ruptures/RupturesMaster.vue';
import PriceTables from "@/views/priceTables/PriceTables.vue";
import PriceTableItems from "@/views/priceTables/components/PriceTableItems.vue";
import ReleaseNotes from '@/views/ReleaseNotes.vue';
import CustomersMaster from '@/views/customers/CustomersMaster.vue';
import Audits from '@/views/audits/AuditsMaster.vue';
import PurchaseSuggestions from '@/views/purchaseSuggestions/PurchaseSuggestions.vue';
import ConnectivityMaster from '@/views/connectivity/ConnectivityMaster.vue';
import PrivateLabel from '@/views/privateLabel/PrivateLabelMaster.vue';
import MyBillsMaster from "@/views/billing/myBills/MyBillsMaster.vue";
import PromotionalLogos from "@/views/promotionalLogos/PromotionalLogos.vue";
import InvoicesMaster from "@/views/invoices/InvoicesMaster.vue";
import ViewFranchiseeWeb from "@/views/franchisee/ViewFranchiseeWeb.vue";
import TenantsFeedbacks from "@/views/tenantsFeedback/TenantsFeedbacks.vue";
import BuildingManagerReports from "@/views/buildingManagerReports/BuildingManagerReports.vue";
import NewDashboard from "@/views/dashboard-new/NewDashboard.vue";
import AcquisitionsOrder from '@/views/acquisitionOrders/AcquisitionOrderView.vue';
import AcquisitionsOrdersApproval from "@/views/acquisitionOrdersApproval/AcquisitionsOrdersApprovalView.vue";
import ChangeOwnershipRequests from '@/views/changeOfOwnership/ChangeOwnershipRequests.vue';
import ChangeOwnershipNewRequest from '@/views/ChangeOwnershipRequest/ChangeOwnershipNewRequest.vue';
import CustomerProfile from '@/views/CustomerProfile.vue';
import MissingProducts from '@/views/missingProduct/MissingProducts.vue';

import i18n from '@/locales/i18n';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: i18n.t('routes.redirect').toString(),
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameTransactions'),
    name: i18n.t('routes.nameTransactions').toString(),
    component: Transactions,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameTelemetry'),
    name: i18n.t('routes.nameTelemetry').toString(),
    component: Telemetry,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameConnectivityMaster'),
    name: i18n.t('routes.nameConnectivityMaster').toString(),
    component: ConnectivityMaster,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameManagePos'),
    name: i18n.t('routes.nameManagePos').toString(),
    component: ManageMachine,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameProductsMaster'),
    name: i18n.t('routes.nameProductsMaster').toString(),
    component: ProductsMaster,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameInventoryMaster'),
    name: i18n.t('routes.nameInventoryMaster').toString(),
    component: InventoryMaster,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.namePurchases'),
    name: i18n.t('routes.namePurchases').toString(),
    component: Purchases,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameOrders'),
    name: i18n.t('routes.nameOrders').toString(),
    component: Orders,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameNewPointOfSale'),
    name: i18n.t('routes.nameNewPointOfSale').toString(),
    component: PointOfSale,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameToEditPointOfSale') + '/:posId',
    name: i18n.t('routes.nameToEditPointOfSale').toString(),
    component: PointOfSale,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.namePriceTables'),
    name: i18n.t('routes.namePriceTables').toString(),
    component: PriceTables,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.namePriceTables') + '/:id',
    name: i18n.t('routes.namePriceTableItems').toString(),
    component: PriceTableItems,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.namePlanogramMaster'),
    name: i18n.t('routes.namePlanogramMaster').toString(),
    component: PlanogramMaster,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameCharts'),
    name: i18n.t('routes.nameCharts').toString(),
    component: Charts,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameTransactionsReports'),
    name: i18n.t('routes.nameTransactionsReports').toString(),
    component: TransactionsReports,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameInventoryReports'),
    name: i18n.t('routes.nameInventoryReports').toString(),
    component: InventoryReports,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameRuptures'),
    name: i18n.t('routes.nameRuptures').toString(),
    component: Ruptures,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameLosses'),
    name: i18n.t('routes.nameLosses').toString(),
    component: Losses,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.namePickLists'),
    name: i18n.t('routes.namePickLists').toString(),
    component: PickLists,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameReplenishment'),
    name: i18n.t('routes.nameReplenishment').toString(),
    component: Operations,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameAccessControl'),
    name: i18n.t('routes.nameAccessControl').toString(),
    component: AccessControle,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.namePrivateLabel'),
    name: i18n.t('routes.namePrivateLabel').toString(),
    component: PrivateLabel,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameDiscountsMaster'),
    name: i18n.t('routes.nameDiscountsMaster').toString(),
    component: DiscountsMaster,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameNewBox'),
    name: i18n.t('routes.nameNewBox').toString(),
    component: NewBox,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameAlerts'),
    name: i18n.t('routes.nameAlerts').toString(),
    component: Alerts,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameDownloads'),
    name: i18n.t('routes.nameDownloads').toString(),
    component: Downloads,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameContracts'),
    name: i18n.t('routes.nameContracts').toString(),
    component: Contracts,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameSupport'),
    name: i18n.t('routes.nameSupport').toString(),
    component: Support,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameReleaseNotes'),
    name: i18n.t('routes.nameReleaseNotes').toString(),
    component: ReleaseNotes,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameAudits'),
    name: i18n.t('routes.nameAudits').toString(),
    component: Audits,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameTaxScenarios'),
    name: i18n.t('routes.nameTaxScenarios').toString(),
    component: TaxingBrazil,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameTaxScenarios') + '/:id',
    name: i18n.t('routes.nameTaxScenariosItems').toString(),
    component: TaxGroupProducts,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.namePagSeguro'),
    name: i18n.t('routes.namePagSeguro').toString(),
    component: PagSeguro,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameSwagger'),
    name: i18n.t('routes.nameSwagger').toString(),
    component: Swagger,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameSuspiciousMaster'),
    name: i18n.t('routes.nameSuspiciousMaster').toString(),
    component: SuspiciousMaster,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameUsers'),
    name: i18n.t('routes.nameUsers').toString(),
    component: Users,
    meta: { auth: true}
  },
  {
    path: '/' + i18n.t('path.nameUsersFeedbacks'),
    name: i18n.t('routes.nameUsersFeedbacks').toString(),
    component: UsersFeedbacksMaster,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameMobileUsers'),
    name: i18n.t('routes.nameMobileUsers').toString(),
    component: MobileUsers,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameNotifications'),
    name: i18n.t('routes.nameNotifications').toString(),
    component: Notifications,
    meta: { auth: true}
  },
  {
    path: '/' + i18n.t('path.nameCustomersMaster'),
    name: i18n.t('routes.nameCustomersMaster').toString(),
    component: CustomersMaster,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.namePurchaseSuggestions'),
    name: i18n.t('routes.namePurchaseSuggestions').toString(),
    component: PurchaseSuggestions,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameDashboard'),
    name: i18n.t('routes.nameDashboard').toString(),
    component: NewDashboard,
    meta: { auth: true }
  },
  {

    path: '/' + i18n.t('path.nameMyBills'),
    name: i18n.t('routes.nameMyBills').toString(),
    component: MyBillsMaster,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.namePromotionalLogos'),
    name: i18n.t('routes.namePromotionalLogos').toString(),
    component: PromotionalLogos,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameInvoicesMaster'),
    name: i18n.t('routes.nameInvoicesMaster').toString(),
    component: InvoicesMaster,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameViewFranchiseeWeb'),
    name: i18n.t('routes.nameViewFranchiseeWeb').toString(),
    component: ViewFranchiseeWeb,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameTenantsFeedbacks'),
    name: i18n.t('routes.nameTenantsFeedbacks').toString(),
    component: TenantsFeedbacks,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameCondominiumReports'),
    name: i18n.t('routes.nameCondominiumReports').toString(),
    component: BuildingManagerReports,
    meta: { auth: true}
  },
  {
    path: '/' + i18n.t('path.nameMidasOrders'),
    name: i18n.t('routes.nameMidasOrders').toString(),
    component: AcquisitionsOrder,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameViewAcquisitionsOrders'),
    name: i18n.t('routes.nameViewAcquisitionsOrders').toString(),
    component: AcquisitionsOrdersApproval,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameChangeOfOwnership'),
    name: i18n.t('routes.nameChangeOfOwnership').toString(),
    component: ChangeOwnershipRequests,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameChangeOwnershipNewRequest'),
    name: i18n.t('routes.nameChangeOwnershipNewRequest').toString(),
    component: ChangeOwnershipNewRequest,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameCustomerProfile'),
    name: i18n.t('routes.nameCustomerProfile').toString(),
    component: CustomerProfile,
    meta: { auth: true }
  },
  {
    path: '/' + i18n.t('path.nameMissingProducts'),
    name: i18n.t('routes.nameMissingProducts').toString(),
    component: MissingProducts,
    meta: { auth: true }
  },

]

const router = new VueRouter({
  routes
})

router.afterEach((to) => {
  (router as any).$awsRum?.recordPageView(to.path);
});

export default router
