import i18n from '@/locales/i18n';
const monthNameConvertions = {}

monthNameConvertions['1'] = i18n.t("months.january");
monthNameConvertions['2'] = i18n.t("months.february");
monthNameConvertions['3'] = i18n.t("months.march");
monthNameConvertions['4'] = i18n.t("months.april");
monthNameConvertions['5'] = i18n.t("months.may");
monthNameConvertions['6'] = i18n.t("months.june");
monthNameConvertions['7'] = i18n.t("months.july");
monthNameConvertions['8'] = i18n.t("months.august");
monthNameConvertions['9'] = i18n.t("months.september");
monthNameConvertions['10'] = i18n.t("months.october");
monthNameConvertions['11'] = i18n.t("months.november");
monthNameConvertions['12'] = i18n.t("months.december");

export { monthNameConvertions };
