<template>
  <v-card class="mb-16">
    <news
      v-if="isBrazil"
    />
    <portal to="toolbarTitle"><InventoryReportsHelp/></portal>

    <InventoryReportsFilters
      @table="acquireTable"
      @excel="acquireExcel"
      :loadingExcelStop="loadingExcelStop"
    />

    <v-card-title>
      <v-spacer></v-spacer>
      <v-form
        ref="form"
        lazy-validation
        @submit.prevent="getDynamicInventories">

        <v-text-field
          class="mr-2 pt-0"
          v-model="search.text"
          append-icon="search"
          :label="$t('search')"
          single-line
          hide-details
          clearable
          filled
          rounded
          dense
          @click:clear="search.text = ''; getDynamicInventories()"
          @click:append="options.page = 1; getDynamicInventories()"
          @input="options.page = 1"
        ></v-text-field>
      </v-form>
    </v-card-title>
    <v-divider></v-divider>

    <v-data-table
      :loading="loading.inventoryReports"
      :headers="dynamicHeaders"
      :items="inventoryReports"
      :options.sync="options"
      :server-items-length="totalItems"
      :footer-props="{ itemsPerPageOptions: [10, 20, 30, 50, 100] }"
      :no-data-text="$t('tables.noData')"
    >
      <template v-slot:[`item.ProductCategory`]="{ item }">
        {{ item.ProductCategory ? item.ProductCategory : '-' }}
      </template>
      <template v-slot:[`item.ProductDescription`]="{ item }">
        {{ item.ProductDescription ? item.ProductDescription : '-' }}
      </template>
      <template v-slot:[`item.SumCost`]="{ item }">
        {{ $currencyFormatter.format(item.SumCost) }}
      </template>
      <template v-slot:[`item.Type`]="{ item }">
        <v-chip :color="getColor(item.Type)" :dark="isDarkChip(item.Type)">{{ formatStatus(item.Type) }}</v-chip>
      </template>
      <template v-slot:[`item.Day`]="{ item }">

        {{ displayDateFormatted($moment.utc(item.Day), country, false, DateTimePrecision.DateOnly)}}
      </template>
      <template v-slot:[`item.Month`]="{ item }">
        {{ monthNameConvertions[item.Month] }}
      </template>
      <template v-slot:[`item.DayOfWeek`]="{ item }">
        {{ weekDayTranslations[item.DayOfWeek] }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Vue from "vue";
import InventoryReportsFilters from "./components/InventoryReportsFilters.vue";
import { shouldRefreshData, toApiPagination } from "@/components/TableOptionsComparator";
import { monthNameConvertions } from '@/constants/Months';
import { weekDayTranslations } from '@/constants/WeekDays';
import { allInventoryOperationTypes } from '@/constants/InventoryOperationTypes';
import { getColorInventoryTransactionStatus } from '@/views/inventory/utility/inventoryUtility.js';
import download from '@/components/Download';
import News from '@/components/News.vue';
import InventoryReportsHelp from './components/InventoryReportsHelp.vue';
import { displayDateFormatted } from "@/utility/TextFormatting";
import { DateTimePrecision }  from '@/constants/enums'

export default Vue.extend({
  components: {
    InventoryReportsHelp,
    InventoryReportsFilters,
    News
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getDynamicInventories();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      loadingExcelStop: false,
      inventoryReports: [],
      totals: {},
      dynamicHeaders: [],
      weekDayTranslations,
      monthNameConvertions,
      allInventoryOperationTypes,
      groupings: {
        product: {
          columns: [
            { text: this.$t('tables.headers.productCode'), value: "ProductCode" },
            { text: this.$t('tables.headers.productDescription'), value: "ProductDescription" }
          ]
        },
        category: {
          columns: [
            { text: this.$t('tables.headers.category'), value: "ProductCategory" },
          ]
        },
        inventory: {
          columns: [
            { text: this.$t('tables.headers.inventoryId'), value: "InventoryId" },
            { text: this.$t('tables.headers.inventory'), value: "Inventory" },
          ]
        },
        type: {
          columns: [
            { text: this.$t('tables.headers.operationType'), value: "Type" }
          ]
        },
        day: {
          columns: [
            { text: this.$t('tables.headers.day'), value: "Day" }
          ]
        },
        month: {
          columns: [
            { text: this.$t('tables.headers.month'), value: "Month" }
          ]
        },
        dayOfWeek: {
          columns: [
            { text: this.$t('tables.headers.dayOfWeek'), value: "DayOfWeek" }
          ]
        }
      },
      totalizers: [
        { text: this.$t('tables.headers.qty'), align: 'left', sortable: true, value: 'Count'},
        // { text: 'Valor Total', align: 'left', sortable: true, value: 'SumPrice'},
        { text: this.$t('tables.headers.totalCost'), align: 'left', sortable: true, value: 'SumCost'}
      ],
      form: {},
      search: {
        text: "",
        valid: true
      },
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["SumCost"],
        sortDesc: [true],
        mustSort: true,
      },
      loading: {
        inventoryReports: false
      },
    }
  },
  methods: {
    acquireExcel(form) {
      this.updateFilters(form);
      this.getDynamicInventoriesExcel();
    },
    acquireTable(form) {
      this.updateFilters(form);
      this.getDynamicInventories()
    },
    updateFilters(form) {
      this.form = form;
      this.options.page = 1;
      this.options.sortBy = ["SumCost"];
      this.options.sortDesc = [true];
    },
    getGroupings() {
      return this.form.group.map((value) => this.groupings[value]);
    },
    getUniqueColumns(groupings) {
      const columnValues = groupings
        .flatMap((g) => g.columns
          .map((c) => c.value ));
      return [...new Set(columnValues)];
    },
    getDynamicInventories() {
      this.loading.inventoryReports = true;

        const groupings = this.getGroupings();
        const uniqueColumns = this.getUniqueColumns(groupings);

        const params = toApiPagination(this.options, this.search.text);
        this.insertFiltersFromForm(params, uniqueColumns);

        this.axios
          .get("/api/DynamicReports/InventoryTransactions", { params })
          .then((response) => {
            this.loading.inventoryReports = false;
            this.inventoryReports = response.data.items;
            this.totalItems = response.data.totalItems;
            this.totals = response.data.totals;
            this.dynamicHeaders = groupings
              .flatMap((g) => g.columns
                .map((c) => ({text: c.text, value: c.value })));
            this.dynamicHeaders
              .push(...this.totalizers);
          });
    },
    getDynamicInventoriesExcel() {
      const groupings = this.getGroupings();
      const uniqueColumns = this.getUniqueColumns(groupings);

      const params = {
        minDate: this.form.minDate,
        maxDate: this.form.maxDate,
        groupColumns: uniqueColumns,
      };

      this.insertFiltersFromForm(params, uniqueColumns);

      this.axios
        .get("api/DynamicReports/InventoryTransactions/Excel", { params, responseType: 'blob' })
          .then((response) => {
            this.loadingExcelStop = !this.loadingExcelStop
            download(response)
          }).catch(() => {
            this.loadingExcelStop = !this.loadingExcelStop
          });
    },
    insertFiltersFromForm(params, columnValues) {
      const form = this.form;

      params.minDate = form.minDate;
      params.maxDate = form.maxDate;
      params.groupColumns = columnValues;

      params.inventoryId = form.inventoryId;
      params.type = form.type;
      params.productCategoryId = form.productCategoryId;
      params.productId = form.productId;

      return params;
    },
    getColor(status) {
      return getColorInventoryTransactionStatus(status)[0];
    },
    isDarkChip(status) {
      return getColorInventoryTransactionStatus(status)[1];
    },
    formatStatus(status) {
      return allInventoryOperationTypes[status]
    },
    displayDateFormatted

  },
  computed: {
    DateTimePrecision() {
      return DateTimePrecision
    },
    isBrazil() {
      return this.$auth.user().tenantCountry == 'BR';
    },
    country(){
      return this.$auth.user().tenantCountry;
    }
  }
});
</script>
