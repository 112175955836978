export default {
  security: {
    deviationAlert: {
      title: "Alertas de Desvíos",
      suspiciousAccess: {
        title: "Accesos Sospechosos",
      },
      suspiciousTransactions: {
        title: "Transacciones Sospechosas",
        accessDetailsDialogSubtitle: "Transacciones sospechosas en {date} de {timeStart} a {timeEnd}"
      },
      videoAlerts: {
        title: "Alertas de Video",
        videos: "Videos",
      },
      messages: {
        tokenNotActivated: `Este entorno no tiene habilitada la sincronización de transacciones con el adquirente.
          Por lo tanto, puede haber transacciones con resultados que difieren del estado de cuenta del adquirente.
          Para habilitar la sincronización de transacciones, comuníquese con nuestro soporte.`,
        success: {
          updateStatusTransaction: "Estado de transacción actualizado con éxito",
          downloadRealizeSuccess: "La descarga está en marcha y se podrá descargar pronto",
        },
        error: {
          choosePosInitialSearch: "Elija un punto de venta para iniciar la búsqueda",
        },
      },
      tooltips: {
        relatedSuspiciuosTransactions: "Transacciones sospechosas relacionadas",
      },
      resultDeviationStatus: {
        pending: "Pendiente",
        verifiedOk: "Verificado Ok",
        deviation: "Tiene Desvío",
        refunded: "Reembolsado",
        lost: "Perdido",
      },
      help: {
        description: `
          <p>
            Aquí se muestran las ventas realizadas, datos importantes para el control de desvíos y si la compra fue cancelada o no.
            Es posible acceder a los videos de las transacciones y configurar el estado de verificación del desvío.
          </p>
          <p>
            <strong>Alertas de Video:</strong>
          </p>
          <ul>
            <li>
              Puede acceder a las grabaciones de cada cámara de PDV por separado.
            </li>
          </ul>
          <br>
          <p>
            <strong>Transacciones Sospechosas:</strong>
          </p>
          <ul>
            <li>
              En transacciones sospechosas, tiene una vista de todos los intentos de transacción en el PDV, mostrando transacciones canceladas
              <i class="mdi mdi-minus-circle success--text" style="font-size:22px"></i>
              y transacciones pagadas
              <i class="mdi mdi-check-circle error--text" style="font-size:22px"></i>;
            </li>
            <li>
              Puede ver más detalles de los intentos haciendo clic para expandir la transacción, donde puede ver los productos
              que se añadieron al carrito en el momento de la compra, junto con los productos que fueron eliminados posteriormente, marcados en rojo;
            </li>
            <li>
              Las transacciones aparecerán por defecto con el estado de verificación "Pendiente", y dependiendo de la acción que seleccione,
              este estado cambiará. Las acciones son:
              <ul>
                <li>
                  <i class="mdi mdi-check-circle success--text" style="font-size:22px"></i>
                  : indica que no hubo desvíos y la transacción está bien.
                </li>
                <li>
                  <i class="mdi mdi-alert error--text" style="font-size:22px"></i>
                  : indica que se identificó un desvío.
                </li>
                <li>
                  <i class="mdi mdi-cash-multiple success--text" style="font-size:22px"></i>
                  : indica que el desvío fue reembolsado.
                </li>
                <li>
                  <i class="mdi mdi-close-circle error--text" style="font-size:22px" color=""></i>
                  : indica que el desvío no será reembolsado y se considera una pérdida.
                </li>
                <li>
                  <i class="mdi mdi-download success--text" style="font-size:22px"></i>
                  : cuando se activa, comienza el intento de descargar el video.
                  <strong>Es muy importante que este botón se presione solo una vez para evitar la desaceleración del sistema.</strong>
                </li>
                <li>
                  <i class="mdi mdi-video info--text" style="font-size:22px"></i>
                  : se mostrará si la descarga se completa con éxito, y cuando se activa,
                  permite ver el video descargado.
                </li>
              </ul>
            </li>
          </ul>
          <br>
          <p>
            <strong>Acceso Sospechoso:</strong>
          </p>
          <ul>
            <li>
              En accesos sospechosos, aparecerán todos los momentos en que hubo acceso a través del módulo de control de acceso y no se notificó ninguna transacción dentro de los 5 minutos;
            </li>
            <li>
              Puede realizar las mismas acciones que en Transacciones Sospechosas, con la opción de descargar el video del momento de acceso;
            </li>
            <li>
              Al hacer clic en el botón
              <i class="mdi mdi-magnify info--text" style="font-size:22px"></i>
              junto a la fecha, puede ver las 5 transacciones que ocurrieron después del acceso dentro de un período de 1 hora,
              lo que le permite descargar el video, tomar acciones y verificar los detalles de la transacción.
            </li>
          </ul>
          <br>
          `,
          alert: 'Para acceder a los videos de una transacción de punto de venta, es necesario que tenga habilitada la integración de video.'
      },
    },
    accessControl: {
      title: "Control de Acceso",
      help: {
        description: `
        <p>
          Aquí se muestra todo el acceso a cada cerradura, junto con la telemetría de cada dispositivo, todo en pestañas separadas.
        </p>
        <p>
          Comenzando con la pestaña <strong>Lista de Accesos</strong>, verá una lista de los accesos realizados, junto con el resultado de la acción, que puede ser <strong style='color: red'>Error</strong> o <strong style='color: green'>Éxito</strong>.
          Al expandir la fila haciendo clic en <i class="material-icons" style="vertical-align: middle; margin-left: 2px;">keyboard_arrow_down</i>, verá un listado con información sobre la persona responsable de abrir la cerradura, el nombre de la cerradura y el dispositivo, así como el error específico que ocurrió durante la apertura, si lo hubo.
        </p>
        <p>
          Al pasar a la pestaña <strong>Telemetría</strong>, verá datos de telemetría relevantes con información sobre el cliente, el dispositivo y su estado.
        </p>
        `,
      },
      tooltips: {
        blockedUser: "Bloquear usuario en la tienda",
        unblockedUser: "Desbloquear usuario en la tienda",
      },
      messages: {
        blocked: "Bloqueado",
        unblocked: "Desbloqueado",
        success: "Éxito al realizar {action} para {fullName}.",
        error: "Error al realizar {action}."
      },
    }
  }
}
