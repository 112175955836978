export default {
    tittleDialog: 'ATENCIÓN',
    approvedTextDialogDescription: 'Este proceso se refiere solo a la transferencia de titularidad de los POS en el sistema. <b>También es necesaria</b> la transferencia de la máquina de pago con el adquirente. <span style="color:#FF5252;">Si aún no ha realizado la transferencia</span>, siga las instrucciones proporcionadas en el siguiente video para completar la migración del terminal.</br> Si esta transferencia no se completa, los ingresos de la tienda seguirán siendo acreditados al antiguo titular.',
    rejectedTextDialogStart: '¿Está seguro de que desea <span style="color:#FF5252;">rechazar</span> esta transferencia de POS? Esta acción es irreversible y requerirá que reinicie todo el proceso.',
    rejectedTextDialogComment: 'Por favor, deje un comentario informando el motivo del rechazo',
    rejectedCommentPlaceHolder: 'Deje aquí el motivo...',
    btnApproved: 'APROBAR',
    btnRejected: 'SÍ',
    btnClose: 'CERRAR',
    termResponsibilityPOS: 'Entiendo que al declarar que he realizado la transferencia de la cuenta de la máquina de pago, soy consciente de que completar este paso es esencial para el correcto funcionamiento del POS y para recibir los ingresos.',
    termResponsibilityTransfer: 'Entiendo que asumo todas las responsabilidades administrativas, operativas y financieras relacionadas con las máquinas y POS que estoy recibiendo del antiguo titular.',
    msgSuccessMigration: 'Se han confirmado dos aprobaciones: Punto de venta migrado con éxito.',
};
