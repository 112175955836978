import ButtonEs from './ButtonEs';
import RemoveDialogEs from './RemoveDialogEs';
import PlanogramEs from './PlanogramEs';
import PriceTablesEs from './PriceTablesEs';
import PointOfSaleEs from './PointOfSaleEs';
import FranchiseeEs from './FranchiseeEs';
import TablesEs from './TablesEs';
import ReportEs from './ReportEs';
import FiltersEs from './FiltersEs';
import TenantEs from './TenantEs';
import PromotionLogoEs from './PromotionLogoEs';
import SecurityEs from './SecurityEs';
import UnlockEs from './UnlockEs';
import UserEs from './UsersEs';
import BoxEs from './BoxEs';
import ProductsEs from './ProductsEs';
import ValidationsEs from './ValidationsEs';
import AlertsEs from './AlertsEs';
import ConnectivityEs from './ConnectivityEs';
import TelemetryEs from './TelemetryEs';
import MissingProductsEs from './MissingProductsEs';
import InventoryEs from './InventoryEs';
import TaxingsEs from './TaxingsEs';
import PickListsEs from './PickListsEs';
import PurchaseEs from './PurchaseEs';
import DatesEs from './DatesEs';
import RuptureReportsEs from './RuptureReportsEs';

export default Object.assign({},
  PriceTablesEs,
  ButtonEs,
  PlanogramEs,
  RemoveDialogEs,
  PointOfSaleEs,
  FranchiseeEs,
  TablesEs,
  ReportEs,
  FiltersEs,
  PromotionLogoEs,
  SecurityEs,
  UnlockEs,
  UserEs,
  BoxEs,
  ProductsEs,
  ValidationsEs,
  TenantEs,
  InventoryEs,
  AlertsEs,
  ConnectivityEs,
  TelemetryEs,
  MissingProductsEs,
  TaxingsEs,
  PurchaseEs,
  DatesEs,
  PickListsEs,
  RuptureReportsEs
);
