export default {
  inventory: {
    title: 'Inventory',
    help: {
      title: 'Inventory Information',
      description: `<p>Keep accurate control of available items and movements in your inventory.
          Use this page to monitor inventory in different locations, make adjustments, and analyze movement patterns.
        </p>
        <p><strong>Inventory items: </strong>Explore inventory items and transactions details with custom filters.
          You can segment by Store Inventory or Central Inventory. Adjust the item's address,
          quantity, critical limit, and minimum purchase quantity. Additionally, you can edit quantities directly in the system and download the data in Excel format.
        </p>
        <p><strong>Transactions: </strong>Analyze operations by specific touch or across your entire operation with detailed filters.
          Understand movement patterns and identify potential logistical errors to optimize your operation.
        </p>`
    },
    types: {
      title: 'Stock Types',
      center: 'Central',
      pos: 'POS',
    },
    items: {
      title: 'Inventory items',
      tableTitle: 'Items in Inventory',
      tooltips: {
        onlyDistributionCenter: 'Select only one distribution center',
        notDeleteInventoryPDV: 'Cannot delete product from point of sale inventory',
      },
      messages: {
        error: {
          downloadFile: 'Unable to download the file',
          consult: 'Unable to consult the inventory',
        },
        success: {
          minimumQuantityUpdated: 'Minimum purchase quantity successfully updated',
          addressUpdated: 'inventory address successfully updated',
          minimumCriticalUpdated: 'Minimum critical quantity successfully updated',
          quantityUpdated: 'Quantity successfully updated',
        }
      },
      dialogs: {
        removeProduct: {
          title: 'Remove products from distribution center',
          all: 'Do you want to remove all products from the distribution center that are not in an active planogram?',
          specific: 'Do you want to remove the product {product} from the distribution center?',
          attention: 'Attention',
          messages: {
            success: {
              productsRemoved: 'Products were removed from distribution center',
              noProductsToRemove: 'There are no products to remove from distribution center',
            },
            error: {
                removeProducts: 'Unable to remove products from distribution center',
            },
          }
        },
        itemAddress: {
          title: 'Inventory Address',
          messages: {
            error: {
              addressUpdated: 'Unable to edit inventory address',
            },
          },
          help: {
            description: `<p align="justify">
            The <strong>inventory Address</strong> is the location of the item in the distribution center.
            </p>
            <p align="justify">
              This address is standardized according to the inventory, usually a code that indicates where the item is stored in the distribution center.
            </p>
            <p align="justify">
              For example, some Coca-Cola bottles are stored on the shelf with code PRAT20.
              Thus, the inventory address for this item is PRAT20, which allows it to be located in the distribution center.
            </p>`
          }
        },
        addProduct: {
          title: 'Add products to distribution center',
          radioButtons: {
            addAllProducts: 'Add all products from the planogram to the distribution center',
            addSpecificProducts: 'Add specific products to the distribution center',
          },
          messages: {
            success: {
              productsAdded: 'Products were added to the distribution center',
              noProductsToAdd: 'There are no products to add to the distribution center',
            },
            error: {
              addProducts: 'Unable to add products to the distribution center',
            },
          }
        },
        minimuCritical: {
          title: 'Critical Minimum',
          help: {
            description: `<p align="justify">
                The <strong>Critical Minimum</strong> is an essential indicator for inventory management, signaling when it is necessary to replenish merchandise to prevent shortages.
                Keeping an eye on this value is vital to optimize operational efficiency and ensure customer satisfaction. Our feature is fully editable, providing flexibility
                to adjust it according to specific operational needs. After editing, the new value is saved, allowing personalized control of the inventory.
                For example, if you update the value from 5 to 10, the system will consider 10 as the new "Critical Minimum." This keeps inventory levels ideal and prevents drops
                in essential products in the store.
              </p>`
          },
          label: 'Edit critical minimum',
          messages: {
            error: {
              minimumCriticalUpdated: 'Error saving',
            },
          }
        },
        purchaseQuantity: {
          title: 'Minimum Quantity',
          help: {
            description: `<p align="justify">
              The <strong>Minimum Purchase Quantity</strong> is the minimum purchase order that can be placed to supply your distribution center.
              </p>
              <p align="justify">
                This value is based on the <strong>Purchase Unit</strong> (such as box, pack, package, etc.) and how many individual products that unit contains.
              </p>
              <p align="justify">
                For example, a beverage supplier only sells with a minimum order of 10 packs containing 6 units each.
                Thus, the minimum purchase quantity in this case is 60 units. You would enter <strong> "10" in this form</strong> for a
                <strong>Minimum Purchase Quantity of 60 units.</strong>
              </p>`
          },
          minQuantity: '<strong>Minimum Purchase Quantity:</strong> total of {minimumPurchaseQuantity} units',
          messages: {
            error: {
              minimumQuantityUpdated: 'Unable to edit the Minimum Purchase Quantity',
            },
          }
        }
      }
    },
    inventoryTransaction: {
      title: 'Transactions Made',
      tab: 'Transactions',
      messages: {
        error: {
          generateFile: 'Error generating file',
        }
      }
    },
    operation: {
      title: 'Replenishment',
      canceled: 'Canceled',
      active: 'Active',
      completed: 'Completed',
      help: {
        description: `<p>This screen displays the refills and inventory controls performed on TouchPay.</p>
          <p>The refilling option can be accessed in three ways:</p>
          <ul>
              <li><strong>Refilling via terminal</strong>: The refilling option via terminal is accessed through the payment terminal's administrator menu.</li>
              <li><strong>Refilling via TouchPay Refiller app</strong>: Refilling via the app can be done when a pick list is pending/separated. In this option, the user can edit quantities and expiration dates, as well as remove items.</li>
              <li><strong>Refilling via telemetry</strong>: Refilling can also be done via the telemetry page of the web system; however, in this mode, it is not possible to edit the refilled quantities, perform inventory control, or inform product expiration dates. It is possible to refill a created pick list or perform a full refill. In a full refill, items will be refilled to reach the desired quantity for that planogram, always being the difference between the current quantity and the desired amount.</li>
          </ul>
          <p>The refilling updates the stock of your point of sale with the refilled products. A Pick List is required for each refill that is not complete.</p>
          <p>The inventory option can be accessed in three ways:</p>
          <ul>
              <li><strong>Inventory via terminal</strong>: The inventory option via terminal is accessed through the payment terminal's administrator menu.</li>
              <li><strong>Inventory via TouchPay Refiller app</strong>: Inventory via the app can be done when a POS has a Planogram. In this option, the user can edit quantities and expiration dates, as well as remove items.</li>
              <li><strong>Inventory via Stock</strong>: Inventory can also be performed via the stock page of the web system; however, in this mode, it is not possible to edit product expiration dates, and there is no reference for the desired quantity. This operation can only be performed by users with manager or administrator permissions.</li>
          </ul>`,
      },
      messages: {
        error: {
          report: 'Could not generate the report',
          undo: 'Could not undo the operation',
        },
        success: {
          undo: 'Operation successfully undone',
        }
      },
      details: {
        pos: 'POS',
        inventory: 'Inventory',
        started: 'Started on',
        finished: 'Finished on',
        elapsedtime: 'Elapsed Time',
        type: 'Type',
        users: 'Users',
        pickList: 'Pick List',
        completedSupply: 'Completed Supply',
      },
      types: {
        inventory: 'Inventory',
        blindInventory: 'Blind Inventory',
        combined: 'Combined',
        separeted: 'Separated',
        correction: "Correction",
        sale: "Sale",
        purchase: "Purchase",
        reversed: "Operation Reversal",
        automaticCorrection: "Automatic Correction",
        supply: "Supply",
        expired: "Expired",
        damaged: "Damaged Product",
        return: "Return",
        removedInventory: "Inventory Removal",
        other: "Other",
        expirationDateChanged: "Expiration Date Changed",
        expirationDateAdded: "Expiration Date Added",
        expirationDateRemoved: "Expiration Date Removed",
      },
    },
    report: {
      dynamic: {
        title: 'Dynamic Inventory Summary',
        help: {
          description: `<p><strong>On this page, you can generate customized inventory reports according to your operational needs. Use the following features to configure your reports:</strong></p>
          <p><strong>Filters and Actions:</strong> Select the desired period using the start and end dates. You can also choose fixed intervals, such as 30 days, 15 days, 7 days, or same day, for a quick analysis of specific periods.</p>
          <p><strong>Inventory:</strong> Use this filter to focus on specific inventories you want to analyze.</p>
          <p><strong>Products:</strong> Choose specific products or categories to detail the report according to the need for item monitoring.</p>
          <p><strong>Data Grouping:</strong> Define how you prefer to organize the data in the report. Grouping options include Products, Category, Inventory, Operation, Day, Month, or Day of the Week, enabling a personalized inventory analysis.</p>
          <p><strong>Save Filters:</strong> Save your filter preferences for reuse in future reports, making the query process easier.</p>
          <p><strong>Report Export:</strong> After adjusting the filters according to your needs, you can export the report in Excel format by clicking the "Excel" button.</p>
          <p>Remember to click "Update" after adjusting the filters to view the most recent information about your inventory.</p>`
        },
      },
      operational: {
        title: 'Operational Reports',
        help: {
          description: `<p>Here are displayed the reports for <strong>products about to expire</strong>, <strong>losses by POS</strong>, and <strong>losses by product</strong>. To generate data for these reports, it is necessary to perform the inventory supply and control flow through the payment terminal. This flow is only available on micro-market terminals.</p>`
        },
        productsToExpire: 'Products About to Expire',
        posLosses: 'Losses by POS',
        lossesByProduct: 'Losses by Product'
      }
    },
  }
}
