<template>
  <v-container fluid class="pa-0">
    <portal to="toolbarTitle">
     <supply-help></supply-help>
    </portal>
    <v-layout>
      <v-flex xs-12>
        <v-card class="mb-16">
          <inventory-types-filter v-on:modify="updateFilters">
          </inventory-types-filter>
          <v-divider></v-divider>
          <v-data-table
            :items="operations"
            :headers="headers"
            :loading="loading"
            :server-items-length="totalItems"
            :options.sync="options"
            :footer-props="{ itemsPerPageOptions: [10, 20, 30] }"
          >
            <template #item="{ item }">
              <tr class="clickable" @click="handleClick(item)">
                <td>{{ (item.isCanceled) ? $t('inventory.operation.canceled') : $t('inventory.operation.active') }}</td>
                <td>{{ (item.pointOfSale) ? item.pointOfSale.id : "-"}}</td>
                <td>{{ (item.pointOfSale) ? (item.pointOfSale.customerName ?? '') + ' / '
                    + (item.pointOfSale.localName ?? '') + ' / ' + (item.pointOfSale.specificLocation ?? '') : "-"}}</td>
                <v-tooltip nudge-left="30%" nudge-bottom="1.5" right>
                  <template #activator="{ on, attrs }" >
                    <td v-bind="attrs" v-on="on">{{ (item.userEmail) ? truncateEmail(item.userEmail) : "-"}}</td>
                  </template>
                  <span>{{ (item.userEmail) ? (item.userEmail) : "-"}}</span>
                </v-tooltip>
                <td>{{(item.distributionCenterName) ?  item.distributionCenterName : '-' }}</td>
                <td>{{ translateOperationType(item.type, item.isBlindOperation) }}</td>
                <td>{{ item.type != 'Inventory' ? (item.pickListId || $t('inventory.operation.completed')) : '-' }}</td>
                <td>{{ $moment(item.dateCompleted).format('DD/MM/YYYY HH:mm') }}</td>
                <td>{{ item.totalTime.includes(".") ? item.totalTime.split(".")[0] : item.totalTime }}</td> 
                <td>{{ $currencyFormatter.format(Math.max(item.totalLosses, 0)) }}</td>
              </tr>
            </template>
          </v-data-table>

          <operation-details
            v-model="operationDialog.show"
            :operation="operationDialog.operation"
          ></operation-details>

        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import OperationDetails from '@/views/operations/OperationDetails'
import { shouldRefreshData, toApiPagination } from '@/components/TableOptionsComparator';
import OperationHelper from '@/views/operations/OperationCommon';
import SupplyHelp from '@/components/SupplyHelp';
import InventoryTypesFilter from '@/components/InventoryTypesFilter';
import { truncateEmail } from '@/utility/TextFormatting';

export default {
  data() {
    return {
      loading: true,
      operations: [],
      headers: [
        { text: this.$t('tables.headers.status'), align: 'left', sortable: false, value: 'isCanceled' },
        { text: this.$t('tables.headers.pointOfSaleId'), align: 'left', sortable: true, value: 'planogram.pointOfSale.id' },
        { text: this.$t('tables.headers.customer'), align: 'left', sortable: true, value: 'planogram.pointOfSale.local.customer.name' },
        { text: this.$t('tables.headers.users'), align: 'left', sortable: false, value: 'userEmail' },
        { text: this.$t('tables.headers.inventory'), align: 'left', sortable: false, value: 'result.distributionCenterName' },
        { text: this.$t('tables.headers.operationType'), align: 'left', sortable: true, value: 'result.type' },
        { text: this.$t('tables.headers.pickList'), align: 'left', sortable: true, value: 'pickListId' },
        { text: this.$t('tables.headers.finished'), align: 'left', sortable: true, value: 'result.dateCompleted' },
        { text: this.$t('tables.headers.elapsedtime'), align: 'left', sortable: false, value: 'totalTime'},
        { text: this.$t('tables.headers.totalLosses'), align: 'left', sortable: false, value: 'totalLosses' }
      ],
      totalItems: 0,
      form: {},
      options: {
        page: 1,
        itemsPerPage: 10,
        mustSort: true,
        sortBy: ['result.dateCompleted'],
        sortDesc: [true]
      },
      operationDialog: {
        show: false,
        operation: null
      }
    }
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.loadOperations();
        }
      },
      deep: true
    }
  },
  computed: {
    params() {
      let pagination = toApiPagination(this.options);
      this.insertFiltersFromForm(pagination);
      return pagination;
    }
  },
  methods: {
    updateFilters(form) {
      this.form = form;
      this.options.page = 1;
      this.loadOperations();
    },
    loadOperations() {
      this.loading = true;
      this.axios.get('api/inventory/operations', { params: this.params })
        .then((response) => {
          this.loading = false;
          this.operations = response.data.items;
          this.totalItems = response.data.totalItems;
        });
    },
    insertFiltersFromForm(params) {
      const form = this.form;
      params.minDate = form.minDate;
      params.maxDate = form.maxDate;
      params.inventoryIds = form.inventoryIds;
      params.inventoryTypes = form.inventoryTypes;
      params.operationTypes = form.operationTypes;
      params.userId = form.userId;
      params.productId = form.productId;
      return params;
    },
    handleClick(operation) {
      this.operationDialog.show = true;
      this.operationDialog.operation = operation;
    },
    translateOperationType: OperationHelper.translateOperationType,
    truncateEmail
  },
  components: {
    OperationDetails,
    SupplyHelp,
    InventoryTypesFilter,
  }
}
</script>

<style scoped>

    InventoryTypesFilter  .clickable {
    cursor: pointer
  }
</style>
