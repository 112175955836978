import {defineStore} from "pinia";
import agent from "@/api/agent";
import {Bill, BillChargedItem} from "@/types/Bill";
import {ref, watch} from "vue";
import {useAlertsStore} from "@/stores/alertsStore";
import moment from "moment";

export const useBillsStore = defineStore('bills', () => {
  const alertsStore = useAlertsStore()

  const customerBills = ref<Bill[]>([]);
  const filteredCustomerBills = ref<Bill[]>([]);

  const chargedItems = ref<BillChargedItem[]>([]);

  const loadingCustomerBills = ref(false);

  const noDataText = ref('Digite seu CNPJ/CPF para pesquisar por boletos em aberto');
  const customerDocument = ref<string>();

  const chargedItemsDialog = ref(false);

  const billTypeFilter = ref('');
  const billMonthFilter = ref(null);

  function filterCustomerBills() {
    if (customerBills.value) {
      filteredCustomerBills.value = customerBills.value
        .filter(bill => {
          return !billTypeFilter.value || bill.billType === billTypeFilter.value;
        })
        .filter(bill => {
          return !billMonthFilter.value
            || moment(bill.forecastDateTime).format('MM') === billMonthFilter.value
            || moment(bill.issueDateTime).format('MM') === billMonthFilter.value;
        });
    }
  }

  watch([billTypeFilter, billMonthFilter], () => {
    filterCustomerBills()
  })

  async function getCustomerBills() {
    loadingCustomerBills.value = true;
    const sanitizedDocument = customerDocument.value ? customerDocument.value.replace(/\D/g, '') : '';
    await agent.Bills.list({customerDocument: sanitizedDocument})
      .then((response) => {
        customerBills.value = response.bills;
        if (customerBills.value.length === 0) {
          noDataText.value = 'Nenhum boleto em aberto encontrado, confira o CNPJ/CPF digitado';
        }
        filterCustomerBills()
      })
      .catch((error) => alertsStore.showErrorSnackbar(error.response.data))
      .finally(() => loadingCustomerBills.value = false);
  }

  async function downloadBill(item: Bill) {
    await agent.Bills.billLink(item.omieBillId)
      .then((response) => window.open(response))
      .catch((error) => alertsStore.showErrorSnackbar(error.response.data))
  }

  async function downloadServiceOrder(item: Bill) {
    await agent.Bills.serviceOrderLink(item.serviceOrderCode)
      .then((response) => window.open(response))
      .catch((error) => alertsStore.showErrorSnackbar(error.response.data))
  }

  async function downloadDanfe(item: Bill) {
    await agent.Bills.danfeLink(item.nfeKey!)
      .then((response) => window.open(response))
      .catch((error) => alertsStore.showErrorSnackbar(error.response.data))
  }

  function isOlderThanOneQuarter(forecastDateTime: string) {
    const forecastDate = new Date(forecastDateTime);
    const today = new Date();
    const quarterAgo = new Date(today.setMonth(today.getMonth() - 3));
    return forecastDate < quarterAgo;
  }

  function handleBillChargedItems(item: Bill) {
    chargedItems.value = item.chargedItems;
    chargedItemsDialog.value = true;
  }

  return {
    billMonthFilter,
    billTypeFilter,
    chargedItems,
    chargedItemsDialog,
    customerDocument,
    downloadBill,
    downloadDanfe,
    downloadServiceOrder,
    filteredCustomerBills,
    getCustomerBills,
    handleBillChargedItems,
    isOlderThanOneQuarter,
    loadingCustomerBills,
    noDataText
  };
})
