
import Vue from 'vue';
import moment from "moment";
import agent from '@/api/agent';
import ProductsCloseToExpirationHelp from '@/views/dashboard-new/helpers-new/ProductsCloseToExpirationHelp.vue';
import {displayDateFormatted} from '@/utility/TextFormatting';
import {Country, DateTimePrecision} from '@/constants/enums'
import {shouldRefreshData} from "@/components/TableOptionsComparator";

export default Vue.extend({
  components: {
    ProductsCloseToExpirationHelp,
  },
  data() {
    return {
      expiration: {
        loading: true,
        headers:[
          { text: '', align: 'center', sortable: false, value: 'circle', width: '30px' },
          { text: this.$t('ProductsCloseToExpirationProduct'), align: 'left', sortable: false, value: 'product.description' },
          { text: this.$t('ProductsCloseToExpirationMaturity'), align: 'left', sortable: false, value: 'productExpirationDate' },
          { text: this.$t('ProductsCloseToExpirationQuantityAtPos'), align: 'left', sortable: false, value: 'currentQuantity' },
          { text: this.$t('ProductsCloseToExpirationPos'), align: 'left', sortable: false, value: 'inventoryName' },
        ],
        items: [] as any[],
        paginationOptions: {
          sortBy: ['productExpirationDate'],
          sortDesc: [true],
          pageSize: 8
        },
        totalItems: 0,
        showZeroProducts: false,
      },
      colors: {
        dashboardRed: (this.$vuetify.theme as any).defaults.dashboard.dashboardRed,
        dashboardRedDarken1: (this.$vuetify.theme as any).defaults.dashboard.dashboardRedDarken1,
        dashboardRedDarken2: (this.$vuetify.theme as any).defaults.dashboard.dashboardRedDarken2,
      },
    };
  },
  watch: {
    'expiration.paginationOptions': {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.updateExpiration();
        }
      },
      deep: true
    },
    'expiration.showZeroProducts': {
    handler(newShowZeroProducts, oldShowZeroProducts) {
      if (newShowZeroProducts !== oldShowZeroProducts) {
        this.updateExpiration();
      }
    }
    }
  },
  computed: {
    DateTimePrecision() {
      return DateTimePrecision
    },
    country(): Country {
      return (this as any).$auth.user().tenantCountry as Country;
    },
  },
  methods: {
    updateExpiration(){
      this.expiration.loading = true;
      let params = {
        ...this.expiration.paginationOptions,
        showZeroProducts: this.expiration.showZeroProducts
      };

      agent.OperationalReports.getProductsToExpire(params)
        .then(response => {
          let items = response.items;
          this.expiration.items = items;
          this.expiration.totalItems = response.totalItems;
          this.expiration.items.forEach((item) => {
            const expirationDate = moment(item.productExpirationDate);
            const today = moment();
            const daysUntilExpiration = expirationDate.diff(today, 'days');
            if (daysUntilExpiration < 0) {
              item.circle = this.colors.dashboardRed;
            } else if (daysUntilExpiration <= 15) {
              item.circle = this.colors.dashboardRedDarken1;
            } else {
              item.circle =  this.colors.dashboardRedDarken2;
            }
          });
        })
        .catch(() => {})
        .finally(() => {
          this.expiration.loading = false;
        });
    },
    isExpired(expirationDate: string): boolean{
      return new Date(expirationDate) < new Date();
    },
    displayDateFormatted
  },
});
