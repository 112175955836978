export type ConsolidatedCatalog = {
  description: string;
  items: CatalogItem[];
}

export type CatalogItem = {
  id: number;
  name: string;
  description: string;
  imageUrl: string;
}

export type CustomerFullInfoDto = {
  contractId: number;
  billingDocumentId: string | null;
  customerInfo: GetFilledCustomerInfoDto;
  paymentProcessorListDto: PaymentProcessorDto[];
}

export type GetFilledCustomerInfoDto = {
  city: string | null;
  cep: string | null;
  address: string | null;
  addressNumber: number | null;
  addressComplement?: string | null;
  brazilState: string | null;
  phone: string | null;
  fantasyName: string | null;
  email: string | null;
}

export type CustomerInfoForProfileDto = {
  Email: string | null;
  Phone: string | null;
  DateOfBirth: Date | null;
  AddressTypes: number[] | null;
  NeedsToUpdateInfo: boolean;
}

export enum AddressTypes {
  Street = 0,
  Condominium = 1,
  Company = 2,
  Other = 3
}

export type AcquisitionOrderRequestDto = {
  orderItems: AcquisitionOrderItemRequest[];
  tenantId: number;
  groupId?: number;
  fantasyName: string | null;
  email: string | null;
  phoneNumber: string | null;
  deliveryAddress: DeliveryAddress | null;
  paymentProcessorDto: PaymentProcessorDto;
}

export type ValidateDocumentIdDto = {
  documentId: string;
  status: CnpjStatus;
  ieIsValid: boolean;
}

export type DeliveryAddress = {
  city: string | null;
  cep: string | null;
  address: string | null;
  addressNumber: number | null;
  addressComplement: string | null;
  brazilState: string | null;
}

export type AcquisitionOrderItemRequest = {
  quantity: number;
  catalogItemId: number;
}

export type FilledCustomerInfoDto = {
  city: string | null;
  cep: string | null;
  address: string | null;
  addressNumber: number | null;
  addressComplement: string | null;
  brazilState: string | null;
}

export type PaymentProcessorDto = {
  id:number|null;
  email: string | null;
  activationCode: string | null;
  apiToken: string | null;
}

export enum CnpjStatus {
  Active,
  Suspended,
  Unqualified,
  Down,
  Null,
}

