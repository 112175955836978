export default {
  inventory: {
    title: 'Inventario',
    help: {
      title: 'Información de Inventario',
      description: `<p>Mantén un control preciso de los artículos disponibles y los movimientos en tu inventario.
          Utiliza esta página para monitorear el inventario en diferentes ubicaciones, realizar ajustes y analizar patrones de movimiento.
        </p>
        <p><strong>Inventario: </strong>Explora detalles del inventario y movimientos con filtros personalizados.
          Puedes segmentar por Inventario de Tienda o Inventario Central. Realiza ajustes en la dirección del artículo,
          cantidad, límite crítico y cantidad mínima de compra. Además, puedes editar cantidades directamente en el sistema y descargar los datos en formato Excel.
        </p>
        <p><strong>Movimientos: </strong>Analiza operaciones por toque específico o en toda tu operación con filtros detallados.
          Comprende patrones de movimiento e identifica posibles errores logísticos para optimizar tu operación.
        </p>`
    },
    types: {
      title: 'Tipos de Inventario',
      center: 'Central',
      pos: 'Punto de Venta',
    },
    items: {
      title: 'Inventario',
      tableTitle: 'Artículos en el Inventario',
      tooltips: {
        onlyDistributionCenter: 'Seleccione solo un inventario central',
        notDeleteInventoryPDV: 'No es posible eliminar un producto del inventario del punto de venta'
      },
      messages: {
        error: {
          downloadFile: 'No fue posible descargar el archivo',
          consult: 'No fue posible consultar el inventario',
        },
        success: {
          minimumQuantityUpdated: 'Cantidad mínima de compra actualizada con éxito',
          addressUpdated: 'Dirección del inventario actualizada con éxito',
          minimumCriticalUpdated: 'Cantidad mínima crítica actualizada con éxito',
          quantityUpdated: 'Cantidad actualizada con éxito',
        }
      },
      dialogs: {
        removeProduct: {
          title: 'Eliminar productos del inventario central',
          all: '¿Desea eliminar todos los productos del inventario central que no están en un planograma activo?',
          specific: '¿Desea eliminar el producto {product} del inventario central?',
          attention: 'Atención',
          messages: {
            success: {
              productsRemoved: 'Los productos fueron eliminados del inventario central',
              noProductsToRemove: 'No hay productos para eliminar del inventario central',
            },
            error: {
              removeProducts: 'No fue posible eliminar los productos del inventario central',
            },
          }
        },
        itemAddress: {
          title: 'Dirección del Inventario',
          messages: {
            error: {
              addressUpdated: 'No fue posible editar la dirección del inventario',
            },
          },
          help: {
            description: `<p align="justify">
              La <strong>Dirección del Inventario</strong> es la ubicación del artículo en el inventario central.
              </p>
              <p align="justify">
                Esta dirección está estandarizada de acuerdo con el inventario, generalmente es una especie de código que indica el lugar donde se almacenó el artículo en el inventario central.
              </p>
              <p align="justify">
                Por ejemplo, algunas botellas de Coca-Cola se almacenan en la estantería con el código PRAT20.
                Entonces, la dirección del inventario de este artículo es PRAT20, lo que permite localizarlo en el inventario central.
              </p>`
          }
        },
        addProduct: {
          title: 'Agregar productos al inventario central',
          radioButtons: {
            addAllProducts: 'Agregar todos los productos del planograma al inventario central',
            addSpecificProducts: 'Agregar productos específicos al inventario central',
          },
          messages: {
            success: {
              productsAdded: 'Productos fueron añadidos al inventario central',
              noProductsToAdd: 'No hay productos para añadir al inventario central',
            },
            error: {
              addProducts: 'No fue posible añadir los productos al inventario central',
            },
          }
        },
        minimuCritical: {
          title: 'Mínimo Crítico',
          help: {
            description: `<p align="justify">
                El <strong>Mínimo Crítico</strong> es un indicador esencial para la gestión de inventarios, señalando cuándo es necesario reponer mercancías y evitando
                agotamientos. Mantener atención a este valor es vital para optimizar la eficiencia operativa y garantizar la satisfacción del cliente. Nuestra funcionalidad es completamente
                editable, proporcionando flexibilidad para ajustarla conforme a las necesidades operativas específicas. Tras la edición, el nuevo valor se guarda, permitiendo control
                personalizado del inventario. Por ejemplo, si actualizas el valor de 5 a 10, el sistema considerará 10 como el nuevo "Mínimo Crítico". Esto mantiene el inventario en niveles
                ideales y evita faltas de productos esenciales en la tienda.
              </p>`
          },
          label: 'Editar mínimo crítico',
          messages: {
            error: {
              minimumCriticalUpdated: 'Error al guardar',
            },
          }
        },
        purchaseQuantity: {
          title: 'Cantidad Mínima',
          help: {
            description: `<p align="justify">
              La <strong>Cantidad Mínima de Compra</strong> es el pedido mínimo de compra que se puede realizar para abastecer tu inventario central.
              </p>
              <p align="justify">
                Este valor se define en base a la <strong>Unidad de Compra</strong> (como caja, paquete, etc.) y cuántos productos individuales contiene esa unidad.
              </p>
              <p align="justify">
                Por ejemplo, un proveedor de refrescos solo vende a partir de un pedido mínimo de 10 cajas, con cada caja conteniendo 6 unidades.
                Entonces, la cantidad mínima de compra en este caso es de 60 unidades. Para ello, introducirías <strong>"10" en este formulario</strong>, para una <strong>Cantidad Mínima de Compra de 60 unidades.</strong>
              </p>`
          },
          minQuantity: '<strong>Cantidad Mínima de Compra:</strong> total de {minimumPurchaseQuantity} unidades',
          messages: {
            error: {
              minimumQuantityUpdated: 'No fue posible editar la Cantidad Mínima de Compra',
            },
          }
        }
      }
    },
    inventoryTransaction: {
      title: 'Movimientos Realizados',
      tab: 'Movimientos',
      messages: {
        error: {
          generateFile: 'Error al generar el archivo'
        }
      }
    },
    operation: {
      title: 'Reabastecimiento',
      canceled: 'Cancelado',
      active: 'Activo',
      completed: 'Completo',
      help: {
        description: `<p>En esta pantalla se muestran los reabastecimientos y controles de inventario realizados en TouchPay.</p>
        <p>La opción de reabastecimiento se puede acceder de tres formas:</p>
        <ul>
            <li><strong>Reabastecimiento por terminal</strong>: La opción de reabastecimiento por terminal se accede a través del menú de administrador del terminal de pago.</li>
            <li><strong>Reabastecimiento por la aplicación TouchPay Abastecedor</strong>: El reabastecimiento por la aplicación se puede realizar cuando una Pick List está pendiente/separada. En esta opción, el usuario puede editar cantidades y fechas de vencimiento, además de retirar los artículos.</li>
            <li><strong>Reabastecimiento por telemetría</strong>: El reabastecimiento también se puede realizar a través de la página de telemetría del sistema web, pero en este modo no es posible editar las cantidades reabastecidas, realizar el control de inventario o informar la fecha de vencimiento de los productos. Es posible realizar el reabastecimiento de una Pick List creada o un reabastecimiento completo. En un reabastecimiento completo, se reabastecerán los artículos suficientes para alcanzar la cantidad deseada de ese planograma, siempre será la diferencia entre la cantidad actual y el valor deseado.</li>
        </ul>
        <p>El reabastecimiento actualiza el inventario de su punto de venta con los productos reabastecidos. Se requiere una Pick List para cada reabastecimiento que no sea completo.</p>
        <p>La opción de inventario se puede acceder de tres formas:</p>
        <ul>
            <li><strong>Inventario por terminal</strong>: La opción de inventario por terminal se accede a través del menú de administrador del terminal de pago.</li>
            <li><strong>Inventario por la aplicación TouchPay Abastecedor</strong>: El inventario por la aplicación se puede realizar cuando un punto de venta tiene un Planograma. En esta opción, el usuario puede editar cantidades y fechas de vencimiento, además de retirar los artículos.</li>
            <li><strong>Inventario por Almacén</strong>: El inventario también se puede realizar a través de la página de inventarios del sistema web, pero en este modo no es posible editar la fecha de vencimiento de los productos y no tenemos la referencia de la cantidad deseada. Esta operación solo puede ser realizada por usuarios con permiso de gerente o administrador.</li>
        </ul>`,
      },
      messages: {
        error: {
          report: 'No se pudo generar el informe',
          undo: 'No se pudo deshacer la operación',
        },
        success: {
          undo: 'Operación deshecha con éxito',
        }
      },
      details: {
        pos: 'Punto de Venta',
        inventory: 'Inventario',
        started: 'Iniciado en',
        finished: 'Finalizado en',
        elapsedtime: 'Tiempo transcurrido',
        type: 'Tipo',
        users: 'Usuarios',
        pickList: 'Lista de Selección',
        completedSupply: 'Abastecimiento Completo',
      },
      types: {
        inventory: 'Inventario',
        blindInventory: 'Inventario a Ciegas',
        combined: 'Combinado',
        separeted: 'Separación',
        correction: "Corrección",
        sale: "Venta",
        purchase: "Compra",
        reversed: "Reversión de Operación",
        automaticCorrection: "Corrección Automática",
        supply: "Abastecimiento",
        expired: "Vencido",
        damaged: "Producto Dañado",
        return: "Devolución",
        removedInventory: "Eliminación del Inventario",
        other: "Otros",
        expirationDateChanged: "Fecha de Vencimiento Modificada",
        expirationDateAdded: "Fecha de Vencimiento Añadida",
        expirationDateRemoved: "Fecha de Vencimiento Eliminada",
      },
    },
    report: {
      dynamic: {
        title: 'Resumen Dinámico de Inventario',
        help: {
          description: `<p><strong>En esta página, puedes generar informes personalizados de inventario según tus necesidades operativas. Utiliza las siguientes funcionalidades para configurar tus informes:</strong></p>
          <p><strong>Filtros y Acciones:</strong> Selecciona el período deseado utilizando las fechas de inicio y fin. También puedes elegir intervalos fijos, como 30 días, 15 días, 7 días o el mismo día, para un análisis rápido de períodos específicos.</p>
          <p><strong>Inventario:</strong> Usa este filtro para centrarte en inventarios específicos que deseas analizar.</p>
          <p><strong>Productos:</strong> Elige productos o categorías específicas para detallar el informe según la necesidad de monitoreo de artículos.</p>
          <p><strong>Agrupación de Datos:</strong> Define cómo prefieres organizar los datos en el informe. Las opciones de agrupación incluyen Productos, Categoría, Inventario, Operación, Día, Mes o Día de la Semana, permitiendo un análisis personalizado del inventario.</p>
          <p><strong>Guardar Filtros:</strong> Guarda tus preferencias de filtros para reutilizarlas en futuros informes, facilitando el proceso de consulta.</p>
          <p><strong>Exportación de Informes:</strong> Después de ajustar los filtros según tus necesidades, puedes exportar el informe en formato Excel haciendo clic en el botón "Excel".</p>
          <p>Recuerda hacer clic en "Actualizar" después de ajustar los filtros para visualizar la información más reciente sobre tu inventario.</p>`
        },
      },
      operational: {
        title: 'Informes Operativos',
        help: {
          description: `<p>Aquí se muestran los informes de <strong>productos por vencer</strong>, <strong>pérdidas por PDV</strong> y <strong>pérdidas por producto</strong>. Para que haya información para generar estos informes, es necesario realizar el flujo de abastecimiento y control de inventario a través del terminal de pago. Este flujo está disponible solo en los terminales de micro-mercado. </p>`
        },
        productsToExpire: 'Productos por Vencer',
        posLosses: 'Pérdidas por PDV',
        lossesByProduct: 'Pérdidas por Producto'
      }
    },
  }
}
