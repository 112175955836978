import { render, staticRenderFns } from "./AcquisitionOrderCatalog.vue?vue&type=template&id=f10284ee&scoped=true"
import script from "./AcquisitionOrderCatalog.vue?vue&type=script&setup=true&lang=ts"
export * from "./AcquisitionOrderCatalog.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./AcquisitionOrderCatalog.vue?vue&type=style&index=0&id=f10284ee&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f10284ee",
  null
  
)

export default component.exports