import { render, staticRenderFns } from "./InvoicesTable.vue?vue&type=template&id=31f4b0bd&scoped=true"
import script from "./InvoicesTable.vue?vue&type=script&lang=ts"
export * from "./InvoicesTable.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31f4b0bd",
  null
  
)

export default component.exports