export default {
  buttons: {
    search: "Search",
    cancel: "Cancel",
    save: "Save",
    close: "Close",
    apply: "Apply",
    create: "Create",
    import: "Import",
    add: "Add",
    excel: "Excel",
    yes: "Yes",
    no: "No",
    finish: "Finish",
    remove: "Remove",
    activate: "Activate",
    clear: 'Clear',
    exit: 'Exit',
    update: 'Update',
    confirm: 'Confirm',
    undo: 'Undo',
    ok: 'Ok',
    next: 'Next',
    back: 'Back',
    products: {
      remove: 'Remove Products',
      add: 'Add Products',
    },
    invalidMessages : {
      fillAllFields: 'Fill in all fields',
    },
    pos: {
      new: 'new POS',
    },
    ruptureReports: "View occurrences of this item"
  }
};
