export default{
  ruptureReports:{
    help:{
      description:`
      <p>
        Here, rupture reports are displayed, by product, points of sale, and occurrences related to each inventory item. Regardless of the individual characteristics of each report, an <strong>estimate</strong> of possible lost sales is displayed, in <strong>units</strong> and <strong>value</strong>.
      </p>
      <p>
        The <strong>date</strong> range present in the filter will determine in which date range the <strong>start</strong> of the rupture occurred.
      </p>
      <p>
        For each <strong>rupture</strong> (occurrence), which is subsequently addressed in different reports, the following formula is applied:
      </p>
      <ul align="justify">
        <li>
          The moment the rupture <strong>begins</strong> is determined by a sales stock movement, where the final stock result of the item is zero.
        </li>
        <li>
          The moment the rupture <strong>ends</strong> is determined by a stock movement that results in a quantity greater than zero (it can be inventory or replenishment). Note that if the item has not been replenished, that end is the exact present.
        </li>
        <li>
          With the start and end of the rupture, the <strong>unavailability</strong> time of the item is calculated.
        </li>
        <li>
          The quantity of possible <strong>lost sales</strong> is calculated based on the average of the last 7 days preceding the start of each rupture. This value is multiplied by the <strong>unavailability</strong> time. It is worth mentioning that, if an average that does not complete a whole number occurs, <strong>Lost Sales</strong> will be considered as zero.
        </li>
        <li>
          The <strong>value</strong> of possible lost sales is the multiplication of the units that were not sold by the value applied in the last planogram in which the item is found.
        </li>
      </ul>
      <p></p>`
    },
    title: "Rupture Reports",
    products:{
      title: "Products",
      help:{
        description:`
        <p>
          Here the occurrences <strong>grouped by product</strong> are displayed. For each of them, an <strong>estimate</strong> of possible lost sales is shown.
        </p>
        <p>The <strong>search</strong> filters by: Id, Code, and Description.</p>
        <p>
          <strong>Table:</strong>
        </p>
        <ul align="justify">
          <li><strong>Id</strong>: indicates the product id.</li>
          <li><strong>Code</strong>: indicates the code registered with the product.</li>
          <li><strong>Description</strong>: is the description registered with the product.</li>
          <li><strong>Occurrences</strong>: represents the total number of times the product has had any rupture in any stock.</li>
          <li><strong>Total Time (Days)</strong>: represents the total time of product unavailability. This value is the sum of all the intervals of the ruptures of that product.</li>
          <li><strong>Total Losses (Un.)</strong>: is the sum of the estimated possible lost sales in units for each occurrence.</li>
          <li><strong>Total Losses ({sign})</strong>: is the sum of the estimated possible lost sales in value for each occurrence.</li>
        </ul>`
      }
    },
    pointOfSales:{
      title: "POS",
      help:{
        description:`
        <p>
          Here the occurrences <strong>grouped by POS</strong> are displayed. For each of them, an <strong>estimate</strong> of possible lost sales is shown.
        </p>
        <p>The <strong>search</strong> filters by: POS Information.</p>
        <p>
          <strong>Table:</strong>
        </p>
        <ul align="justify">
          <li><strong>POS Information</strong>: represents the POS information, as follows: POS Id - Client - Location.</li>
          <li><strong>Occurrences</strong>: represents the total number of times the product has had any rupture in any stock.</li>
          <li><strong>Total Time (Days)</strong>: represents the total time of product unavailability. This value is the sum of all the intervals of the ruptures of that product.</li>
          <li><strong>Total Losses (Un.)</strong>: is the sum of the estimated possible lost sales in units for each occurrence.</li>
          <li><strong>Total Losses ({sign})</strong>: is the sum of the estimated possible lost sales in value for each occurrence.</li>
        </ul>`
      }
    },
    occurrences:{
      title: "Occurrences",
      help:{
        description:`
        <p>
          Here the occurrences for each inventory stock item are displayed. For each of them, an <strong>estimate</strong> of possible lost sales is shown.
        </p>
        <p>The <strong>search</strong> filters by: Item Id, Product Code, Description, and Stock.</p>
        <p>The buttons for filtering <strong>Closed</strong> and <strong>Open</strong> ruptures were added for convenience to quickly search for open and closed ruptures. For the same effect in the Excel report, it is suggested to use the End Date filter.</p>
        <p><strong>Table:</strong></p>
        <ul align="justify">
          <li><strong>Item Id</strong>: indicates the stock item.</li>
          <li><strong>Product Code</strong>: indicates the code registered with the product.</li>
          <li><strong>Description</strong>: is the description registered with the product.</li>
          <li><strong>Stock</strong>: is the name of the POS stock.</li>
          <li><strong>Start Date</strong>: indicates the start of the rupture (when the stock of the item had a result of 0).</li>
          <li><strong>End Date</strong>: indicates the end of the rupture; if it is still open, it will be empty, and for calculation purposes, the current moment is used.</li>
          <li><strong>Time (Days)</strong>: represents the difference in days (in decimal form) between the start and end date of the rupture.</li>
          <li><strong>Losses (Un.)</strong>: is the estimate of possible lost sales in units.</li>
          <li><strong>Losses ({sign})</strong>: is the estimate of possible lost sales in value.</li>
        </ul>`
      },
      status:{
        closed: "Closed",
        open: "Open"
      },
    }
  },
}
