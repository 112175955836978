export default {
  tables: {
    headers: {
      id: 'Id',
      pointOfSaleId: 'ID PDV',
      actions: 'Ações',
      customer: 'Cliente',
      location: 'Localização',
      specificLocation: 'Local específico',
      idSubEnvironment: 'ID Sub Ambiente',
      subEnvironment: 'Sub Ambiente',
      cnpj: 'CNPJ',
      billing: 'Faturamento (últimos 30 dias)',
      machines: 'Qtd. de máquinas',
      stores: 'Qtd. de lojas',
      nps: 'Média de NPS',
      name: 'Nome',
      type: 'Tipo',
      lastUpdated: 'Data de atualização',
      items: 'Items',
      activate: 'Ativar',
      remove: 'Excluir',
      code: 'Código',
      description: 'Descrição',
      barCode: 'Código de barra',
      category: 'Categoria',
      cost: 'Custo',
      price: 'Preço',
      markUp: 'MarkUp',
      responsible: 'Responsável',
      applyPriceTableOnPointOfSale: 'Aplicar tabela de preços em PDVs',
      removePriceTable: 'Remover tabela de preços',
      visualizeFranchiseeEnvironment: 'Visualizar Ambiente',
      result: 'Resultado',
      value: 'Valor',
      card: 'Cartão',
      verificationStatus: 'Status de Verificação',
      event: 'Evento',
      channel: 'Canal',
      day: 'Dia',
      month: 'Mês',
      dateHour: 'Data/Hora',
      date: 'Data',
      dayOfWeek: 'Dia da Semana',
      initialHour: 'Hora Inicial',
      finalHour: 'Hora Final',
      duration: 'Duração',
      ip: 'IP',
      users: 'Usuários',
      parameters: 'Parâmetros',
      lockCode: 'Código da Trava',
      lockName: 'Nome da Trava',
      details: 'Detalhes',
      deviceName: 'Nome do Dispositivo',
      openingResponsible: 'Responsável pela Abertura',
      cpf: 'CPF',
      ageRestriction: 'Restrição de Idade',
      status: 'Status',
      product: 'Produto',
      productCode: 'Código do Produto',
      productCategory: 'Categoria do Produto',
      quantity: 'Quantidade',
      qty: 'Qtd.',
      cmv: 'CMV',
      margin: 'Margem',
      selection: 'Seleção',
      nit: 'NIT',
      ein: 'EIN',
      associatedPos: 'PDV Associado',
      inventory: 'Estoque',
      inventoryId: 'ID do Estoque',
      inventoryAddress: 'Endereço do Estoque',
      lastInventory: 'Último Inventário',
      lastPurchaseCost: 'Custo Últ. Compra',
      meanCost: 'Custo Médio',
      totalCost: 'Custo Total',
      reserved: 'Reservado',
      minimumCritical: 'Mínimo Crítico',
      minimumQuantity: 'Quant. Mín. Compra',
      previousQuantity: 'Quantidade Anterior',
      originInventory: 'Estoque de Origem',
      movimentQuantity: 'Quantidade Movimentada',
      destinyInventory: 'Estoque de Destino',
      endQuantity: 'Quantidade Final',
      dateCreated:'Data de Criação',
      operation: 'Operação',
      operationId: 'ID da Operação',
      email : 'Email',
      active: 'Active',
      wiFiPercentage: 'Wi-Fi (%)',
      mobilePercentage: 'Mobile (%)',
      offlinePercentage: 'Offline (%)',
      pointOfSale: 'Ponto de Venda',
      productDescription: 'Desc. do Produto',
      dateOfReading: 'Data da Leitura',
      pointOfSaleReading: 'PDV Lido',
      typeOfAlert: 'Tipo de Alerta',
      operationType: 'Tipo de Operação',
      pickList: 'Pick list',
      finished: 'Finalizado em',
      elapsedtime: 'Tempo decorrido',
      totalLosses: 'Total de Perdas',
      capacity: 'Capacidade',
      validDate: 'Data de Validade',
      confirmedQuantity: 'Quantidade Confirmada',
      supply: 'A abastecer',
      supplyQuantity: 'Quantidade abastecida',
      separetedQuantity: 'Quantidade Separada',
      removals: 'Remoções',
      serialNumber: 'Número de Série',
      distributionCenter: 'Centro de Distribuição',
      posCode:'Código do PDV',
      version: 'Versão',
      password: 'Senha',
      model: 'Modelo',
      modelManufacturer: 'Modelo / Fabricante',
      paymentSystem: 'Sist. de pagamento',
      expires: 'Expira em',
      currentQty: 'Qtd. atual',
      pos:'PDV',
      qtySold: 'Qtd. Vendida',
      soldValue: 'Valor Vendido',
      expiredQty: 'Qtd. Expirada',
      expiredValue: 'Valor Expirado',
      damagedQty: 'Qtd. Danificado',
      damagedValue: 'Valor Danificado',
      stolenQty: 'Qtd. Desviado',
      stolenValue: 'Valor Desviado',
      otherLossesQty: 'Qtd. Outras Perdas',
      otherLossesValue: 'Valor Outras Perdas',
      gainQty: 'Qtd. de Ganhos',
      gainValue: 'Valor de Ganhos',
      defaultPrice: 'Preço Padrão',
      occurrences: 'Ocorrências',
      totalDays: 'Total Tempo(Dias)',
      totalLossesValue: 'Total Perdas',
      startDate: 'Data Inicio',
      endDate: 'Data Fim',
      time: 'Tempo(Dias)',
      losses: 'Perdas(Un.)',
      lossesValue: 'Perdas',
    },
    noData: "Sem resultados",
    none: "Sem dados",
    total: "Total",
  }
}
