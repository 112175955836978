<script>
import { useApprovalAcquisitionsOrderStore } from "@/stores/approvalAcquisitionsOrderStore";

export default {
  data() {
    return {
      approvalAcquisitionsOrderStore: useApprovalAcquisitionsOrderStore()
    }
  },
  methods: {
    async HandleAquisitionOrderApproval() {
      await this.approvalAcquisitionsOrderStore.HandleAquisitionOrderApproval();
    },
  },
}
</script>

<template>
  <v-dialog v-model="approvalAcquisitionsOrderStore.commentsDialog" max-width="600">
    <v-card>
      <v-form>
      <v-card-title class="text-h6 font-weight-bold d-flex justify-center">
        {{ $t('attention') }}
      </v-card-title>
      <v-card-text>
        <p v-if=" approvalAcquisitionsOrderStore.aquisitionOrderApproval !== undefined && approvalAcquisitionsOrderStore.aquisitionOrderApproval.status === 1">
          {{ $t('areYouSureApprove') }}
        </p>
        <p v-if=" approvalAcquisitionsOrderStore.aquisitionOrderApproval !== undefined && approvalAcquisitionsOrderStore.aquisitionOrderApproval.status === 2">
          {{ $t('areYouSureReject') }}
        </p>
        <p v-if=" approvalAcquisitionsOrderStore.aquisitionOrderApproval.status === null">
          {{ $t('areYouSurePending') }}
        </p>
        <p >
          CNPJ: {{ approvalAcquisitionsOrderStore.formatCNPJ(approvalAcquisitionsOrderStore.paginatedListResponse.items.find(x => x.id === approvalAcquisitionsOrderStore.aquisitionOrderApproval.id).documentId) }} -
          {{approvalAcquisitionsOrderStore.paginatedListResponse.items.find(x => x.id === approvalAcquisitionsOrderStore.aquisitionOrderApproval.id).fantasyName}}

        </p>
        <p class ="font-weight-bold">
          {{$t('reasonLabel')}}
        </p>

        <v-textarea
          v-model="approvalAcquisitionsOrderStore.aquisitionOrderApproval.comments"
          :placeholder="$t('reasonText')"
          outlined
          rows="3"
          :rules="[(v) => (approvalAcquisitionsOrderStore.aquisitionOrderApproval.status === 1 || !!v) || $t('requiredRules')]"
        ></v-textarea>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn text color="blue" @click="approvalAcquisitionsOrderStore.commentsDialog = false">
          {{$t('no')}}
        </v-btn>
        <v-btn
          color="primary"
          :disabled="approvalAcquisitionsOrderStore.aquisitionOrderApproval.comments === '' && approvalAcquisitionsOrderStore.aquisitionOrderApproval.status !== 1"
          :loading = "approvalAcquisitionsOrderStore.loadingDialog"
          @click="HandleAquisitionOrderApproval"
        >
          {{$t('yes')}}
        </v-btn>
      </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<style scoped>

.text-center {
  text-align: center;
}
</style>
