<template>
  <v-card class="mb-16">

      <!-- Filters Component -->
      <inventory-transactions-filters
        @modify="updateFilters"
      ></inventory-transactions-filters>



    <v-card-title>
      <div class="title">
        <v-icon left>mdi-swap-vertical-bold</v-icon>{{$t('inventory.inventoryTransaction.title')}}
      </div>
      <v-spacer></v-spacer>
    </v-card-title>




    <v-divider></v-divider>

    <v-data-table
      :loading="loading.transactions"
      :headers="headers"
      :items="transactions"
      :options.sync="options"
      :server-items-length="totalItems"
      :footer-props="{ itemsPerPageOptions: [10, 20, 30, 50, 100] }"
    >
      <template v-slot:[`item.sourceItemId`]="{ item }">
        {{ item.sourceItemId !== null ? item.sourceItemId : '-' }}
      </template>
      <template v-slot:[`item.sourceItemInventoryName`]="{ item }">
        {{ item.sourceItemInventoryName !== null ? item.sourceItemInventoryName : '-' }}
      </template>
      <template v-slot:[`item.changeAmount`]="{ item }">
        {{ shouldShowAmount(item.type) ? '-' : item.changeAmount }}
      </template>
      <template v-slot:[`item.previousAmount`]="{ item }">
        {{ shouldShowAmount(item.type) ? '-' : item.previousAmount }}
      </template>
      <template v-slot:[`item.finalAmount`]="{ item }">
        {{ shouldShowAmount(item.type) ? '-' : item.finalAmount }}
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <span>{{ formatDate(item.date) }}</span>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <v-chip :color="getColor(item.type)" :dark="isDarkChip(item.type)">{{ formatStatus(item.type) }}</v-chip>
      </template>
      <template v-slot:[`item.userEmail`]="{ item }">
        {{ item.userEmail ? truncateEmail(item.userEmail) : '-' }}
      </template>

    </v-data-table>
  </v-card>
</template>

<script>
import { shouldRefreshData, toApiPagination } from "@/components/TableOptionsComparator";
import InventoryTransactionsFilters from './components/InventoryTransactionsFilters.vue';
import moment from 'moment';
import { getColorInventoryTransactionStatus } from './utility/inventoryUtility';
import { allInventoryOperationTypes } from '@/constants/InventoryOperationTypes';
import { truncateEmail } from '@/utility/TextFormatting';


export default {
  data() {
    return {
      transactions: [],
      headers: [
        { text: this.$t('tables.headers.productCode'), align: 'left', sortable: true, value: 'itemProductCode'},
        { text: this.$t('tables.headers.description'), align: 'left', sortable: true, value: 'itemProductDescription'},
        { text: this.$t('tables.headers.previousQuantity'), align: 'left', sortable: false, value: 'previousAmount'},
        { text: this.$t('tables.headers.originInventory'), align: 'left', sortable: true, value: 'sourceItemInventoryName'},
        { text: this.$t('tables.headers.movimentQuantity'), align: 'left', sortable: true, value: 'changeAmount'},
        { text: this.$t('tables.headers.destinyInventory'), align: 'left', sortable: true, value: 'itemInventoryName'},
        { text: this.$t('tables.headers.endQuantity'), align: 'left', sortable: true, value: 'finalAmount'},
        { text: this.$t('tables.headers.dateHour'), align: 'left', sortable: true, value: 'date'},
        { text: this.$t('tables.headers.operation'), align: 'left', sortable: true, value: 'type'},
        { text: this.$t('tables.headers.users'), align: 'left', sortable: false, value: 'userEmail'},
        { text: this.$t('tables.headers.operationId'), align: 'left', sortable: true, value: 'operationId'},
      ],
      loading: {
        transactions: true,
      },
      form: {},
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["date"],
        sortDesc: [true],
        mustSort: true,
      }
    };
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getTransactions();
        }
      },
      deep: true,
    },
  },

  methods: {
    updateFilters(form) {
      if(form.productId != null || form.inventoryIds.length != 0 || form.operations.length != 0) {
        this.options.page = 1;
      }
      this.form = form;
      this.getTransactions();
    },
    getTransactions() {
      this.loading.transactions = true;

      let params = toApiPagination(this.options, null);
      this.insertFiltersFromForm(params);

      this.axios
        .get("api/web/inventory/transactions", { params: params })
        .then((response) => {
          this.loading.transactions = false;
          this.transactions = response.data.items;
          this.transactions.forEach(item => {
            item.previousAmount = item.finalAmount - item.changeAmount;
          });

          this.totalItems = response.data.totalItems;

        });

    },
    insertFiltersFromForm(params) {
      const form = this.form;

      params.minDate = form.minDate;
      params.maxDate = form.maxDate;
      params.inventoryIds = form.inventoryIds;
      params.productId = form.productId;
      params.operations = form.operations;
      params.timezoneOffset = new Date().getTimezoneOffset();

      return params;
    },
    formatDate(date) {
      return moment(date).isValid() ? moment(date).format('DD/MM/YYYY HH:mm:ss') : "";
    },
    getColor(status) {
      return getColorInventoryTransactionStatus(status)[0];
    },
    isDarkChip(status) {
      return getColorInventoryTransactionStatus(status)[1];
    },
    formatStatus(status) {
      return allInventoryOperationTypes[status]
    },
    shouldShowAmount(type) {
      return type === "ExpirationDateChanged" || type === "ExpirationDateAdded" || type === "ExpirationDateRemoved";
    },
    truncateEmail
  },
  components: {InventoryTransactionsFilters},
};
</script>
