<template>
  <v-expansion-panels v-model="expansionIndex" hover>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <template #default="{ open }">
          <v-row no-gutters>
            <v-col cols="2">{{ $t('filters.filtersActions') }}</v-col>
            <v-col cols="10" class="text--secondary">
              <span v-if="!open">
                {{ $t('filters.labels.period') }}: <strong>{{ formattedMinDate }} - {{ formattedMaxDate }}</strong>,
                {{ $t('filters.labels.customer') }}: <strong>{{ (customers.find(c => c.id === form.customerId)||{}).name|| $t('filters.labels.all') }}</strong>,
                {{ $t('filters.labels.local') }}: <strong>{{ (locations.find(l => l.id === form.localId)||{}).name|| $t('filters.labels.all') }}</strong>,
                {{ $t('filters.labels.pos') }}: <strong>{{ (pointsOfSale.find(p => p.value === form.pointOfSaleId)||{}).text|| $t('filters.labels.all') }}</strong>
              </span>
            </v-col>
          </v-row>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form ref="form" v-model="valid" @submit.prevent="getTransactions()">
          <v-layout row wrap>
            <v-flex sm12 md3 class="search-field">
              <v-flex sm12 class="search-field">
                <v-menu class="pr-2" :close-on-content-click="true" v-model="hasMinDate" transition="scale-transition"
                    offset-y :nudge-right="40" max-width="290px" min-width="290px">
                  <template #activator="{ on }">
                    <v-text-field
                      v-on="on"
                      :label="$t('filters.labels.initialDate')"
                      :rules="[rules.required]"
                      required
                      v-model="formattedMinDate"
                      prepend-icon="date_range"
                      readonly>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.minDate"
                    no-title
                    scrollable
                    actions
                    :allowed-dates="getAllowedMinDates">
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="clearMinDate">{{ $t('buttons.clear') }}</v-btn>
                    </v-card-actions>
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex sm12 class="search-field">
                <v-menu :close-on-content-click="true" v-model="hasMaxDate" transition="scale-transition"
                    offset-y :nudge-right="40" max-width="290px" min-width="290px">
                  <template #activator="{ on }">
                    <v-text-field
                      v-on="on"
                      :label="$t('filters.labels.finalDate')"
                      :rules="[rules.required]"
                      required
                      v-model="formattedMaxDate"
                      prepend-icon="event_available"
                      readonly>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.maxDate"
                    no-title
                    scrollable
                    actions
                    :allowed-dates="getAllowedMaxDates">
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="clearMaxDate">{{ $t('buttons.clear') }}</v-btn>
                    </v-card-actions>
                  </v-date-picker>
                </v-menu>
              </v-flex>
            </v-flex>
            <v-flex sm12 md3 class="search-field">
              <v-flex sm12 class="search-field">
                <v-autocomplete
                  prepend-icon="account_box"
                  :items="customers"
                  v-model="form.customerId"
                  :label="$t('filters.labels.customer')"
                  item-text="name"
                  item-value="id"
                  single-line
                  clearable>
                </v-autocomplete>
              </v-flex>
              <v-flex sm12 class="search-field">
                <v-autocomplete
                  prepend-icon="location_on"
                  :disabled="form.customerId === null || loading.locals"
                  :hint="form.customerId === null ? $t('filters.selectACustomer') : ''"
                  :items="locations"
                  v-model="form.localId"
                  :loading="loading.locals"
                  item-text="name"
                  item-value="id"
                  :label="$t('filters.labels.location')"
                  single-line
                  persistent-hint
                  clearable>
                </v-autocomplete>
              </v-flex>
            </v-flex>
            <v-flex sm12 md3 class="search-field">
              <v-flex sm12 class="search-field">
                <v-autocomplete
                  prepend-icon="business"
                  :disabled="form.localId === null || loading.pointsOfSale"
                  :hint="form.localId === null ? $t('filters.selectALocation') : ''"
                  :items="pointsOfSale"
                  v-model="form.pointOfSaleId"
                  :loading="loading.pointsOfSale"
                  :label="$t('filters.labels.pointOfSale')"
                  single-line
                  persistent-hint
                  clearable>
                </v-autocomplete>
              </v-flex>
            </v-flex>
          </v-layout>
          <v-row>
            <v-col sm12 class="search-field text-right pb-2">
              <slot name="excelButton"/>
              <UpdateButton
                :valid="valid"
                @refresh="emitModification"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import UpdateButton from "@/components/buttons/UpdateButton.vue";
import moment from 'moment';
import {displayDateFormatted} from "@/utility/TextFormatting";
import {DateTimePrecision} from "@/constants/enums";
export default {
  components:{
    UpdateButton
  },
  data(){
    return {
      valid: false,
      hasMinDate: null,
      hasMaxDate: null,
      customers: [],
      locations: [],
      pointsOfSale: [],
      expansionIndex: 0,
      form: {
        minDate: null,
        maxDate: null,
        customerId: null,
        localId: null,
        pointOfSaleId: null,
      },
      loading: {
        locals: false,
        pointsOfSale: false,
      },
      rules:{
          required: (v) => !!v || this.$t('requiredRules'),
      }
    }
  },
  computed: {
    formattedMinDate: function(){
      return displayDateFormatted(this.form.minDate, this.country, false, DateTimePrecision.DateOnly);
    },
    formattedMaxDate: function(){
      return displayDateFormatted(this.form.maxDate, this.country, false, DateTimePrecision.DateOnly);
    },
    country() {
      return this.$auth.user().tenantCountry;
    },
  },
  watch: {
    'form.customerId': function(newId) {
      if(newId !== null){
        this.loading.locals = true;
        this.axios.get('api/Customers/' + newId)
          .then((response) => {
            this.loading.locals = false;
            this.locations = response.data.locals;
          });
      }
      else {
        this.form.localId = null;
      }
    },
    'form.localId': function(newId) {
      if(newId != null){
        this.loading.pointsOfSale = true;
        this.axios.get('api/Locals/' + newId + '/PointsOfSale')
          .then((response) => {
            this.loading.pointsOfSale = false;
            this.pointsOfSale = response.data.map((p) => ({ text: p.id + (p.specificLocation !== null ? ' / ' + p.specificLocation : ''), value: p.id }));
          });
      }
      else{
        this.form.pointOfSaleId = null;
      }
    },
  },
  mounted(){
    const maxDate = new Date();
    const minDate = new Date(maxDate.getTime());
    minDate.setDate(minDate.getDate() - 30);
    this.form.maxDate = moment(maxDate).format('YYYY-MM-DD');
    this.form.minDate = moment(minDate).format('YYYY-MM-DD');
    this.$emit('modify', this.form);
    this.axios.get('api/Customers/Active')
      .then((response) => {
        this.customers = response.data
      });
  },
  methods: {
    emitModification(){
      if(this.$refs.form.validate()){
        this.$emit('modify', this.form);
        this.expansionIndex = undefined;
      }
    },
    newForm() {
      return {
        minDate: this.form.minDate,
        maxDate: this.form.maxDate,
      };
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    clearMinDate() {
      this.form.minDate = null;
      this.formattedMinDate = null;
    },
    clearMaxDate() {
      this.form.maxDate = null;
      this.formattedMaxDate = null;
    },
    getAllowedMinDates(minDate) {
      if (this.form.maxDate !== null) {
        return this.isDateRangeAllowed(new Date(minDate), new Date(this.form.maxDate));
      }
      return true;
    },
    getAllowedMaxDates(maxDate) {
      if (this.form.minDate !== null) {
        return this.isDateRangeAllowed(new Date(this.form.minDate), new Date(maxDate));
      }
      return true;
    },
    isDateRangeAllowed(min, max){
      return max >= min && Math.round(max - min)/(1000*60*60*24) < 90;
    }
  },
}
</script>
