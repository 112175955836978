import i18n from '@/locales/i18n';
const weekDayTranslations = {}

weekDayTranslations['Monday'] = i18n.t("days.monday");
weekDayTranslations['Tuesday'] = i18n.t("days.tuesday");
weekDayTranslations['Wednesday'] = i18n.t("days.wednesday");
weekDayTranslations['Thursday'] = i18n.t("days.thursday");
weekDayTranslations['Friday'] = i18n.t("days.friday");
weekDayTranslations['Saturday'] = i18n.t("days.saturday");
weekDayTranslations['Sunday'] = i18n.t("days.sunday");

export { weekDayTranslations };
