
import Vue from 'vue'
export default Vue.extend({

  props: {
    value: {} as any,
    multipleProps: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      inventoryTypes: [
        { text: this.$t('filters.labels.central'), value: "distributionCenter" },
        { text:  this.$t('filters.labels.pos'), value: "pointOfSale" },
      ],
    }
  },
  computed: {
    selectedTypeInventory: {
      get(): any {
        return this.value;
      },
      set(value: any) {
        this.$emit("input", value);
      }
    },
  },
})
