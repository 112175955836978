export default {
  buttons: {
    cancel: "Cancelar",
    search: "Procurar",
    save: "Salvar",
    close: "Fechar",
    apply: "Aplicar",
    create: "Criar",
    import: "Importar",
    add: "Adicionar",
    excel: "Excel",
    yes: "Sim",
    no: "Não",
    finish: "Finalizar",
    remove: "Remover",
    activate: "Ativar",
    clear: 'Limpar',
    exit: 'Sair',
    update: 'Atualizar',
    confirm: 'Confirmar',
    undo: 'Desfazer',
    ok: 'Ok',
    next: 'Próximo',
    back: 'Voltar',
    products: {
      remove: "Remover Produtos",
      add: "Adicionar Produtos",
    },
    invalidMessages : {
      fillAllFields: 'Preencha todos os campos',
    },
    pos: {
      new: 'novo PDV',
    },
    ruptureReports: 'Ver ocorrências deste item'
  },
};
