export default {
    purchasesFiltersTitle: 'Filters and actions',
    purchasesFiltersStartDate: 'Start date',
    purchasesFiltersEndDate: 'End date',
    purchasesFiltersBtnClear: 'Clear',
    purchasesFiltersAddNote: 'ADD NOTE',
    purchasesFiltersXml: 'XML',
    purchasesFiltersManual: 'Manual',
    purchasesFiltersRefresh: 'Refresh',
    purchasesFiltersRulesDate: 'No Date',
    purchasesFiltersRulesRequired: 'This field is required.',
  };
