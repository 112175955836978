
import Vue from 'vue';
import {shouldRefreshData} from "@/components/TableOptionsComparator";
import agent from '@/api/agent';
import {Box} from '@/types/Boxe';

export default Vue.extend({
  data() {
    return {
      boxIds: [] as Box[],
      headers: [
        {text: this.$t('tables.headers.id'), align: 'left', sortable: true, value: 'id'},
        {text: this.$t('tables.headers.pointOfSaleId'), align: 'left', sortable: false, value: 'pointOfSaleId'},
        {text: this.$t('tables.headers.model'), align: 'left', sortable: true, value: 'phoneModel'},
        {text: this.$t('tables.headers.version'), align: 'left', sortable: true, value: 'currentVersion'},
        {text: this.$t('tables.headers.serialNumber'), align: 'left', sortable: true, value: 'cardReaderId'},
        {text: this.$t('tables.headers.password'), align: 'left', sortable: false, value: 'password'},
      ],
      filterHeaders: [] as object[],
      loading: true,
      showPassword: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["id"],
        sortDesc: [false],
        mustSort: true,
      },
      search: {
        valid: true,
        text: "",
      },
      totalItems: 0,
      totalPages: 0,
    }
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getBoxesPaginated();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getBoxesPaginated();
    this.filterHeaders = this.headers;
  },
  methods: {
    getBoxesPaginated() {
      this.loading = true;
      const params = {
        page: this.options.page,
        pageSize: this.options.itemsPerPage,
        sortOrder: this.options.sortBy[0],
        descending: this.options.sortDesc[0],
        search: this.search.text ? this.search.text : null,
      }

      agent.Boxes.getPaginated(params)
        .then(response => {
          this.totalItems = response.totalItems;
          this.totalPages = response.totalPages;
          this.boxIds = response.items.map((item: Box) => ({
            ...item,
            pointOfSaleId: item.pointOfSale ? item.pointOfSale.id : null,
            showPassword: false
          }));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getPassword(box : Box) {
      agent.Boxes.getPassword(box.id)
        .then(response => {
          box.password = response;
          box.showPassword = !box.showPassword;
        })
        .catch(() => {
          box.password = this.$t('pointOfSale.boxes.notFoundMasterBarcode').toString();
          box.showPassword = !box.showPassword;
        });
    },
  }
});
