<template>
  <v-dialog
    persistent
    v-model="show"
    width="720"
    @keydown.esc="show = false"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
        v-model="valid"
        @submit.prevent="saveItem"
      >
        <v-card-title>
          <span class="title">{{isNew ? $t('itemDialogContentAddItem') : $t('itemDialogContentChangeItem')}}</span>
          <help>
            <template #title>{{ $t('itemDialogContent') }}</template>
            <p>
              {{ $t('itemDialogContentDescription') }}
            </p>
            <ul>
              <li>
                {{ $t('selectionNumberHelp') }}
              </li>
              <li>
                {{ $t('pricerHelp') }}
              </li>
              <li>
                {{ $t('maximumCapacityHelp') }}
              </li>
              <li>
                {{ $t('desiredQuantityHelp') }}
              </li>
              <li>
                {{ $t('criticalMinimumHelp') }}
              </li>
            </ul>
            <h3 class="mt-2 mb-1">{{ $t('advancedOptionsHelp') }}</h3>
            <ul>
              <li>
                {{ $t('availabilityHelp') }}
              </li>
              <li>
                {{ $t('sponsoredLinkHelp') }}
              </li>
            </ul>
          </help>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex v-if="posType !== 'MicroMarket'" xs12 sm4>
                <v-text-field
                  type="number"
                  :label= "$t('selectionLabel')"
                  :disabled="!isNew"
                  v-model="editedEntry.selection"
                  :rules="[rules.integer, rules.uniqueSelection]"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm8>
                <div>
                  <products-filter
                    ref="productDropDown"
                    v-model="editedEntry.productId"
                    :required="true"
                    :rules="[rules.required, rules.nonEmpty]"
                    @product-changed="changeProduct"
                  >
                  </products-filter>
                </div>
              </v-flex>
              <v-flex xs12 sm4>
                <v-text-field
                  type="number"
                  min="0"
                  step="0.01"
                  placeholder="0.00"
                  :label= "this.$t('priceLabel')"
                  v-model="editedEntry.price"
                  :rules="[$validations.required, $validations.float, $validations.positiveFloat]"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex
                v-if="posType !== 'MicroMarket'"
                xs12
                sm6
                md4
              >
                <v-text-field
                  type="number"
                  :label= "this.$t('maxCapacityLabel')"
                  v-model="editedEntry.capacity"
                  :rules="[rules.integer]"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex
                xs12
                sm6
                md4
              >
                <v-text-field
                  type="number"
                  :label= "this.$t('desiredQuantityLabel')"
                  v-model="editedEntry.quantityToSupply"
                  :rules="[rules.requiredDesired, rules.integer]"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex
                xs12
                sm6
                md4
              >
                <v-text-field
                  type="number"
                  :label="this.$t('criticalMinimumLabel')"
                  v-model="editedEntry.minimumQuantity"
                  :rules="[rules.integer]"
                  required
                ></v-text-field>
              </v-flex>
              <v-expansion-panels v-if="posType === 'MicroMarket'" flat hover>
                <v-expansion-panel>
                  <v-expansion-panel-header>{{ $t('expandAdvanced') }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        :label="this.$t('selectionLabel')"
                        :disabled="!isNew"
                        v-model="editedEntry.selection"
                        :rules="[rules.integer]"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        :items="availability"
                        v-model="editedEntry.availability"
                        :label="this.$t('availabilityLabel')"
                        required
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        type="string"
                        :label="this.$t('sponsoredLinkLabel')"
                        v-model="editedEntry.externalUrl"
                        :rules="[rules.isUrl]"
                        required
                      ></v-text-field>
                    </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click.native="show = false"
          >{{ $t('itemDialogContentClose') }}</v-btn>
          <v-btn
            color="primary"
            type="submit"
            :disabled="!valid"
            :loading="loading.newItem"
          >{{ $t('itemDialogContentSave') }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import ProductsFilter from '@/components/filters/ProductsFilter.vue';

export default {
  components: {
    ProductsFilter
  },
  props: {
    value: { type: Boolean, required: true },
    planogramId: { type: Number, required: true },
    editedEntry: { type: Object, default: () => ({availability: "Local", selection: null, productId: null, price: null}) },
    isNew: { type: Boolean, default: true },
    selectionList: { type: Array, default: () => [] },
    posType: { type: String, default: null },
    nextSelection: { type: Number, default: null }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    show: function (enabled) {
      if (enabled) {
        this.valid = true;
        if (this.isNew) {
          this.editedEntry.availability = 'Local';
          this.editedEntry.selection = null;
          this.$refs.form.resetValidation()
        }
      } else {
        this.$refs.form.reset();
      }
    },
    "editedEntry.productId": function(newValue) {
      if (newValue == null) this.editedEntry.price = null;
    }
  },
  data() {
    return {
      products: [],
      isLoading: false,
      availability: [
        { value: "Local", text: this.$t('itemDialogContentLocal') },
        { value: "Order", text: this.$t('itemDialogContentOrder') },
        { value: "LocalAndOrder", text:  this.$t('itemDialogContentLocalAndOrder') },
      ],
      valid: true,
      loading: {
        products: true,
        newItem: false,
      },
      rules: {
        nonEmpty: (v) => /\S/.test(v) || this.$t('nonEmptyRules'),
        required: (v) => !!v || this.$t('requiredRules'),
        requiredDesired: (v) => v !== null && v !== undefined && v !== "" || this.$t('requiredDesiredRules'),
        integer: (v) => !v || /^\d+$/.test(v) || this.$t('integerRules'),
        float: (v) => !isNaN(v) || this.$t('floatRules'),
        uniqueSelection: (v) =>
          !this.isNew ||
          !this.selectionList.includes(Number(v)) ||
          this.$t('uniqueSelectionRules'),
        requiredIf: (v, condition) =>
          !condition || !!v || this.$t('requiredIfRules'),
        isUrl: (v) => !v ||
          !v || /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}[.]{0,1}/.test(
            v
          ) || this.$t('isUrlRules'),
      },
    };
  },
  methods: {
    saveItem() {
      if (this.$refs.form.validate()) {
        this.loading.newItem = true;
				this.editedEntry.planogramId = this.planogramId;
				if (this.isNew) {
          // is new item
					this.axios
						.post("api/PlanogramEntries", this.editedEntry)
						.then(() => {
              this.$emit('entry-created')
              this.show = false
						})
						.catch(error => this.$emit('entry-error', this.$t('errorSavingItem'), error))
						.then(() => (this.loading.newItem = false));
				} else {
          this.axios
						.put("api/PlanogramEntries", this.editedEntry)
						.then(response => {
              this.$emit('entry-updated', response.data)
							this.show = false
						})
						.catch(error => this.$emit('entry-error', this.$t('errorSavingItem'), error))
						.then(() => (this.loading.newItem = false));
				}
			}
		},
    changeProduct(product) {
      this.$set(this.editedEntry, "price", product.defaultPrice);
    }
  }
}
</script>