export default {
    tittleDialog: 'ATENÇÃO',
    approvedTextDialogDescription: 'Este processo refere-se apenas à transferência de titularidade dos PDVs no sistema. A transferência da máquina de pagamento junto à adquirente <b>também é necessária</b>. <span style="color:#FF5252;">Caso ainda não tenha realizado a transferência</span>, siga as orientações fornecidas no vídeo a seguir para concluir a migração do terminal.</br> Caso essa transferência não seja cumprida, o faturamento da loja continuará sendo creditado ao antigo titular.',
    rejectedTextDialogStart:'Tem certeza de que deseja <span style="color:#FF5252;">reprovar</span> esta transferência de PDV? Essa ação é irreversível e exigirá que você reinicie todo o processo.',
    rejectedTextDialogComment: 'Por favor, deixe um comentário relatando o motivo da reprovação',
    rejectedCommentPlaceHolder: 'Deixe aqui o motivo...',
    btnApproved: 'APROVAR',
    btnRejected: 'SIM',
    btnClose: 'FECHAR',
    termResponsibilityPOS : 'Entendo que, ao declarar que realizei a transferência de conta da máquina de pagamento, estou ciente de que a conclusão dessa etapa é essencial para o correto funcionamento do PDV e para o recebimento dos faturamentos.',
    termResponsibilityTransfer: 'Entendo que assumo todas as responsabilidades administrativas, operacionais e financeiras relacionadas às máquinas e PDVs que estou recebendo do antigo titular.',
    msgSuccessMigration: 'Duas aprovações foram confirmadas: Ponto de venda migrado com sucesso.',
};