export default {
  months:{
    january: 'January',
    jan: 'Jan',
    february: 'February',
    feb: 'Feb',
    march: 'March',
    mar: 'Mar',
    april: 'April',
    apr: 'Apr',
    may: 'May',
    june: 'June',
    jun: 'Jun',
    july: 'July',
    jul: 'Jul',
    august: 'August',
    aug: 'Aug',
    september: 'September',
    sep: 'Sep',
    october: 'October',
    oct: 'Oct',
    november: 'November',
    nov: 'Nov',
    december: 'December',
    dec: 'Dec',
  },
  days: {
    sunday: 'Sunday',
    sun: 'Sun',
    monday: 'Monday',
    mon: 'Mon',
    tuesday: 'Tuesday',
    tue: 'Tue',
    wednesday: 'Wednesday',
    wed: 'Wed',
    thursday: 'Thursday',
    thu: 'Thu',
    friday: 'Friday',
    fri: 'Fri',
    saturday: 'Saturday',
    sat: 'Sat'
  },
  dateIntervals: {
    thirtyDays: '30 days',
    fifteenDays: '15 days',
    sevenDays: '7 days',
    sameDay: 'same day',
  }
}
