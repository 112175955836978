
import Vue from 'vue';
import RupturesProductsFilters from './components/RupturesProductsFilters.vue';
import RupturesProductsHelp from './helpers/RupturesProductsHelp.vue'
import { formatDateTimeMinutes } from '@/utility/TextFormatting'
// eslint-disable-next-line no-unused-vars
import { RuptureOccurrenceFilters, RuptureProduct } from '@/types/Rupture'

export default Vue.extend({
  components: {
    RupturesProductsFilters,
    RupturesProductsHelp
  },
  data() {
    const $currencyFormatter = (this as any).$currencyFormatter;
    return{
      ruptureProducts: [] as RuptureProduct[] | undefined,
      totals: {
        ocurrences: 0,
        salesLosses: 0,
        profitLosses: 0
      },
      headers: [
        { text: this.$t('tables.headers.id'), align: 'left', sortable: true, value: 'itemProductId' },
        { text: this.$t('tables.headers.productCode'), align: 'left', sortable: true, value: 'itemProductCode' },
        { text: this.$t('tables.headers.description'), align: 'left', sortable: true, value: 'itemProductDescription' },
        { text: this.$t('tables.headers.category'), align: 'left', sortable: true, value: 'itemProductCategoryName' },
        { text: this.$t('tables.headers.occurrences'), align: 'left', sortable: true, filterable: false, value: 'ruptureOccurrences' },
        { text: this.$t('tables.headers.totalDays'), align: 'left', sortable: true, filterable: false, value: 'totalRuptureDays' },
        { text: this.$t('tables.headers.totalLosses'), align: 'left', sortable: true, filterable: false, value: 'totalSalesLost' },
        { text: this.$t('tables.headers.totalLossesValue')+`(${$currencyFormatter.getSign()})`, align: 'left', sortable: true, filterable: false, value: 'totalProfitLoss' },
        { text: this.$t('tables.headers.actions'), align: 'center', sortable: false, filterable: false, value: 'actions' }
      ],
      loading: {
        ruptureProducts: true,
      },
      form: {} as RuptureOccurrenceFilters | undefined,
      search: '',
    }
  },
  methods: {
    updateFilters(form: any) {
      this.form = form;
      this.getRuptureProducts();
    },
    getRuptureProducts() {
    this.loading.ruptureProducts = true;
    this.axios
      .get('api/ruptures/products', { params: this.form })
      .then((response) => {
        this.loading.ruptureProducts = false;
        this.ruptureProducts = response.data.ruptureItems;
        this.totals.ocurrences = response.data.totalOcurrences;
        this.totals.salesLosses = response.data.totalLossesSales;
        this.totals.profitLosses = response.data.totalLossesProfit;
      });
    },
    formatDateTimeMinutes,
    goToItemOcurrences(item: RuptureProduct) {
      const filters = {
        inventoryIds: this.form?.inventoryIds,
        inventoryType: this.form?.inventoryType,
        productId: item.itemProductId,
        minDate: this.form?.minDate,
        maxDate: this.form?.maxDate
      }
      // This emit will set a prop in parent RupturesMaster, which will, in turn,
      // send the filters information to it's children components via props.
      this.$emit('goToOcurrences', filters);
    }
  },
});
