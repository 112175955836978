import ButtonEn from './ButtonEn';
import RemoveDialogEn from './RemoveDialogEn';
import PlanogramEn from './PlanogramEn';
import PriceTablesEn from './PriceTablesEn';
import PointOfSaleEn from './PointOfSaleEn';
import FranchiseeEn from './FranchiseeEn';
import TablesEn from './TablesEn';
import ReportEn from './ReportEn';
import FiltersEn from './FiltersEn';
import UsersEn from './UsersEn';
import BoxEn from './BoxEn';
import ProductsEn from './ProductsEn';
import PromotionLogoEn from './PromotionLogoEn';
import SecurityEn from './SecurityEn';
import UnlockEn from './UnlockEn';
import ValidationsEn from './ValidationsEn';
import TenantEn from './TenantEn';
import InventoryEn from './InventoryEn';
import AlertsEn from './AlertsEn';
import ConnectivityEn from './ConnectivityEn';
import TelemetryEn from './TelemetryEn';
import MissingProductsEn from './MissingProductsEn';
import TaxingsEn from './TaxingsEn';
import PickListsEn from './PickListsEn';
import PurchaseEn from './PurchaseEn';
import DatesEn from './DatesEn';
import RuptureReportsEn from './RuptureReportsEn';

export default Object.assign({},
  PriceTablesEn,
  ButtonEn,
  PlanogramEn,
  RemoveDialogEn,
  PointOfSaleEn,
  FranchiseeEn,
  TablesEn,
  ReportEn,
  FiltersEn,
  PromotionLogoEn,
  SecurityEn,
  UnlockEn,
  UsersEn,
  BoxEn,
  ProductsEn,
  ValidationsEn,
  TenantEn,
  InventoryEn,
  AlertsEn,
  ConnectivityEn,
  TelemetryEn,
  MissingProductsEn,
  TaxingsEn,
  PurchaseEn,
  DatesEn,
  PickListsEn,
  RuptureReportsEn
);
