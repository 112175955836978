<template>
  <v-menu v-model="saveReportMenu" offset-y style="max-width: 600px" :close-on-content-click="false">
    <template #activator="{ on: dialog }">
      <v-btn v-on="dialog" class="ma-1" text>
        <v-icon left>mdi-star</v-icon> {{ $t('filters.labels.savedFilters') }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click.stop="saveReportDialog = true">
        <v-list-item-action>
          <v-icon large>mdi-star-plus</v-icon>
        </v-list-item-action>
        <v-list-item-content>{{ $t('filters.labels.saveFilters') }}</v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header class="px-0" expand-icon="">
              <template>
                <div align="left" class="nobr">
                <v-icon large class="pr-7">mdi-star</v-icon> {{ $t('filters.labels.savedFilters') }}
                </div>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-select
                  :label="$t('filters.labels.savedFilters')"
                  v-model="selectedReport"
                  return-object
                  :items="savedReports"
                  item-text="description"
                  :loading="loading.savedReports"
                  clearable
                >
                  <template #item="data">
                    <v-list-item-content>
                      <v-list-item-title>{{ data.item.description }}</v-list-item-title>
                    </v-list-item-content>
                    <v-tooltip left :disabled="data.item.userId != null ">
                      <template #activator="{ on }">
                        <div v-on="on">
                          <v-btn
                            color="error"
                            text
                            :disabled="data.item.userId == null"
                            @click.native.capture.stop="removeSavedReport(data.item)">
                            <v-icon>remove_circle_outline</v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <span>{{ $t('filters.saveReport.onlyUser') }}</span>
                    </v-tooltip>
                  </template>
                </v-select>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-list-item>
    </v-list>
    <v-dialog v-model="saveReportDialog" max-width="500px">
      <v-form ref="form" lazy-validation @submit.prevent="saveFilters">
        <v-card max-width="500px">
          <v-card-title>{{ $t('filters.saveReport.title') }}</v-card-title>
          <v-card-text>
            <v-row class="mx-0 py-2">
              <v-text-field
                v-model="newReportDescription"
                :label="$t('filters.labels.reportName')"
                :rules="[rules.required, rules.nonEmpty]"
                required
              >
              </v-text-field>
            </v-row>
            <v-row cols="12">
              <v-col cols="6" v-if="filtersList.length > 0">
                <h3>{{ $t('filters.title') }}</h3>
                <div v-for="(filter, i) in filtersList" :key="i">
                  {{ returnFilterName(filter.filterName) }}: {{ filter.filterValue }}
                </div>
              </v-col>
              <v-col cols="6" v-if="groups.length > 0">
                <h3>{{ $t('filters.groupings') }}</h3>
                <div v-for="(group, i) in groups" :key="i">
                  {{ i + 1 }}. {{ returnGroupName(group) }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <CancelButton
              @cancel="saveReportDialog = false; newReportDescription = ''"
            />
            <SaveButton
              :disabled="!valid"
              :loading="loading.saveFilter"
            />
          </v-card-actions>
        </v-card>
      </v-form>
      <feedback ref="feedback"></feedback>
    </v-dialog>
  </v-menu>
</template>

<script>
import FilterNamesTranslations from './FilterNamesTranslations';
import CancelButton from "@/components/buttons/CancelButton.vue";
import SaveButton from "@/components/buttons/SaveButton.vue";

export default {
  components: {
    CancelButton,
    SaveButton
  },
  props: {
    value: { type: Boolean, required: true },
    // filtersList: { type: Array, default: () => [] },
    form: { type: Object, default: () => {} },
    definedDateInterval: { type: Number, default: null },
    groups: { type: Array, default: () => {} },
    type: { type: String, default: "Transactions" },
    selectedSavedReport: { type: Object, default: () => {} }
  },
  mounted() {
    this.getSavedReports();
  },
  computed: {
    saveReportMenu: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('window', value);
      }
    },
    selectedReport: {
      get() {
        return this.selectedSavedReport;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    // The Object below (filtersList) will be sent to the back end.
    // It has to be a list<(filtername: key, filterValue: value)>, value != null, for every filter.
    // It will *NOT* have the group[] values.
    // It will *NOT* have minDate either maxDate.
    // It *WILL* have dateInterval, if it is != null.
    filtersList: function() {
      let list = Object.entries(this.form);
      list.splice(list.findIndex(i => i[0] == "minDate"), 1); // Removes minDate
      list.splice(list.findIndex(i => i[0] == "maxDate"), 1); // Removes maxDate
      list.splice(list.findIndex(i => i[0] == "group"), 1);   // Removes group[]
      if (this.definedDateInterval !== null) list.push(["dateInterval", this.definedDateInterval]);

      // The function below filters the null filters
      function pairValueIsNull(element) {
        return element[1] === null || element[1] === "" || element[1].length === 0;
      }

      // The function below converts the Array[[]] into an Array[object{"filterName", "filterValue"}]
      function convertArrayToObject(pair) {
        return { filterName: pair[0], filterValue: JSON.stringify(pair[1]) }
      }
      list = list.filter(i => !pairValueIsNull(i));         // Filter null filters
      let finalList = list.map(pair => convertArrayToObject(pair));     // Convert array(array) to array(object)
      return finalList;
    }
  },
  data() {
    return {
      newReportDescription: "",
      filterNames: FilterNamesTranslations.filterNames,
      valid: true,
      rules: {
        required: (v) => !!v || this.$t('requiredRules'),
        nonEmpty: (v) => /\S/.test(v) || this.$t('nonEmptyRules'),
      },
      loading: {
        saveFilter: false,
        savedReports: true,
      },
      groupNames: [
        { text: this.$t('filters.labels.product'), value: "product" },
        { text: this.$t('filters.labels.category'), value: "category" },
        { text: this.$t('filters.inventory'), value: "inventory" },
        { text: this.$t('filters.labels.operation'), value: "type" },
        { text: this.$t('filters.labels.day'), value: "day" },
        { text: this.$t('filters.labels.month'), value: "month" },
        { text: this.$t('filters.labels.dayOfWeek'), value: "dayOfWeek" },
        { text: this.$t('filters.labels.customers'), value: "customerName" },
        { text: this.$t('filters.labels.pointsOfSale'), value: "pointOfSaleId" },
        { text: this.$t('filters.labels.states'), value: "addressState" },
        { text: this.$t('filters.labels.paymentMethods'), value: "paymentMethod" },
        { text: this.$t('filters.labels.cardBrands'), value: "cardBrand" },
      ],
      saveReportDialog: false,
      savedReports: [],
    }
  },
  methods: {
    saveFilters() {
      if(this.$refs.form.validate()) {
        this.loading.saveFilter = true;
        let params = {
          id: 0,
          description: this.newReportDescription,
          reportType: this.type,
          groupColumns: this.groups,
          savedReportFilters: this.filtersList
        }
        this.axios.post("/api/SavedReports", params)
          .then((response) => {
            this.loading.saveFilter = false;
            this.$emit('filterAdded', `${this.$t('filters.title')} '${response.data.description}' ${this.$t('filters.saveReport.savedSuccessfully')}`);
            this.savedReports.push(response.data);
            this.saveReportMenu = false;
            this.newReportDescription = null;
          })
          .catch((error) => {
            this.$refs.feedback.handleError(this.$t('filters.saveReport.errorWhenSaving'), error);
            this.loading.saveFilter = false;
          });
      }
    },
    returnFilterName(filter) {
      const pair = this.filterNames.find(pair => pair.value == filter);
      return pair?.name || null;
    },
    returnGroupName(group) {
      const pair = this.groupNames.find(pair => pair.value == group);
      return pair?.text;
    },
    getSavedReports() {
      this.loading.savedReports = true;
      this.axios.get(`/api/SavedReports/${this.type}`)
        .then((response) => {
          this.savedReports = response.data;
        })
        .catch(() => {})
        .then(() => this.loading.savedReports = false);
    },
    removeSavedReport(item) {
      let confirmed = confirm(`${this.$t('filters.saveReport.deleteConfirmation')} '${item.description}'?`);
      if (confirmed == false) return;
      const id = item.id;
      this.axios.delete(`/api/SavedReports/${id}`)
        .then(() => {
          this.savedReports = this.savedReports.filter(sr => sr.id != id);
        })
        .catch((error) => {
          if(error.response && error.response.data) error = error.response.data;
          const message = this.$t('filters.saveReport.error.unableToRemove') + (error == null ? '' : (': ' + error));
          alert(message);
        });
    },
  }
}
</script>

<style>
.nobr { white-space: nowrap;
        font-size: 16px; }
</style>
