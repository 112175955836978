export default {
  months: {
    january: 'Janeiro',
    jan: 'Jan',
    february: 'Fevereiro',
    feb: 'Fev',
    march: 'Março',
    mar: 'Mar',
    april: 'Abril',
    apr: 'Abr',
    may: 'Maio',
    june: 'Junho',
    jun: 'Jun',
    july: 'Julho',
    jul: 'Jul',
    august: 'Agosto',
    aug: 'Ago',
    september: 'Setembro',
    sep: 'Set',
    october: 'Outubro',
    oct: 'Out',
    november: 'Novembro',
    nov: 'Nov',
    december: 'Dezembro',
    dec: 'Dez'
  },
  days: {
    sunday: 'Domingo',
    sun: 'Dom',
    monday: 'Segunda-feira',
    mon: 'Seg',
    tuesday: 'Terça-feira',
    tue: 'Ter',
    wednesday: 'Quarta-feira',
    wed: 'Qua',
    thursday: 'Quinta-feira',
    thu: 'Qui',
    friday: 'Sexta-feira',
    fri: 'Sex',
    saturday: 'Sábado',
    sat: 'Sáb'
  },
  dateIntervals:{
    thirtyDays:'30 dias',
    fifteenDays:'15 dias',
    sevenDays:'7 dias',
    sameDay:'mesmo dia',
  }
}
