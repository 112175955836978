export default {
  months: {
    january: 'Enero',
    jan: 'Ene',
    february: 'Febrero',
    feb: 'Feb',
    march: 'Marzo',
    mar: 'Mar',
    april: 'Abril',
    apr: 'Abr',
    may: 'Mayo',
    june: 'Junio',
    jun: 'Jun',
    july: 'Julio',
    jul: 'Jul',
    august: 'Agosto',
    aug: 'Ago',
    september: 'Septiembre',
    sep: 'Sep',
    october: 'Octubre',
    oct: 'Oct',
    november: 'Noviembre',
    nov: 'Nov',
    december: 'Diciembre',
    dec: 'Dic'
  },
  days: {
    sunday: 'Domingo',
    sun: 'Dom',
    monday: 'Lunes',
    mon: 'Lun',
    tuesday: 'Martes',
    tue: 'Mar',
    wednesday: 'Miércoles',
    wed: 'Mié',
    thursday: 'Jueves',
    thu: 'Jue',
    friday: 'Viernes',
    fri: 'Vie',
    saturday: 'Sábado',
    sat: 'Sáb'
  },
  dateIntervals: {
    thirtyDays: '30 días',
    fifteenDays: '15 días',
    sevenDays: '7 días',
    sameDay: 'mismo día',
  },
}
