<template>
  <v-expansion-panels hover>
    <v-expansion-panel>

      <v-expansion-panel-header>
        <div>
          <v-icon>filter_list</v-icon>
          {{ $t('filters.filtersActions') }}
        </div>
        <v-spacer></v-spacer>
      </v-expansion-panel-header>

      <v-expansion-panel-content class="pb-2" eager>
        <v-form ref="form" v-model="valid">

          <!-- Filters Row -->
          <v-row class="pt-5">

            <!-- 1st Column-->
            <v-col cols="12" md="3">
              <interval-date-picker
                  :interval-max-range=31
                  :interval-start-range=7
                  :reset-date="IntervalDatePicker.reset"
                  @intervalDateUpdated="onIntervalDateUpdated"
              ></interval-date-picker>
            </v-col>

            <!-- 2nd Column -->
            <v-col cols="12" md="3">
              <products-filter
                  ref="productDropDown"
                  v-model="form.productId"
                  :required="false"
                  icon='shopping_cart'
              ></products-filter>
            </v-col>
          </v-row>

          <!-- Buttons Row -->
          <v-row class="pa-2" justify="end">
            <v-btn
                class="ma-1"
                color="error"
                text
                @click.native="clearQuery"
            >
              <v-icon left>clear</v-icon>
              {{ $t('buttons.clear') }}
            </v-btn>

            <v-btn
                :disabled="!valid"
                :loading="loading.excel"
                class="ma-1"
                color="info"
                @click.native="getExcel"
            >
              <v-icon left>mdi-file-excel</v-icon>
              {{ $t('buttons.excel') }}
            </v-btn>

            <v-btn
                :disabled="!valid"
                class="ma-1"
                color="success"
                v-on:click.native="emitModification"
            >
              <v-icon left>refresh</v-icon>
              {{ $t('buttons.update') }}
            </v-btn>
          </v-row>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import download from '@/components/Download';
import ProductsFilter from "@/components/filters/ProductsFilter";
import IntervalDatePicker from "@/components/filters/IntervalDatePicker";

export default {
  components: {
    IntervalDatePicker,
    ProductsFilter
  },
  data() {
    return {
      valid: false,
      expansionIndex: 0,
      form: {
        minDate: null,
        maxDate: null,
        productId: null,
        inventoryType: 'pointOfSale'
      },
      loading: {
        excel: false
      },
      IntervalDatePicker: {
        reset: false,
      },
    };
  },
  mounted() {
    this.emitModification();
  },
  methods: {
    getExcel() {
      if (this.$refs.form.validate()) {
        this.loading.excel = true;
        this.axios
            .get('api/ruptures/pointofsales/excel', {params: this.form, responseType: 'blob'})
            .then((response) => {
              this.loading.excel = false;
              download(response);
            }).catch(() => {
          this.loading.excel = false;
        });
      }
    },
    emitModification() {
      if (this.$refs.form.validate()) {
        this.$emit("modify", this.form);
      }
    },
    newForm() {
      return {
        minDate: this.form.minDate,
        maxDate: this.form.maxDate,
        inventoryId: null,
        productId: null,
        inventoryType: 'pointOfSale'
      };
    },
    clearQuery() {
      this.form = this.newForm();
      this.IntervalDatePicker.reset = true;
      this.emitModification();
    },
    onIntervalDateUpdated(minDate, maxDate, resetDate) {
      if (resetDate) {
        this.IntervalDatePicker.reset = false;
      }
      this.form.minDate = minDate;
      this.form.maxDate = maxDate;
    },
  },
};
</script>
