
import Vue from 'vue';
import { AcquisitionOrders } from '@/api/agent';
import {
  AcquisitionOrderRequestDto,
  FilledCustomerInfoDto,
  CustomerFullInfoDto,
  CatalogItem,
  PaymentProcessorDto,
  DeliveryAddress } from '@/types/MidasProductsOrders'
import IconButton from "@/components/buttons/IconButton.vue";
import BrazilStates from "@/components/BrazilStates";
import {useAlertsStore} from "@/stores/alertsStore";
import AcquisitionOrderTokenHelpActivation from "@/views/acquisitionOrders/helpers/AcquisitionOrderTokenHelpActivation.vue";
import AcquisitionOrderTokenHelp from "@/views/acquisitionOrders/helpers/AcquisitionOrderTokenHelp.vue";

const defaultEnvironmentContractInfo = {
  contractId: 0,
  billingDocumentId: null,
  customerInfo: {
    fantasyName: null,
    email: null,
    city: null,
    cep: null,
    address: null,
    addressNumber: null,
    addressComplement: null,
    brazilState: null,
    phone: null,
  } as FilledCustomerInfoDto,
  paymentProcessorListDto: [{
    id:null,
    email:  null,
    activationCode:  null,
    apiToken:  null,
  } as PaymentProcessorDto] as PaymentProcessorDto[],
} as CustomerFullInfoDto

export default Vue.extend({
  computed: {
    BrazilStates() {
      return BrazilStates
    }
  },
  components: {
    AcquisitionOrderTokenHelpActivation,
    AcquisitionOrderTokenHelp,
    IconButton,
  },
  props: {
    parentOrderProducts: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      environmentContractInfo: { ...defaultEnvironmentContractInfo },
      loading: false,
      valid: {
        order: false,
        newPaymentProcessor: false,
        newCustomerAddress: false
      },
      finalOrderProducts: [] as (CatalogItem & {selectedQuantity: number})[],

      // variables for the paymentProcessors radio buttons and dialog
      paymentProcessors: [] as PaymentProcessorDto[],
      selectedPaymentProcessorIndex: 0,
      newPaymentProcessorDialog: false,
      editPaymentProcessorBool:false,
      editPaymentProcessorIndex: 0,
      states: BrazilStates.states.map(state => state.value),
      cnpjIsRegular: true,
      newPaymentProcessor: {
        id:null,
        email: '',
        activationCode: '',
        apiToken: ''
      } as PaymentProcessorDto,

      // variables for the customerAddresses radio buttons and dialog
      deliveryAdresses: [] as (DeliveryAddress | null)[],
      selectedDeliveryAddressIndex: 0,
      cep:0,
      newCustomerAddress: {
        city: "",
        cep: "",
        address: "",
        addressNumber: null,
        addressComplement: null as string | null,
        brazilState: "",
      } as FilledCustomerInfoDto,
      newCustomerAddressDialog: false
    }
  },
  watch: {
    async cep(newCep){
      newCep = newCep.replace(/[^0-9]/g, '');
      if(newCep.length === 8){
        await AcquisitionOrders.getDeliveryAddress(newCep).then((response) => {
          this.newCustomerAddress = Object.assign(this.newCustomerAddress, response);
        }).catch(() => {
          (this.$refs.feedback as any).handleError(this.$t("cepError"));
          this.newCustomerAddress = {
            city: "",
            cep: "",
            address: "",
            addressNumber: null,
            addressComplement: null,
            brazilState: "",
          }
        });
      }
    }
  },
  mounted() {
    this.getEnvironmentContractInfo();
    this.finalOrderProducts = this.parentOrderProducts as any[];
    this.validateDocumentId();
  },
  methods: {
    async validateDocumentId(){
      let alertStore = useAlertsStore();
      await AcquisitionOrders.validateDocumentId()
        .then((response) => {
          if (response.every(r => r.status.toString() === 'Active')
            && response.every(r => r.ieIsValid)) {
            alertStore.showSuccessSnackbar(this.$t('documentIdIsOk').toString());
            this.cnpjIsRegular = true;
          } else {
            this.cnpjIsRegular = false;
            let message = "";

            const invalidDocuments = response.filter(r =>
              r.status.toString() !== 'Active' || !r.ieIsValid
            );

            // Verifique se há documentos inválidos antes de continuar
            if (invalidDocuments.length > 0) {
              invalidDocuments.forEach(r => {
                const localizedMessage = this.$t("documentIdIsNotOk", {
                  status: this.$t(`statusEnum.${r.status}`),
                  ieStatus: this.$t(r.ieIsValid ? "ieStatus.Ok" : "ieStatus.NotOk"),
                  documentId: r.documentId
                }).toString();
                message += localizedMessage + "\n\n\n";
              });

              alertStore.showErrorSnackbar(message,50000);
            } else {
              console.error("Nenhum documento inválido encontrado, mas o fluxo caiu no else.");
            }
          }
        })
        .catch(() => {
          this.cnpjIsRegular = false;
          alertStore.showErrorSnackbar(this.$t('missingDocumentIdError').toString(),50000);
        });

    },
    async getEnvironmentContractInfo() {
      try {
        const response = await AcquisitionOrders.getEnvironmentContractInfo();
        this.environmentContractInfo = Object.assign(this.environmentContractInfo, response);
      } catch (error) {
        (this.$refs.feedback as any).handleError(error);
        this.environmentContractInfo = { ...defaultEnvironmentContractInfo }
      } finally {
        this.initializePaymentProcessorsArray();
        this.initializeCustomerInfosArray();
      }
    },
    editPaymentProcessor(index: number) {
      this.newPaymentProcessor = Object.assign({}, this.paymentProcessors[index]);
      this.newPaymentProcessorDialog = true;
      this.editPaymentProcessorBool = true;
      this.editPaymentProcessorIndex = index;
    },
    closeDialog() {
      this.newPaymentProcessorDialog = false;
      this.newCustomerAddressDialog = false;
      this.newPaymentProcessor = {
        id:null,
        email: '',
        activationCode: '',
        apiToken: ''
      } as PaymentProcessorDto;
    },
    async sendOrder() {
      if ((this.$refs.form as any).validate()) {

        const selectedAddress = this.deliveryAdresses[this.selectedDeliveryAddressIndex]
        if (selectedAddress != null && Object.entries(selectedAddress as DeliveryAddress)
          .filter(([key, ]) => key !== 'addressComplement')
          .some(([, value]) => value === null)
        ) {
          (this.$refs.feedback as any).handleError(this.$t("missingAddressInformationError"));
          return;
        }

        const paymentProcessorDto = this.paymentProcessors[this.selectedPaymentProcessorIndex]

        if (paymentProcessorDto == undefined) {
          (this.$refs.feedback as any).handleError(this.$t("missingPaymentProcessorError"));
          return;
        }
        if(paymentProcessorDto.email === null || paymentProcessorDto.email === undefined){
          (this.$refs.feedback as any).handleError(this.$t("missingEmailInformationError"));
          return;
        }
        if(paymentProcessorDto.apiToken === null || paymentProcessorDto.apiToken === undefined){
          (this.$refs.feedback as any).handleError(this.$t("missingApiTokenInformationError"));
          return;
        }
        if(paymentProcessorDto.activationCode === null || paymentProcessorDto.activationCode === undefined){
          (this.$refs.feedback as any).handleError(this.$t("missingActivationCodeInformationError"));
          return;
        }

        const orderItems = this.finalOrderProducts.map(product => {
          return {
            quantity: product.selectedQuantity,
            catalogItemId: product.id
          }
        })

        let phone = this.environmentContractInfo.customerInfo.phone;

        if(phone === null || phone === undefined){
          (this.$refs.feedback as any).handleError(this.$t("missingPhoneInformationError"));
          return;
        }

        if (phone.length === 10 || phone.length === 11) {
          phone = `55${phone}`;
        }

        const params: AcquisitionOrderRequestDto = {
          orderItems: orderItems,
          tenantId: (this as any).$auth.user().tenantId,
          groupId: (this as any).$auth.user().tenantGroupId,
          fantasyName: this.environmentContractInfo.customerInfo.fantasyName,
          email: this.environmentContractInfo.customerInfo.email,
          phoneNumber: phone,
          deliveryAddress: selectedAddress,
          paymentProcessorDto: paymentProcessorDto
        }

        this.loading = true;
        try {
          await AcquisitionOrders.postAcquisitionOrder(params);
          this.$emit('orderSent');
        } catch (error) {
          (this.$refs.feedback as any).handleError(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async pushNewPaymentProcessor() {
      (this.$refs.newPaymentProcessorForm as any).validate();
      const newPaymentProcessor = Object.assign({}, this.newPaymentProcessor);
      if(this.editPaymentProcessorBool){
        this.paymentProcessors.splice(this.editPaymentProcessorIndex, 1);
        this.selectedPaymentProcessorIndex = this.paymentProcessors.length - 1;
        this.editPaymentProcessorBool = false;
      }
      this.paymentProcessors.push(newPaymentProcessor);
      this.newPaymentProcessorDialog = false;
      this.selectedPaymentProcessorIndex = this.paymentProcessors.length - 1;
      const params = {
        tenantId: (this as any).$auth.user().tenantId,
        groupId: (this as any).$auth.user().tenantGroupId
      }
      await AcquisitionOrders.upsertPagseguroAccountBulk(this.paymentProcessors, params);
      this.newPaymentProcessor = {
        id:null,
        email: '',
        activationCode: '',
        apiToken: ''
      } as PaymentProcessorDto;
      await this.getEnvironmentContractInfo();
    },
    pushNewCustomerAddress() {
      (this.$refs.newCustomerAddressForm as any).validate();
      const newCustomerAddress = Object.assign({}, this.newCustomerAddress);
      this.deliveryAdresses[0] = newCustomerAddress;
      this.newCustomerAddressDialog = false;
      this.newCustomerAddress = {
        city: "",
        cep: "",
        address: "",
        addressNumber: null,
        addressComplement: "",
        brazilState: "",
      }
      this.cep = 0;
    },
    initializePaymentProcessorsArray() {
      this.paymentProcessors = Object.assign([], this.environmentContractInfo.paymentProcessorListDto);
    },
    initializeCustomerInfosArray() {
      const deliveryAddressFromEnvironment = {
        city: this.environmentContractInfo.customerInfo.city,
        cep: this.environmentContractInfo.customerInfo.cep,
        address: this.environmentContractInfo.customerInfo.address,
        addressNumber: this.environmentContractInfo.customerInfo.addressNumber,
        addressComplement: this.environmentContractInfo.customerInfo.addressComplement,
        brazilState: this.environmentContractInfo.customerInfo.brazilState,
      } as DeliveryAddress;

      this.deliveryAdresses = Object.values(deliveryAddressFromEnvironment).some(value => value !== null)
       ? ([Object.assign({}, deliveryAddressFromEnvironment)]) : [];

      this.deliveryAdresses.push(null);
    },
    async deletePaymentProcess(id: number){
      await AcquisitionOrders.deletePaymentProcess(id);
      this.paymentProcessors = this.paymentProcessors.filter(paymentProcessor => paymentProcessor.id !== id);
    }
  }
})
