export default {
    filter: 'Filtrar',
    period: 'Período',
    startDate: 'Data inicial',
    endDate: 'Data final',
    initialHour: 'Hora Inicial',
    finalHour: 'Hora Final',
    customer: 'Cliente',
    category: 'Categoria',
    statePointOfSale: 'Estado do Ponto de Venda',
    location: 'Local',
    locations: 'Localização',
    showZeroedPOS: 'Mostrar PDVs zerados',
    update: 'Atualizar',
    clear: 'Limpar',
    requiredRules: 'Este campo é obrigatório',
    noData: 'Nenhum dado disponível',
    all: 'Todos',
    allF: 'Todas',
    pdv: 'PDV',
    categoryId: 'ID da Categoria',
    productId: 'ID do Produto',
    selectALocation: 'Selecione antes uma localização',
  }
  