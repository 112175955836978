
import Vue from 'vue'
import { UnusedPlanogramEntryRequest, ProductUnusedDto } from '@/types/PlanogramTypes'
import agent from '@/api/agent'
import { PaginatedList } from '@/types/common/PaginatedList'
import { formatDateTimeToDate } from "@/utility/TextFormatting";
import DateChipFilter from '@/components/filters/DateChipFilter.vue';
import moment from 'moment';
import SingleDatePicker from '@/components/filters/SingleDatePicker.vue';
import UnusedProductsDialogHelp from './DialogProductsUnusedHelp.vue';
import { shouldRefreshData } from '@/components/TableOptionsComparator';

export default Vue.extend({
  data() {
    return {
      headers: [
        { text: this.$t('dialogProductsUnusedCodeProduct'), align: 'left', sortable: false, value: 'productCode' },
        { text: this.$t('dialogProductsUnusedDescription'), align: 'left', sortable: false, value: 'productDescription' },
        { text: this.$t('dialogProductsUnusedTablePlanogramId'), align: 'left', sortable: false, value: 'planogramId' },
        { text: this.$t('dialogProductsUnusedLastMovement'), align: 'left', sortable: false, value: 'lastUpdate' },
        { text: this.$t('dialogProductsUnusedQuantity'), align: 'left', sortable: false, value: 'quantity' },
      ],
      options: {
        itemsPerPage: 10 as number,
        page: 1 as number,
      },
      productUnused: [] as ProductUnusedDto[],
      params: {} as UnusedPlanogramEntryRequest,
      totalItems: 0 as number,
      loading: false as boolean,
      dateIntervals: [
        { text: this.$t('dialogProductsUnusedFilter30Days'), value: 30 },
        { text: this.$t('dialogProductsUnusedFilter60Days'), value: 60 },
        { text: this.$t("dialogProductsUnusedFilter90Days"), value: 90 },
        { text: this.$t("dialogProductsUnusedFilterCustom"), value: 0 },
      ],
      dateFilter: moment().subtract(30, 'days').format("YYYY-MM-DD") as string,
      selectedDateInterval: 30 as number | null,
      maxDate: moment().format("YYYY-MM-DD") as string,
      selectAll: false as boolean,
      selectedItems: [] as number[],
    }
  },
  props: {
    value: { type: Boolean, default: false },
    planogramId: { type: Number, default: null },
    posId: { type: Number, default: null },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    },
    computedHeaders(): any[] {
      if (this.selectAll) {
        return [{ text: '', align: 'left', sortable: false, value: 'checkbox' }, ...this.headers]
      }
      return this.headers;
    }
  },
  watch: {
    value: {
      handler(value: boolean) {
        if (value) {
          this.getProductsUnused();
        }
      }
    },
    dateFilter: {
      handler(value: string | null) {
        if (!value) return;
        let diff = moment().diff(value, 'days');
        let isInDateIntervals = this.dateIntervals.some(interval => interval.value == diff);
        if (!isInDateIntervals || diff == 0) {
          this.selectedDateInterval = 0;
        }
        if (this.selectedDateInterval == 0) {
          this.getProductsUnused();
        }
      }
    },
    selectedDateInterval: {
      handler(value: number) {
        if (value) {
          this.dateFilter = moment().subtract(value, 'days').format("YYYY-MM-DD");
          this.getProductsUnused();
        }
      }
    },
    options: {
      handler(newOptions, oldOptions) {
       if (shouldRefreshData(newOptions, oldOptions)){
          this.getProductsUnused();
        }
      },
      deep: true
    }
  },
  methods: {
    getProductsUnused() {
      this.loading = true;
      this.params = {
        planogramId: this.planogramId,
        lastUpdateProduct: this.dateFilter,
        page: this.options.page,
        pageSize: this.options.itemsPerPage,
      };

      agent.Planogram.getProductsUnused(this.params)
        .then((response: PaginatedList<ProductUnusedDto>) => {
          this.productUnused = response.items;
          this.productUnused.forEach(product =>
            product.selected = this.selectedItems.includes(product.productId) ? true : false
          );
          this.totalItems = response.totalItems;
        })
        .catch(error => {
          this.$emit('error', this.$t('dialogProductsUnusedErrorMensage'), error);
          this.dialog = false;
        }).finally(() => {
          this.loading = false;
        });
    },
    openConfirmDialog() {
      this.$emit('openCreatePlanogramUnusedProduct', this.dateFilter, this.selectedItems);
      this.dialog = false;
    },
    updateSelectedItems(item: ProductUnusedDto) {
      if (item.selected) {
        this.selectedItems.push(item.productId);
      } else {
        const index = this.selectedItems.indexOf(item.productId);
        if (index > -1) {
          this.selectedItems.splice(index, 1);
        }
      }
    },
    closeDialog() {
      this.dialog = false;
    },
    formatDateTimeToDate
  },
  components: {
    DateChipFilter,
    SingleDatePicker,
    UnusedProductsDialogHelp
  }
})
