<template>
  <v-container fluid>
    <v-row>
      
      <!-- Sidebar Navigation specific to this page -->
      <template v-if="$vuetify.breakpoint.width >= 960">
        <v-col cols="auto" class="mt-2">
          <v-list dense>
            <v-list-item class="sidebar-item">
                <v-icon class="mx-4 custom-icon">mdi-account-circle-outline</v-icon>
                <v-list-item-title class="title custom-title">{{ $t('registrationData') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-col>
      <v-divider vertical class="full-height-divider"></v-divider>
    </template>


      <!-- Main Content Area -->
      <v-col :cols="calculateCols()">
        <v-card flat>
          <!-- Main Title and Subtitle -->
          <v-card-title>
            <h2 class="text-h5">{{ $t('registrationData') }}</h2>
          </v-card-title>
          <v-card-subtitle class="mt-1">
            <span>{{ $t('registrationDataSubtitle') }}</span>
          </v-card-subtitle>
          <v-divider></v-divider>

          <!-- Success or error alert -->
          <v-container v-if="updateSuccessMessage || updateErrorMessage">
            <v-alert v-if="updateSuccessMessage" type="success" dismissible>
              {{ updateSuccessMessage }}
            </v-alert>
            <v-alert v-if="updateErrorMessage" type="error" dismissible>
              {{ updateErrorMessage }}
            </v-alert>
          </v-container>

          <v-container class="bottom-spacing">
            <v-row dense>
              <!-- Email Field Block -->
              <v-col cols="12">
                <v-row align="center">
                  <v-col cols="auto">
                    <v-icon>mdi-email-outline</v-icon>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="email"
                      label="Email"
                      ref="email"
                      :readonly="!editingEmail"
                      :class="{ 'non-editable': !editingEmail }"
                      :error-messages="emailError"
                    >
                      <template v-slot:append>
                        <v-btn
                          color="blue darken-1"
                          text
                          class="edit-btn"
                          @click="toggleEditAndUpdate('email')">
                          {{ editingEmail ? $t('saveButton') : $t('editButton') }}
                          <v-icon right>{{ editingEmail ? 'mdi-check' : 'mdi-circle-edit-outline' }}</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <!-- Phone Field Block -->
              <v-col cols="12">
                <v-row align="center">
                  <v-col cols="auto">
                    <v-icon>mdi-cellphone</v-icon>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="phone"
                      :label="$t('phone')"
                      :readonly="!editingPhone"
                      :class="{ 'non-editable': !editingPhone }"
                      :placeholder="$t('onlyNumbers')"
                      ref="phone"
                      :error-messages="phoneError"
                    >
                      <template v-slot:append>
                        <v-btn
                          color="blue darken-1"
                          text
                          class="edit-btn"
                          @click="toggleEditAndUpdate('phone')">
                          {{ editingPhone ? $t('saveButton') : $t('editButton') }}
                          <v-icon right>{{ editingPhone ? 'mdi-check' : 'mdi-circle-edit-outline' }}</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <!-- Date of Birth Field Block -->
              <v-col cols="12">
                <v-row align="center">
                  <v-col cols="auto">
                    <v-icon>mdi-calendar-account-outline</v-icon>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="dateOfBirth"
                      :label="$t('dateOfBirth')"
                      :readonly="!editingDateOfBirth"
                      :class="{ 'non-editable': !editingDateOfBirth }"
                      :placeholder="$t('dateFieldPlaceHolder')"
                      ref="dateOfBirth"
                      :error-messages="dateError"
                    >
                      <template v-slot:append>
                        <v-btn
                          color="blue darken-1"
                          text
                          class="edit-btn"
                          @click="toggleEditAndUpdate('dateOfBirth')">
                          {{ editingDateOfBirth ? $t('saveButton') : $t('editButton') }}
                          <v-icon right>{{ editingDateOfBirth ? 'mdi-check' : 'mdi-circle-edit-outline' }}</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <!-- Type of Address Field Block -->
              <v-col cols="12">
                <v-row align="center">
                  <v-col cols="auto">
                    <v-icon>mdi-home-city-outline</v-icon>
                  </v-col>
                  <v-col>
                    <v-combobox
                      v-model="addressTypes"
                      :items="addressOptions"
                      item-text="label"
                      item-value="value"
                      :label="$t('mainAddresses')"
                      :readonly="!editingAddress"
                      :class="{ 'non-editable': !editingAddress }"
                      multiple
                      chips
                      attach
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          v-bind="data.attrs"
                          class="my-chip"
                        >
                          {{ data.item.label }}
                        </v-chip>
                      </template>
                      <template v-slot:append>
                        <v-btn
                          color="blue darken-1"
                          text
                          class="edit-btn"
                          @click="toggleEditAndUpdate('addressTypes')"
                        >
                          {{ editingAddress ? $t('saveButton') : $t('editButton') }}
                          <v-icon right>{{ editingAddress ? 'mdi-check' : 'mdi-circle-edit-outline' }}</v-icon>
                        </v-btn>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>

          <v-dialog v-model="dialog" max-width="600" full>
            <v-card>
              <v-card-title class="py-5">{{ $t('updateInfoDialogText') }}</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red lighten-4" @click="cancelDialog">{{ $t('buttonCancel') }} <v-icon right>mdi-close</v-icon></v-btn>
                <v-btn color="green lighten-4" @click="confirmDialog">{{ $t('buttonConfirm') }} <v-icon right>mdi-content-save</v-icon></v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import agent from '@/api/agent';
import { formatDateTimeToDate } from '@/utility/TextFormatting';
import { formatDateStringToDate } from '@/utility/TextFormatting';
import { AddressTypes } from "@/types/MidasProductsOrders";

export default {
  data() {
    return {
      addressTypes: [],
      addressOptions: [],
      email: '',
      phone: '',
      dateOfBirth: '',
      dialog: false,
      emailError: '',   
      phoneError: '',   
      dateError: '',    
      updateSuccessMessage: '', 
      updateErrorMessage: '',    
      editingEmail: false,
      editingPhone: false,
      editingDateOfBirth: false,
      editingAddress: false,
    };
  },
  mounted() {
    this.fetchCustomerInfo();
    this.addressOptions = Object.keys(AddressTypes)
      .filter(key => isNaN(Number(key))) // Get only string keys (like "Street", "Company")
      .map(key => ({
        label: this.$t(`${key.toLowerCase()}`), // Translate key for display
        value: AddressTypes[key] // Enum key (e.g., "Street", "Company") for selection
    }));
  },
  methods: {
    calculateCols() {
      const width = this.$vuetify.breakpoint.width;
      switch (true) {
        case width >= 1660:
          return 10;
        case width >= 1140:
          return 9;
        case width >= 960:
          return 8;
        default:
          return 12;
      }
    },
    fetchCustomerInfo() {
      const tenantId = this.$auth.user().tenantId;
      const groupId = this.$auth.user().tenantGroupId;
      agent.CustomerInfo.get(tenantId, groupId)
        .then((customerInfo) => {
          this.email = customerInfo.email;
          this.phone = customerInfo.phone;
          this.dateOfBirth = customerInfo.dateOfBirth ? this.formatDateTimeToDate(customerInfo.dateOfBirth) : '';
          this.addressTypes = customerInfo.addressTypes
            ? customerInfo.addressTypes.map(enumValue => 
                this.addressOptions.find(option => option.value === enumValue))
            : [];
        })
        .catch((error) => {
          console.error('Error fetching customer info:', error);
        });
    },
    toggleEditAndUpdate(field) {
      this.toggleEdit(field);
      if (field === 'email' && !this.editingEmail) {
        this.handleUpdateClick();
      } else if (field === 'phone' && !this.editingPhone) {
        this.handleUpdateClick();
      } else if (field === 'dateOfBirth' && !this.editingDateOfBirth) {
        this.handleUpdateClick();
      } else if (field === 'addressTypes' && !this.editingAddress) {
        this.handleUpdateClick();
      }
    },
    toggleEdit(field) {
      if (field === 'email') {
        this.editingEmail = !this.editingEmail;
      }
      else if (field === 'phone') {
        this.editingPhone = !this.editingPhone;
      }
      else if (field === 'dateOfBirth') {
        this.editingDateOfBirth = !this.editingDateOfBirth;
      }
      else if (field === 'addressTypes') {
        this.editingAddress = !this.editingAddress;
      }
    },
    handleUpdateClick() {
      let isFormValid = true;

      const emailValid = this.$validations.emailPattern(this.email);
      if (emailValid !== true && this.email) {
        this.emailError = emailValid;
        isFormValid = false;
      }
      else {
        this.emailError = ''; 
      }

      const phoneValid = this.$validations.phonePattern(this.phone);
      if (phoneValid !== true && this.phone) {
        this.phoneError = phoneValid;
        isFormValid = false;
      }
      else {
        this.phoneError = '';
      }

      const dateValid = this.$validations.date(this.dateOfBirth);
      if (dateValid !== true && this.dateOfBirth) {
        this.dateError = dateValid;
        isFormValid = false;
      }
      else {
        this.dateError = '';
      }

      if (isFormValid) {
        this.openDialog();
      }
    },
    openDialog() {
      this.dialog = true; 
    },
    cancelDialog() {
      this.dialog = false;
    },
    confirmDialog() {
      this.updateSuccessMessage = '';
      this.updateErrorMessage = '';

      const tenantId = this.$auth.user().tenantId;
      const groupId = this.$auth.user().tenantGroupId;

      const addressEnumList = this.addressTypes.map(item => item.value);

      const customerInfoForProfileDto = {
        email: this.email,
        phone: this.phone,
        dateOfBirth: this.formatDateStringToDate(this.dateOfBirth),
        addressTypes: addressEnumList
      };

      this.dialog = false;

      agent.CustomerInfo.update(tenantId, groupId, customerInfoForProfileDto)
        .then(() => {
          this.updateSuccessMessage = this.$t('infoUpdatedMessage');
        })
        .catch((error) => {
          this.updateErrorMessage = error.message;
        });
    },
    formatDateTimeToDate,
    formatDateStringToDate,
  },
};
</script>

<style scoped>
.custom-icon {
  color: rgb(51, 123, 204);
}
.custom-title {
  color: rgb(51, 123, 204);
  margin-right: 20px;
}
.full-height-divider {
  height: 100vh;
}
.non-editable {
  pointer-events: none;
}
.edit-btn {
  pointer-events: auto;
}
.sidebar-item {
  margin-left: -30px;
}
.my-chip {
  background-color: #B4DBFF !important;
  color: #03009B;
  padding: 9px 25px;
  gap: 10px;
  height: 33px;
  border-radius: 30px;
  margin-top: 20px;
}
.bottom-spacing {
  padding-bottom: 100px !important;
  margin-bottom: 100px !important;
}
</style>
