
import Vue from 'vue';
import AcquisitionOrderCatalog from "@/views/acquisitionOrders/AcquisitionOrderCatalog.vue";
import AcquisitionOrderFinish from "@/views/acquisitionOrders/AcquisitionOrderFinish.vue";
import AcquisitionOrderSuccess from "@/views/acquisitionOrders/AcquisitionOrderSuccess.vue";

export default Vue.extend({
  components: {
    AcquisitionOrderCatalog,
    AcquisitionOrderFinish,
    AcquisitionOrderSuccess
  },
  data() {
    return {
      page: "catalog" as "catalog" | "finishOrder" | "npsFeedback",
      orderProducts: [] as any[]
    }
  },
  methods: {
    getNewOrder(orderProducts: any[]) {
      this.orderProducts = orderProducts
      this.page = "finishOrder";
    }
  }
})
