import i18n from "@/locales/i18n";
// noinspection SpellCheckingInspection

type ValidationFunction = (value: any, parameter?: any) => boolean | string;

export default class Validations {
  required: ValidationFunction = (v) => !!v || (i18n.t('requiredField') as string);
  nonEmpty: ValidationFunction = (v) => /\S/.test(v) || (i18n.t('insertValidValue') as string);
  float: ValidationFunction = (v) => !isNaN(v) || (i18n.t('insertValidValue') as string);
  integer: ValidationFunction = (v) => !v || /^\d+$/.test(v) || (i18n.t('numberInteger') as string);
  barcode: ValidationFunction = (v) => /^[A-Za-z0-9]*#*\*?$/.test(v) || "Insira um código de barras válido";
  commonCharacters: ValidationFunction = (v) => !v || /^[\w.\-+ ]*$/.test(v) || (i18n.t('commonCharacters') as string);
  commonCharactersExpanded: ValidationFunction = (v) => !v || /^(?!.*\s{2,})[A-Za-zÀ-ÖØ-öø-ÿ0-9\s()-]+$/.test(v) || (i18n.t('commonCharacters') as string);
  digitsAndDots: ValidationFunction = (v) => !v || /^[\d.]*$/.test(v) || "Insira caracteres válidos";
  positive: ValidationFunction = (v) => Number(v) > 0 || (i18n.t('validations.invalidPositiveValue') as string);
  currency: ValidationFunction = (v) => !v || /^(0(\.\d{0,2})?|[1-9]\d*(\.\d{0,2})?)$/.test(v) || "Insira um valor válido";
  date: ValidationFunction = (v) => /^\d\d\/\d\d\/\d\d\d\d*$/.test(v) || "Insira uma data válida";
  passwordLenght: ValidationFunction = (v) => !v || /^.{8,}$/.test(v) || "A senha deve ter no mínimo 8 caracteres";
  minLength: ValidationFunction = (v, min) => !v || v.length >= min || (i18n.t('minLength', {min: min}) as string);
  emailPattern: ValidationFunction = (v) => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(v) || "Email inválido";
  };
  phonePattern: ValidationFunction = (v) => {
    const pattern = /^[0-9]{10,13}$/;
    return pattern.test(v) || (i18n.t('phoneField') as string);
  };
  validateEIN: ValidationFunction = (ein) => {
    if(!ein) {
      return true;
    }

    ein = ein.replace(/[^\d]+/g, '');

    return ein.length === 9 || i18n.t('validations.invalidEIN').toString();
  };
  validateCNPJ: ValidationFunction = (cnpj) => {
    if(!cnpj) {
      return true;
    }
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj.length !== 14)
      return i18n.t('validations.invalidCNPJ').toString();

    // CNPJs conhecidos como inválidos
    if (/^(\d)\1+$/.test(cnpj))
      return i18n.t('validations.invalidCNPJ').toString();

    let size = cnpj.length - 2;
    let numbers = cnpj.substring(0, size);
    const digits = cnpj.substring(size);
    let sum = 0;
    let position = size - 7;

    for (let i = size; i >= 1; i--) {
      sum += +numbers.charAt(size - i) * position--;
      if (position < 2) position = 9;
    }

    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== +digits.charAt(0))
      return i18n.t('validations.invalidCNPJ').toString();

    size += 1;
    numbers = cnpj.substring(0, size);
    sum = 0;
    position = size - 7;
    for (let i = size; i >= 1; i--) {
      sum += +numbers.charAt(size - i) * position--;
      if (position < 2) position = 9;
    }

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    return result === +digits.charAt(1) || i18n.t('validations.invalidCNPJ').toString();
  };
  requiredForMarketIntel: ValidationFunction = (v) => !!v || "Este campo é obrigatório para a Inteligência de Mercado";
  noWhitespaceRule: ValidationFunction = (v) => !/\s/.test(v) || (i18n.t('noWhitespace') as string);
  isCnpjOrCpf: ValidationFunction = (text) => {
    return /\d{3}\.\d{3}\.\d{3}-\d{2}|\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}/.test(text);
  };
  phone: ValidationFunction = (v) => v.length === 15 || (i18n.t('invalidPhone') as string);
  positiveInteger: ValidationFunction = (v) => !v || (/^\d+$/.test(v) && v > 0) || i18n.t('isPositiveRules').toString();
  positiveFloat: ValidationFunction = (v) => (!isNaN(v) && v >= 0) || i18n.t('floatRules').toString();

  cep: ValidationFunction = (v) => {
    const pattern = /^\d{5}-?\d{3}$/;
    return pattern.test(v) || i18n.t('cepRules').toString();
  }
}
