export default {
  inventory : {
    title: 'Estoque',
    help: {
      title: 'Informações de Estoque',
      description: `<p>Mantenha um controle preciso dos itens disponíveis e das movimentações em seu estoque.
          Utilize esta página para monitorar o inventário em diferentes locais, realizar ajustes e analisar padrões de movimentação.
        </p>
        <p><strong>Inventário: </strong>Explore detalhes do estoque e movimentações com filtros personalizados.
          Você pode segmentar por Estoque de Loja ou Estoque Central. Faça ajustes no endereço do item,
          quantidade, limite crítico e quantidade mínima de compra. Além disso, você pode editar quantidades diretamente no sistema e baixar os dados em formato Excel.
        </p>
        <p><strong>Movimentações: </strong>Analise operações por toque específico ou em toda a sua operação com filtros detalhados.
          Compreenda padrões de movimentação e identifique eventuais erros logísticos para otimizar sua operação.
        </p>`
    },
    types: {
      title: 'Tipos de estoque',
      center: 'Central',
      pos: 'PDV',
    },
    items: {
      title: 'Inventário de itens',
      tableTitle: 'Itens no Estoque',
      tooltips : {
        onlyDistributionCenter: 'Selecione apenas um estoque central',
        notDeleteInventoryPDV: 'Não é possível excluir produto do estoque do ponto de venda'
      },
      messages: {
        error: {
          downloadFile: 'Não foi possível fazer o download do arquivo',
          consult: 'Não foi possível consultar o estoque',
        },
        success: {
          minimumQuantityUpdated: 'Quantidade mínima de compra atualizada com sucesso',
          addressUpdated: 'Endereço do estoque atualizado com sucesso',
          minimumCriticalUpdated: 'Quantidade mínima crítica atualizada com sucesso',
          quantityUpdated: 'Quantidade atualizada com sucesso',
        }
      },
      dialogs: {
        removeProduct: {
          title: 'Remover produtos do estoque central',
          all: 'Deseja remover todos os produtos do estoque central que nao estão em um planograma ativo?',
          specific: 'Deseja remover o produto {product} do estoque central?',
          attention: 'Atenção',
          messages: {
            success: {
              productsRemoved: 'Produtos foram removidos do estoque central',
              noProductsToRemove: 'Não há produtos para remover do estoque central',
            },
            error: {
              removeProducts: 'Não foi possível remover os produtos do estoque central',
            },
          }
        },
        itemAddress: {
          title: 'Endereço do Estoque',
          messages: {
            error: {
              addressUpdated: 'Não foi possível editar o endereço de estoque',
            },
          },
          help: {
            description: `<p align="justify">
            O <strong>Endereço de estoque</strong> é a localização do item no estoque central.
            </p>
            <p align="justify">
              Esse endereço é padronizado de acordo com o estoque, geralmente é uma especie de código que indica o local que o item foi armazenado no estoque central.
            </p>
            <p align="justify">
              Por exemplo, algumas garrafas de Coca-Cola são armazenadas na prateleira de código PRAT20.
              Então, O endereço de estoque desse item é PRAT20, dessa forma é possivel localiza-lo no estoque central.
            </p>`
          }
        },
        addProduct: {
          title: 'Adicionar produtos ao estoque central',
          radioButtons: {
            addAllProducts: 'Adicionar todos os produtos do planograma no estoque central',
            addSpecificProducts: 'Adicionar produtos específicos no estoque central',
          },
          messages: {
            success: {
              productsAdded: 'Produtos foram adicionados ao estoque central',
              noProductsToAdd: 'Não há produtos para adicionar ao estoque central',
            },
            error: {
              addProducts: 'Não foi possível adicionar os produtos ao estoque central',
            },
          }
        },
        minimuCritical: {
          title: 'Mínimo Crítico',
          help: {
            description: `<p align="justify">
                O <strong>Mínimo Crítico</strong> é um indicador essencial para o gerenciamento de estoques, sinalizando quando é necessário repor mercadorias e evitando
                esgotamentos. Manter atenção a esse valor é vital para otimizar a eficiência operacional e garantir a satisfação do cliente. Nossa funcionalidade é completamente
                editável, proporcionando flexibilidade para ajustá-la conforme as necessidades operacionais específicas. Após a edição, o novo valor é salvo, permitindo controle
                personalizado do estoque. Por exemplo, se você atualizar o valor de 5 para 10, o sistema considerará 10 como o novo "Mínimo Crítico". Isso mantém o estoque em níveis
                ideais e evita quedas de produtos essenciais na loja.
              </p>`
          },
          label: 'Editar mínimo crítico',
          messages: {
            error: {
              minimumCriticalUpdated: 'Erro ao salvar ',
            },
          }
        },
        purchaseQuantity: {
          title: 'Quantidade mínima',
          help: {
            description: `<p align="justify">
              A <strong>Quantidade Mínima de Compra</strong> é o pedido mínimo de compra que pode ser feito para se abastecer seu estoque central.
              </p>
              <p align="justify">
                Esse valor é definido com base da <strong>Unidade de Compra</strong> (como caixa, fardo, pacote, etc) e em quantos produtos individuais essa unidade contém.
              </p>
              <p align="justify">
                Por exemplo, um fornecedor de refrigerante só vende a partir de um pedido mínimo de 10 fardos contendo em cada fardo 6 unidades.
                Então, a quantidade mínima de compra nesse caso é 60 unidades. Para isso você preencheria <strong> "10" nesse formulário</strong>, para uma <strong>Quantidade Mínima de Compra de 60 unidades.</strong>
              </p>`
          },
          minQuantity: '<strong>Quantidade Mínima de compra:</strong> total de {minimumPurchaseQuantity} unidades',
          messages: {
            error: {
              minimumQuantityUpdated: 'Não foi possível editar a Quantidade Mínima de Compra',
            },
          }
        }
      }
    },
    inventoryTransaction: {
      title:'Movimentações Realizadas',
      tab: 'Movimentações',
      messages: {
        error:{
          generateFile: 'Erro ao gerar arquivo'
        }
      }
    },

    operation : {
      title: 'Abastecimento',
      canceled: 'Cancelado',
      active: 'Ativo',
      completed: 'Completo',
      help: {
        description: `<p>Nessa tela são exibidos os abastecimentos e controles de inventários realizados no TouchPay.</p>
        <p>A opção de abastecimento por ser acessada de três formas:</p>
        <ul>
          <li><strong>Abastecimento pelo terminal</strong>: A opção de abastecimento pelo terminal é acessada através do menu de administrador do terminal de pagamento. </li>
          <li><strong>Abastecimento pelo aplicativo TouchPay Abastecedor</strong>: O abastecimento pelo aplicativo pode ser realizando quando uma pick list estiver pendente/separada. Nessa opção o usuário pode editar as quantidades e datas de validade, além de realizar a retirada dos itens.</li>
          <li><strong>Abastecimento pela telemetria</strong>: O abastecimento também pode ser realizado pela página de telemetria do sistema web, porém nesse modo não há como editar as quantidades abastecidas, realizar o controle de inventário ou informar a data de validade dos produtos. É possível realizar o abastecimento de uma pick list criada ou o abastecimento completo. No abastecimento completo, serão abastecidos itens o suficiente para completar a quantidade desejada daquele planograma, sempre será a diferença entre a quantidade atual e o valor desejado.</li>
        </ul>
        <p>O abastecimento atualiza o estoque do seu ponto de venda com os produtos abastecidos. É necessário uma Pick List para cada abastecimento que não seja completo.</p>
        <p>A opção de inventário por ser acessada de três formas:</p>
        <ul>
          <li><strong>Inventário pelo terminal</strong>: A opção de inventário pelo terminal é acessada através do menu de administrador do terminal de pagamento.</li>
          <li><strong>Inventário pelo aplicativo TouchPay Abastecedor</strong>: O abastecimento pelo aplicativo pode ser realizando quando um PDV possuir um Planograma. Nessa opção o usuário pode editar as quantidades e datas de validade, além de realizar a retirada dos itens.</li>
          <li><strong>Inventário pelo Estoque</strong>: O inventário  também pode ser realizado pela página de estoques do sistema web, porém nesse modo não há como editar a data de validade dos produtos e não temos a referência da quantidade desejada. Essa operação só pode ser realizada por usuários com a permissão gerente ou administrador.</li>
        </ul>`,
      },
      messages: {
        error: {
          report: 'Não foi possível gerar o relatório',
          undo: 'Não foi possível desfazer a operação',
        },
        success: {
          undo: 'Operação desfeita com sucesso',
        }
      },
      details: {
        pos: 'PDV',
        inventory: 'Estoque',
        started: 'Iniciado em',
        finished: 'Finalizado em',
        elapsedtime: 'Tempo decorrido',
        type: 'Tipo',
        users: 'Usuários',
        pickList: 'Pick List',
        completedSupply: 'Abastecimento Completo',
      },
      types: {
        inventory: 'Inventário',
        blindInventory: 'Inventário às Cegas',
        combined: 'Combinado',
        separeted: 'Separação',
        correction: "Correção",
        sale: "Venda",
        purchase: "Compra",
        reversed: "Reversão Operação",
        automaticCorrection: "Correção automática",
        supply: "Abastecimento",
        expired: "Validade vencida",
        damaged: "Produto danificado",
        return: "Devolução",
        removedInventory: "Remoção do Inventário",
        other: "Outros",
        expirationDateChanged: "Data de validade alterada",
        expirationDateAdded: "Data de validade adicionada",
        expirationDateRemoved: "Data de validade removida",
      },
    },
    report:{
      dynamic:{
        title:'Resumo Dinâmico de Estoque',
        help:{
          description:`<p><strong>Nesta página, você pode gerar relatórios personalizados de estoque de acordo com suas necessidades operacionais. Utilize as seguintes funcionalidades para configurar seus relatórios: </strong></p>
           <p><strong>Filtros e Ações:</strong> Selecione o período desejado utilizando as datas inicial e final. Também é possível escolher intervalos fixos, como 30 dias, 15 dias, 7 dias ou mesmo dia, para uma análise rápida de períodos específicos.</p>
           <p><strong>Estoque:</strong> Use este filtro para focar em estoques específicos que deseja analisar.</p>
           <p><strong>Produtos:</strong> Escolha produtos ou categorias específicas para detalhar o relatório conforme a necessidade de monitoramento de itens.</p>
           <p><strong>Agrupamento de Dados:</strong> Defina como prefere organizar os dados no relatório. As opções de agrupamento incluem Produtos, Categoria, Estoque, Operação, Dia, Mês, ou Dia da Semana, permitindo uma análise personalizada do inventário.</p>
            <p><strong>Salvar Filtros:</strong> Salve suas preferências de filtros para reutilizá-las em relatórios futuros, facilitando o processo de consulta.</p>
            <p><strong>Exportação de Relatórios:</strong> Após ajustar os filtros de acordo com suas necessidades, você pode exportar o relatório em formato Excel clicando no botão "Excel".</p>
            <p>Lembre-se de clicar em "Atualizar" após ajustar os filtros para visualizar as informações mais recentes sobre o seu estoque.</p>`
        },
      },
      operational:{
        title:'Relatórios Operacionais',
        help:{
          description: `<p>Aqui são exibidos os relatórios de <strong>produtos a vencer</strong>, <strong>perdas por PDV</strong> e <strong>perdas por produto</strong>. Para que haja informações para gerar esses relatórios, é necessário realizar o fluxo de abastecimento e controle de inventário através do terminal de pagamento. Esse fluxo está presente apenas nos terminais de micro-market.</p>`
        },
        productsToExpire: 'Produtos a vencer',
        posLosses: 'Perdas por PDV',
        lossesByProduct: 'Perdas por produto'
      }
    }
  }
}
