
import Vue from 'vue';
import PosManagement from './PosManagement.vue';
import BoxManagement from './BoxManagement.vue';
import { MANAGER_HIERARCHY} from '@/constants/Roles';

export default Vue.extend({
  data() {
    return {
      activeTab: 0
    }
  },
  components: {
    PosManagement,
    BoxManagement
  },
  computed: {
    userIsManagerHierarchy() {
      return MANAGER_HIERARCHY.some(role => (this as any).$auth.check(role));
    }
  },
});
