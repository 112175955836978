export default {
  filters: {
    filtersActions: 'Filters and Actions',
    title: 'Filters',
    groupings: 'Groupings',
    labels: {
      all: 'All',
      period: 'Period',
      pos: 'POS',
      onlySubtractedItems: 'Only subtracted items',
      advanced: 'Advanced',
      calendar: 'Calendar',
      inventoryType: 'Inventory Type',
      central: 'Central',
      search: 'Search',
      searchProducts: 'Search a product',
      months:'Months' ,
      month: 'Month',
      day: 'Day',
      dayOfWeek: 'Day of the Week',
      customer: 'Customer',
      customers: 'Customers',
      location: 'Location',
      locationSpecific: 'Location Specific',
      pointOfSale: 'Point of Sale',
      pointsOfSale: 'Points of Sale',
      result: 'Result',
      minimumPrice: 'Minimum Price',
      maximumPrice: 'Maximum Price',
      product: 'Product',
      category: 'Category',
      lastCardDigits: 'Last Card Digits',
      date: 'Date',
      initialDate: 'Initial Date',
      finalDate: 'Final Date',
      initialHour: 'Initial Hour',
      finalHour: 'Final Hour',
      verificationStatus: 'Verification Status',
      viewOnlyTransactionItemsSubtracted: 'View only transaction with subtracted items',
      viewProfit: 'View Profit',
      accessDetails: 'Access Details',
      audit: 'Audit',
      actions: 'Actions',
      accessResult: 'Access Result',
      operationType: 'Operation Type',
      operation: 'Operation',
      lastMovement: 'Last movement',
      rangeOfDays: 'Range of days: ',
      saveFilters: 'Save Filters',
      savedFilters: 'Saved Filters',
      states: 'States',
      cardBrands: 'Card Brands',
      paymentMethods: 'Payment Methods',
      reportName: 'Report name',
      local: 'local',
    },
    messages: {
      selectMonth: 'Select a month',
      selectCustomer: 'Select a customer',
      selectLocation: 'Select a location',
      selectResult: 'Select result to view',
      selectOperationType: 'Select an operation type',
    },
    timelineDateAlert: 'Maximum interval of three days',
    orderSearch: 'Sort / Search',
    status: 'Status',
    customerName: 'Customer Name',
    inventory: 'Inventory',
    types: 'Types',
    lastSale: 'Last sale',
    creationDate: 'Creation date (Point of sale ID)',
    order: 'Sort',
    groupBy: 'Group by',
    sort: {
      asc: 'Ascending',
      desc: 'Descending',
    },
    saveReport:{
      title:'Save report filters',
      savedSuccessfully: 'Saved successfully',
      deleteConfirmation: 'Delete report filters',
      reportName: 'Report name',
      error: {
        onlyUser: 'Only user filters can be deleted',
        errorWhenSaving: 'An error occurred while saving the filters',
        unableToRemove: 'Unable to remove item',
      },
    },
    selectALocation: 'Select a location first',
    selectACustomer: 'Select a customer first',
  },
}
