export default {
  approvalTab: 'ORDER FOR APPROVAL',
  search: 'Search',
  idAcquisitionOrder: 'Request Code',
  fantasyNameAcquisitionOrder: 'Trade Name',
  documentIdAcquisitionOrder: 'CNPJ',
  emailAcquisitionOrder: 'Email',
  phoneNumberAcquisitionOrder: 'Phone Number',
  dateCreatedAcquisitionOrder: 'Creation Date',
  taxArrangementAcquisitionOrder: 'Tax Arrangement',
  statusAcquisitionOrder: 'Status',
  itensDetailsAcquisitionOrder: 'Item Details',
  commentsAcquisitionOrder: 'Comments',
  attention: 'ATTENTION',
  image: 'Image',
  name: 'Name',
  quantity: 'Quantity',
  description: 'Description',
  itemType: 'Item Type',
  Leased: 'Leased',
  Owned: 'Owned',
  PessoaFisica: 'Individual',
  SimplesNacional: 'National Simple',
  Mei: 'Micro Entrepreneur',
  Other: 'Other',
  NotSpecified: 'Not Specified',
  ErrorOnCheck: 'Verification Error',
  areYouSureReject: 'Are you sure you want to reject this order? This action is irreversible and will require the applicant to restart the entire process.',
  areYouSureApprove: 'Are you sure you want to approve this order? This action is irreversible.',
  areYouSurePending: 'Add a Comment?',
  reasonLabel: 'Leave a comment explaining the reason',
  reasonText: 'Type the reason here',
  Approved: 'Approved',
  Cancelled: 'Rejected',
  requiredRules: 'Mandatory field for Rejection',
  errorLoadingData: 'Error loading data',
  errorUpdatingAcquisitionOrder: 'Error updating request',
  sucessUpdatingAcquisitionOrder: 'Request updated successfully',
};
