import { render, staticRenderFns } from "./ProductsManual.vue?vue&type=template&id=ade953ac&scoped=true"
import script from "./ProductsManual.vue?vue&type=script&lang=ts"
export * from "./ProductsManual.vue?vue&type=script&lang=ts"
import style0 from "./ProductsManual.vue?vue&type=style&index=0&id=ade953ac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ade953ac",
  null
  
)

export default component.exports