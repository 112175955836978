export default {
  translateOperationType: function (type, isBlindOperation) {
    if (type === 'Inventory' && isBlindOperation) return this.$t('inventory.operation.types.blindInventory');
    if (type === 'Inventory') return this.$t('inventory.operation.types.inventory');
    if (type === 'Supply') return this.$t('inventory.operation.types.supply');
    if (type === 'Combined') return this.$t('inventory.operation.types.combined');
    if (type === 'Picking') return this.$t('inventory.operation.types.separeted');	
  },
  translateRemovalReason: function(reason) {
    if (reason === 'Expired') return this.$t('inventory.operation.types.expired');
    if (reason === 'Damaged') return this.$t('inventory.operation.types.damaged');
    if (reason === 'Return') return this.$t('inventory.operation.types.return');
    return this.$t('inventory.operation.types.other');
  }
}