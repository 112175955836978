
import Vue from 'vue';
import HelpVideo from '@/components/HelpVideo.vue'

export default Vue.extend({
  components: {
    HelpVideo
  }
});

