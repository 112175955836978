export default {
    company: 'Empresa',
    condominium: 'Condomínio',
    dateFieldPlaceHolder: 'dd/mm/aaaa',
    dateOfBirth: 'Data de Nascimento',
    editButton: 'Editar',
    infoUpdatedMessage: 'Informações atualizadas com sucesso',
    mainAddresses: 'Selecione seus principais locais de operação',
    myInfo: 'Meus Dados',
    onlyNumbers: 'Somente números',
    other: 'Outro',
    phone: 'Telefone',
    registrationData: 'Dados Cadastrais',
    registrationDataSubtitle: 'Mantenha sempre seus dados atualizados para garantir a autenticidade e facilitar a comunicação com sua conta!',
    street: 'Rua',
    updateInfoDialogText: 'Tem certeza de que deseja atualizar suas informações?',
}