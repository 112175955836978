
import Vue from 'vue';
import RupturesMasterHelp from './helpers/RupturesMasterHelp.vue';
import RupturesProducts from './RupturesProducts.vue';
import RupturesPointOfSales from './RupturesPointOfSales.vue';
import RupturesOccurrences from './RupturesOccurrences.vue';
import News from '@/components/News.vue';

export default Vue.extend( {
  data() {
    return {
      active: 0,
      filters: {}
    };
  },
  components: {
    RupturesOccurrences,
    RupturesMasterHelp,
    RupturesProducts,
    RupturesPointOfSales,
    News
  },
  methods: {
    goToOcurrences(filters: any) {
      this.active = 2;
      // this filters is being sent to childs via prop, as:
      // RupturesMaster -> RupturesOcurrences -> RupturesOcurrencesFilters
      // PARENT         -> CHILD/ CHILD                           -> CHILD/ CHILD
      // To be used when we click at some item 'actions' column, in order to see it in the OcurrencesReport.
      this.filters = filters;
    },
  },
  computed: {
    isBrazil() {
      return (this as any).$auth.user().tenantCountry == 'BR';
    },
  },
});
