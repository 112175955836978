export default{
  ruptureReports:{
    help:{
      description:`
      <p>
        Aquí se muestran los informes de rupturas, por producto, puntos de venta y ocurrencias relacionadas con cada artículo de inventario. Independientemente de las características individuales de cada informe, se muestra una <strong>estimación</strong> de posibles ventas perdidas, en <strong>unidades</strong> y <strong>valor</strong>.
      </p>
      <p>
        El intervalo de <strong>fechas</strong> presente en el filtro determinará en qué intervalo de fechas se encuentra el <strong>inicio</strong> de la ruptura.
      </p>
      <p>
        Para cada <strong>ruptura</strong> (ocurrencia), que posteriormente se trata en diferentes informes, se aplica la siguiente fórmula:
      </p>
      <ul align="justify">
        <li>
          El momento en que <strong>comienza</strong> la ruptura, se determina por un movimiento de stock de venta, en el que el resultado final del inventario del artículo es cero.
        </li>
        <li>
          El momento en que la ruptura <strong>finaliza</strong>, se determina por un movimiento de stock que resulte en una cantidad mayor que cero (puede ser inventario o reabastecimiento). Cabe destacar que si el artículo no ha sido reabastecido, ese final es el presente exacto.
        </li>
        <li>
          Con el inicio y fin de la ruptura, se calcula el tiempo de <strong>indisponibilidad</strong> del artículo.
        </li>
        <li>
          La cantidad de posibles ventas <strong>perdidas</strong> se calcula con base en el promedio de los últimos 7 días anteriores al inicio de cada ruptura. Este valor se multiplica por el tiempo de <strong>indisponibilidad</strong>. Cabe destacar que, si se produce un promedio que no complete un valor entero, se considerará <strong>Ventas Perdidas</strong> como cero.
        </li>
        <li>
          El <strong>valor</strong> de posibles ventas perdidas es la multiplicación de las unidades que no se vendieron por el valor aplicado en el último planograma en el que se encuentra el artículo.
        </li>
      </ul>
      <p></p>`
    },
    title: "Informes de Rupturas",
    products:{
      title: "Productos",
      help:{
        description:`
        <p>
          Aquí se muestran las ocurrencias <strong>agrupadas por producto</strong>. Para cada uno de ellos, se muestra una <strong>estimación</strong> de posibles ventas perdidas.
        </p>
        <p>La <strong>búsqueda</strong> filtra por: Id, Código y Descripción.</p>
        <p>
          <strong>Tabla:</strong>
        </p>
        <ul align="justify">
          <li><strong>Id</strong>: indica el id del producto.</li>
          <li><strong>Código</strong>: indica el código registrado junto al producto.</li>
          <li><strong>Descripción</strong>: es la descripción registrada junto al producto.</li>
          <li><strong>Ocurrencias</strong>: representa el total de veces que el producto ha tenido alguna ruptura en algún stock.</li>
          <li><strong>Total Tiempo (Días)</strong>: representa el total de tiempo de indisponibilidad del producto. Este valor es la suma de todos los intervalos de las rupturas de dicho producto.</li>
          <li><strong>Total Pérdidas (Un.)</strong>: es la suma de la estimación de posibles ventas perdidas en unidades en cada ocurrencia.</li>
          <li><strong>Total Pérdidas ({sign})</strong>: es la suma de la estimación de posibles ventas perdidas en valor en cada ocurrencia.</li>
        </ul>`
      }
    },
    pointOfSales:{
      title: "PDVs",
      help:{
        description:`
        <p>
          Aquí se muestran las ocurrencias <strong>agrupadas por PDVs</strong>. Para cada uno de ellos, se muestra una <strong>estimación</strong> de posibles ventas perdidas.
        </p>
        <p>La <strong>búsqueda</strong> filtra por: Información PDV.</p>
        <p>
          <strong>Tabla:</strong>
        </p>
        <ul align="justify">
          <li><strong>Información PDV</strong>: representa la información del PDV, de la siguiente manera: Id del PDV - Cliente - Localización.</li>
          <li><strong>Ocurrencias</strong>: representa el total de veces que el producto ha tenido alguna ruptura en algún stock.</li>
          <li><strong>Total Tiempo (Días)</strong>: representa el total de tiempo de indisponibilidad del producto. Este valor es la suma de todos los intervalos de las rupturas de dicho producto.</li>
          <li><strong>Total Pérdidas (Un.)</strong>: es la suma de la estimación de posibles ventas perdidas en unidades en cada ocurrencia.</li>
          <li><strong>Total Pérdidas ({sign})</strong>: es la suma de la estimación de posibles ventas perdidas en valor en cada ocurrencia.</li>
        </ul>`
      }
    },
    occurrences:{
      title: "Ocurrencias",
      help:{
        description:`
        <p>
          Aquí se muestran las ocurrencias por artículo de inventario de stock. Para cada uno de ellos, se muestra una <strong>estimación</strong> de posibles ventas perdidas.
        </p>
        <p>La <strong>búsqueda</strong> filtra por: Id. Artículo, Código del Producto, Descripción y Stock.</p>
        <p>Los botones de filtro de rupturas <strong>Cerradas</strong> y <strong>Abiertas</strong> se añadieron para facilitar una búsqueda rápida de rupturas abiertas y cerradas. Para el mismo efecto en el informe de Excel, se sugiere utilizar el filtro de Fecha-Fin.</p>
        <p><strong>Tabla:</strong></p>
        <ul align="justify">
          <li><strong>Id. Artículo</strong>: indica el artículo de stock.</li>
          <li><strong>Cód. Producto</strong>: indica el código registrado junto al producto.</li>
          <li><strong>Descripción</strong>: es la descripción registrada junto al producto.</li>
          <li><strong>Stock</strong>: es el nombre del stock del PDV.</li>
          <li><strong>Fecha-Inicio</strong>: indica el inicio de la ruptura (cuando el stock del artículo tuvo un resultado de 0).</li>
          <li><strong>Fecha-Fin</strong>: indica el fin de la ruptura; si aún está abierta, estará vacío, y para los cálculos se usa el momento actual.</li>
          <li><strong>Tiempo (Días)</strong>: representa la diferencia en días (de forma decimal) entre la fecha de inicio y fin de la ruptura.</li>
          <li><strong>Pérdidas (Un.)</strong>: es la estimación de posibles ventas perdidas en unidades.</li>
          <li><strong>Pérdidas ({sign})</strong>: es la estimación de posibles ventas perdidas en valor.</li>
        </ul>`
      },
      status:{
        closed: "Cerradas",
        open: "Abiertas"
      },
    }
  },
}
