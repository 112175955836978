<template>
  <portal to="toolbarTitle">
    <help>
      <template #title>{{$t('ruptureReports.title')}}</template>
      <span v-html="$t('ruptureReports.help.description')"></span>
      <help-video  help-code="Ruptures"/>
    </help>
  </portal>
</template>

<script>
import HelpVideo from '@/components/HelpVideo.vue'

export default {
components: {
    'help-video': HelpVideo
  },
}
</script>

