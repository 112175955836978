export default {
  buttons: {
    search: "Buscar",
    cancel: "Cancelar",
    save: "Guardar",
    close: "Cerrar",
    apply: "Aplicar",
    create: "Crear",
    import: "Importar",
    add: "Añadir",
    excel: "Excel",
    yes: "Sí",
    no: "No",
    finish: "Finalizar",
    remove: "Eliminar",
    activate: "Activar",
    clear: 'Limpiar',
    exit: 'Salir',
    update: 'Actualizar',
    confirm: 'Confirmar',
    undo: 'Deshacer',
    ok: 'Ok',
    next: 'Siguiente',
    back: 'Volver',
    products: {
      remove: 'Eliminar Productos',
      add: 'Agregar Productos',
    },
    invalidMessages : {
      fillAllFields: 'Complete todos los campos',
    },
    pos: {
      new: 'nuevo PDV',
    },
    ruptureReports: "Ver ocurrencias de este artículo"
  }
}
