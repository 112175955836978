<template>
  <v-row class="mt-2 mb-1">
    <v-app-bar-nav-icon @click.stop="drawerControl = !drawerControl" class="d-md-none"></v-app-bar-nav-icon>
    <v-toolbar-title class="pl-3 py-2 px-2" v-text="$route.name"></v-toolbar-title>
    <portal-target name="toolbarTitle"></portal-target>
    <v-tooltip right v-if="pageIsListedInMenus">
      <template #activator="{ on, attrs }">
        <v-btn
          v-on="on"
          v-bind="attrs"
          icon
          class="pa-0"
          color="amber"
          @click="handleFavorite($route.path)"
        >
          <v-icon>
            {{ isPageFavorited($route.path) }}
          </v-icon>
        </v-btn>
      </template>
      <span>{{ getTooltipText($route.path) }}</span>
    </v-tooltip>
    <tenant-feedback></tenant-feedback>
    <v-spacer></v-spacer>
    <portal-target style="max-width: 500px; width: fit-content; text-align: right;" class="toolbarItemsPortal" name="toolbar"></portal-target>
    <v-menu offset-y max-height="100%" content-class="menu-z-index">
      <template #activator="{ on }">
        <span v-on="on">
          <v-btn icon depressed>
            <v-icon>mdi-account-circle-outline</v-icon>
            <span v-if="($auth.user().tenantId == '268') && notificationStorage.hasUpdate" class="notification-dot-icon"></span>
          </v-btn>
        </span>
        <template v-if="userIsAllowedToFranchise">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span>
                <v-btn icon v-on="on" depressed @click="subGroupPage">
                  <v-icon style="box-shadow: none;">mdi-map-marker-multiple-outline</v-icon>
                </v-btn>
              </span>
            </template>
            <span>{{translations.environment.data.title}}</span>
          </v-tooltip>
        </template>
        <div class="pt-3 px-3" v-if="$auth.check('Franquia')">
          <v-btn color="white" :style="`color: ${color}; border-radius: 30px; width: 100px; height: 25px; text-transform: capitalize;`"
            @click="returnToMainEnvirontment">
              <v-icon size="medium">mdi-arrow-left</v-icon>
                {{ $t('returnToPrevious') }}
          </v-btn>
        </div>
        <div class="vertical-bar"></div>
          <v-col style="flex-grow: 0;">
            <div>
              <div :class="$vuetify.breakpoint.lg ? 'cli' : 'cli-max'">
                  <strong class="pr-1">{{ $t('customer') }}:</strong>
                    <span style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                      {{ $auth.user().tenantName }}
                    </span>
              </div>
              <div :class="$vuetify.breakpoint.lg ? 'cli' : 'cli-max'" style="overflow: visible; white-space: nowrap">
                  <strong class="pr-1">{{ $t('environmentId') }}:</strong>
                  <span>
                    {{ $auth.user().tenantId + ($auth.user().tenantGroupId ? `.${$auth.user().tenantGroupId}` : '') }}
                  </span>
              </div>
            </div>
          </v-col>
      </template>
      <v-list class="pb-0" dense>
        <v-list-item selectable inactive @click.stop="{}" class="left">
          {{ $t('email') }}: {{ $auth.user().userEmail }}
        </v-list-item>
        <v-list-item selectable inactive @click.stop="{}" class="left">
          {{ $t('permissions') }}: {{ translateRoles }}
        </v-list-item>
      </v-list>
      <v-list class="pa-0" dense>
        <v-list-item class="pa-0">
          <v-divider class="pa-0"/>
        </v-list-item>
        <v-list-item selectable inactive @click.stop="{}">
          <h2 class="left pa-0 ma-0">{{$t('myAccount')}}</h2>
        </v-list-item>
        <v-list-item @click="redirectToMyProfile" class="left" v-if="userIsManager">
          {{$t('myProfile')}}
        </v-list-item>
        <v-list-item @click="redirectToMyBills" class="left" v-if="userIsManager && this.isBrazil">
          {{$t('myBills')}}
        </v-list-item>
        <v-list-item @click="redirectToNewOrders" class="left" v-if="(franchiseesIdsForAcquisitionOrders.includes($auth.user().tenantId) && $auth.user().tenantGroupId != null) && this.$auth.check(ORDERS_MANAGER_HIERARCHY)">
          {{$t('newOrder')}}
        </v-list-item>
        <v-list-item @click="redirectToViewAcquisitionsOrders" class="left" v-if="($auth.user().tenantId === 268 && $auth.user().tenantGroupId === null) && this.$auth.check(ORDERS_MANAGER_HIERARCHY)">
          {{$t('viewAcquisitionsOrders')}}
        </v-list-item>
      </v-list>

      <!-- Change of Ownership -->
      <v-list class="pt-0" dense v-if="franchiseesIdsForOwnershipChange.includes($auth.user().tenantId) && this.$auth.check(MANAGER_HIERARCHY)">
        <v-list-item class="pa-0">
         <v-divider class="pa-0"/>
        </v-list-item>
        <v-list-item inactive @click.stop="{}">
         <h2 class="left">{{$t('changeOwnership')}}</h2>
        </v-list-item>
        <v-list-item v-if="($auth.user().tenantGroupId != null) && this.canHaveChangeOwnershipBoolean" @click="requestChangeOwnership" class="left">
         {{$t('requestChangeOwnership')}}
        </v-list-item>
        <v-list-item @click="redirectToChangeOfOwnership" class="left">
         <span v-if="notificationStorage.hasUpdate" class="notification-dot"></span>
         {{$t('viewTransferRequests')}}
        </v-list-item>
      </v-list>


      <v-list v-if="this.$auth.check(SUPPORT_ROLE)" class="pt-0" dense @click.stop="{}">
        <v-list-item class="pa-0" @click.stop="{}">
          <v-divider class="pa-0" @click.stop="{}"/>
        </v-list-item>
        <v-list-item inactive @click.stop="{}">
          <h2 class="left" @click.stop="{}">{{$t('support').toUpperCase()}}</h2>
        </v-list-item>
        <v-row class="ma-0" @click.stop="{}">
          <v-col cols="12" class="pa-4">
            <tenant-changer></tenant-changer>
          </v-col>
        </v-row>
      </v-list>
    </v-menu>
    <span>
      <v-btn icon v-on:click="handleLogout()"><v-icon>mdi-logout</v-icon></v-btn>
    </span>
  </v-row>
</template>

<script>
import TenantFeedbackDialog from '@/components/TenantFeedbackDialog.vue';
import { ADMINISTRATOR_ROLE,ORDERS_MANAGER_HIERARCHY,MANAGER_HIERARCHY,MANAGER_ROLE,SUPPORT_ROLE } from "@/constants/Roles";
import { useDataStore } from '@/stores/notifications';
import TenantChanger from "@/components/TenantChanger.vue";
import {ChangeOwnershipRequest} from "@/api/agent";

export default {
  components: {
    'tenant-feedback': TenantFeedbackDialog,
    'tenant-changer': TenantChanger
  },
  props: {
    drawer: { type: Boolean , default: null },
    listedPages: { type: Array, default: () => {} },
    color: { type: String, required: true }
  },
  data() {
    return {
      franchiseesIdsForOwnershipChange: [268,1],
      franchiseesIdsForAcquisitionOrders: [268, 2940, 3215, 7004, 1042],
      canHaveChangeOwnershipBoolean: false,
      favoritePagesRoutes: [],
      ADMINISTRATOR_ROLE,
      ORDERS_MANAGER_HIERARCHY,
      MANAGER_HIERARCHY,
      MANAGER_ROLE,
      SUPPORT_ROLE,
      notificationStorage: useDataStore(),
      translations: {
        environment: {
          data: {
            title: this.$t('routes.nameViewFranchiseeWeb').toString(),
          }
        }
      }
    }
  },
  mounted() {
    this.getFavoritePages();
    this.removeLocalStorage();
    this.canHaveChangeOwnership();
  },
  computed: {
    drawerControl: {
      get() {
        return this.drawer;
      },
      set(value) {
        this.$emit("change", value);
      }
    },
    pageIsListedInMenus() {
      return this.listedPages.some(p => p.route == this.$route.path && p.permission == true);
    },
    translateRoles() {
      const userRoles = this.$auth.user().claims;
      const translatedRoles = userRoles.map(claim => this.$t(`${claim}`));
      return translatedRoles.join(", ");
    },
    userIsManager() {
      return MANAGER_HIERARCHY.some(role => this.$auth.check(role));
    },
    userIsAllowedToFranchise() {
      return !this.$auth.check('Franquia') && this.$auth.check(ADMINISTRATOR_ROLE) &&
        (this.$auth.user().tenantGroupId == null || this.$auth.user().tenantGroupId == 0);
    },
    isBrazil() {
      return this.$auth.user().tenantCountry == 'BR';
    },
  },
  methods: {
    isPageFavorited(pageRoute) {
      return this.favoritePagesRoutes.includes(pageRoute) ? 'mdi-star' : 'mdi-star-outline';
    },
    getTooltipText(pageRoute) {
      return this.favoritePagesRoutes.includes(pageRoute) ? this.$t('removeFromFavorites') : this.$t('favoritePage');
    },
    getFavoritePages() {
      this.axios.get("api/FavoritePages")
        .then((response) => {
          this.favoritePagesRoutes = response.data
          this.$emit("favoritePagesUpdate", response.data)
        })
        .catch((error) => alert(error))
    },
    handleLogout() {
      this.$auth.logout();
      document.getElementById('chat-g4').hidden = true;
    },
    handleFavorite(pageRoute) {
      let request = {
        pageRoute: pageRoute,
        requestType: this.favoritePagesRoutes.includes(pageRoute) ? "Remove" : "Add"
      }

      this.axios.put("api/FavoritePages", request)
        .then(() => this.getFavoritePages())
        .catch((error) => alert(error))
    },
    subGroupPage() {
      this.$router.push({ name: this.$t('routes.nameViewFranchiseeWeb').toString(),});
    },
    returnToMainEnvirontment(){
      let oldToken = localStorage.getItem('oldToken')
      this.removeLocalStorage()

      this.$auth.token(null, oldToken, false)

      setTimeout(() => {
          this.$router.push({ name: this.$t('routes.nameDashboard').toString() });
          window.location.reload()
      }, 300);

    },
    removeLocalStorage(){
      if(!this.$auth.check('Franquia'))
        localStorage.removeItem('oldToken')
    },
    redirectToNewOrders() {
      this.$router.push({name: this.$t('routes.nameMidasOrders').toString(),});
    },
    redirectToViewAcquisitionsOrders() {
      this.$router.push({name: this.$t('routes.nameViewAcquisitionsOrders').toString(),});
    },
    redirectToChangeOfOwnership() {
      this.$router.push({name: this.$t('routes.nameChangeOfOwnership').toString(),});
    },
    requestChangeOwnership() {
      this.$router.push({name: this.$t('routes.nameChangeOwnershipNewRequest').toString(),});
    },
    redirectToMyBills() {
      this.$router.push({name: this.$t('routes.nameMyBills').toString(),});
    },
    redirectToMyProfile() {
      this.$router.push({name: this.$t('routes.nameCustomerProfile').toString(),});
    },
    async canHaveChangeOwnership(){
      await ChangeOwnershipRequest.canHaveChangeOwnership()
        .then((response) => {
          this.canHaveChangeOwnershipBoolean = response;
        })
        .catch(this.canHaveChangeOwnershipBoolean = false)
    }
  }
}
</script>

<style scoped>
.cli {
  font-size: 12px;
  max-width: 111px;
  height: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;
}
.cli-max {
  display: flex;
  max-width: 111px;
  align-items: center;
  font-size: 12px;
  height: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 0 0 auto;
}
.vertical-bar {
  border-right: 2px solid #f0ebebde;
  margin: 10px 15px;
  margin-top: 13px;
  align-items: center;
  height: 25px;
}
.notification-dot {
  width: 7px;
  height: 7px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(-50%, -50%);
}
.notification-dot-icon {
  width: 7px;
  height: 7px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: 9%;
  right: 4px;
  transform: translate(-50%, -50%);
}
.menu-z-index {
  z-index: 1100 !important;
}

.v-list-item {
  font-size: 12px;
}

</style>
