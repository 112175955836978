<template>
  <v-card class="mb-16">

    <!-- Filters Component -->
    <inventory-items-filters
      @modify="updateFilters"
      @excelError="(error) => $refs.feedback.handleError($t('inventory.items.messages.error.downloadFile'), error)"
      @dateChanged="handleDateChanged"
      :show-edit-icon.sync="showEditIcon"
    ></inventory-items-filters>

    <v-card-title>
      <div class="title">
        <v-icon left>mdi-warehouse</v-icon>{{$t('inventory.items.tableTitle')}}
      </div>
      <v-spacer></v-spacer>

      <v-tooltip bottom :disabled="!itemsDistributionCenter.buttonDisabled">
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn
                color="error"
                :disabled="itemsDistributionCenter.buttonDisabled"
                @click="openDialogRemove('all')"
                class="mx-2 my-2"
                >
              <v-icon left>delete</v-icon>{{ $t('buttons.products.remove') }}
            </v-btn>
          </div>
        </template>
        <span >{{ $t('inventory.items.tooltips.onlyDistributionCenter') }}</span>
      </v-tooltip>

      <v-tooltip bottom :disabled="!itemsDistributionCenter.buttonDisabled">
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn
                color="success"
                :disabled="itemsDistributionCenter.buttonDisabled"
                @click="itemsDistributionCenter.visibleDialogAdd = true"
                class="ma-2"
                >
              <v-icon left>add</v-icon>{{ $t('buttons.products.add') }}
            </v-btn>
          </div>
        </template>
        <span >{{ $t('inventory.items.tooltips.onlyDistributionCenter') }}</span>
      </v-tooltip>

      <remove-inventory-items-dialog :Visible="itemsDistributionCenter.visibleDialogRemove "
        :Item="itemsDistributionCenter.item"
        :Message="itemsDistributionCenter.dialogRemoveMessage"
        :RequestType="itemsDistributionCenter.dialogRemoveType"
        @close="closeDialog()"
      />

      <add-inventory-items-dialog :Visible="itemsDistributionCenter.visibleDialogAdd "
        :DistributionCenterId="itemsDistributionCenter.item.distributionCenterId"
        @close="closeDialog()"
      />
      <v-form ref="form" lazy-validation v-model="search.valid" @submit.prevent="getInventoryItems">
        <v-text-field
          class="ma-2 pt-0"
          v-model="search.text"
          append-icon="search"
          :label="this.$t('filters.labels.searchProducts')"
          single-line
          hide-details
          clearable
          filled
          rounded
          dense
          @click:clear="search.text = ''; getInventoryItems()"
          @click:append="options.page = 1; getInventoryItems()"
          @input="options.page = 1"
        ></v-text-field>
      </v-form>
    </v-card-title>
    <v-divider></v-divider>

    <v-data-table
      :loading="loading.inventoryItems"
      :headers="filteredHeaders"
      :items="inventoryItems"
      :options.sync="options"
      :server-items-length="totalItems"
      :footer-props="{ itemsPerPageOptions: [ 5, 10, 20, 30, 50, 100] }"
    >
      <template v-slot:[`item.productCode`]="{ item }">
        {{ item.productCode !== null ? item.productCode : '-' }}
      </template>

      <template v-slot:[`item.productDescription`]="{ item }">
        {{ item.productDescription !== null ? item.productDescription : '-' }}
      </template>

      <template v-slot:[`item.productCategoryName`]="{ item }">
        {{ item.productCategoryName !== null ? item.productCategoryName : '-' }}
      </template>

      <template v-slot:[`item.averageCost`]="{ item }">
        {{$currencyFormatter.format(item.averageCost)}}
      </template>

      <template v-slot:[`item.totalCost`]="{ item }">
        {{$currencyFormatter.format(item.totalCost)}}
      </template>

      <template v-slot:[`item.productBarCode`]="{ item }">
        {{ item.productBarCode !== null ? item.productBarCode : '-' }}
      </template>

      <template v-slot:[`item.dateLastInventory`]="{ item }">
        <span>{{ item.dateLastInventory !== null ? formatDate(item.dateLastInventory) : '-'}}</span>
      </template>

      <template v-slot:[`item.lastPurchaseCost`]="{ item }">
        {{ item.lastPurchaseCost !== null && item.inventoryType === 'DistributionCenter'
        ? $currencyFormatter.format(item.lastPurchaseCost) : "-" }}
      </template>

      <template v-slot:[`item.minimumPurchaseQuantity`]="{ item }">
        {{ item.minimumPurchaseQuantity || '-' }}
        <v-btn
          v-if="showEditIcon"
          @click.stop="showMinimumQuantityDialog(item)"
          color="info"
          icon
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.bin`]="{ item }">
        {{ item.bin || '-' }}
        <v-btn
          v-if="item.inventoryType == 'DistributionCenter'"
          @click.stop="showStockAddressDialog(item)"
          color="info"
          icon
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.minimumQuantity`]="{ item }">
        <td :class="{ 'red-cell': minimumCriticalAlert(item) }">
          {{ item.minimumQuantity || minimumQuantityNull(item.minimumQuantity) }}
          <v-btn
            v-if="showEditIcon"
            @click.stop="showCriticalMinimumDial(item)"
            color="info"
            icon>
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </td>
      </template>

      <template v-if="inventoryItems.length > 0" slot="body.append">
        <tr>
          <td style="font-weight: bold">{{$t('tables.total')}}</td>
          <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td v-if="alignTotal"></td>
          <td></td>
          <td style="font-weight: bold">{{ $currencyFormatter.format(totals.totalAverageCost ) }}</td>
          <td style="font-weight: bold">{{ totals.totalQuantity }}</td>
          <td style="font-weight: bold">{{ totals.totalReservedQuantity }}</td>
        </tr>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip :disabled="verifySpecificRemoveEnable(item)" top>
          {{ $t('inventory.items.tooltips.notDeleteInventoryPDV') }}
          <template #activator="{ on: tooltip }">
          <span v-on="tooltip">
            <v-btn :disabled="!verifySpecificRemoveEnable(item)"
                @click="openDialogRemove('specific', item)"
                class="mx-0" icon>
              <v-icon color="error">delete</v-icon>
            </v-btn>
            </span>
          </template>
          </v-tooltip>
        </template>

      <template
          v-if="userIsManagerHierarchy"
          v-slot:[`item.quantity`]="{ item }">
            {{ item.quantity || '-' }}
        <v-btn
          v-if="showEditIcon"
          @click.native="editQuantity(item)"
          color="info"
          icon>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <minimum-quantity-dialog
      v-model="minimumQuantityDialog"
      :productConversionUnit="productConversionUnit"
      @close="closeMinimumQuantityDialog"
      @update="updateEntry"
    ></minimum-quantity-dialog>

    <stock-address-dialog
      v-model="stockAddressDialog"
      :stockAdressItem="stockAdressItem"
      @close="closeStockAddressDialog"
      @update="updateEntryStockAddress"
    ></stock-address-dialog>

    <critical-minimum
      v-model="criticalMinimumDialog"
      :criticalMinimumItem="criticalMinimumItem"
      @close="closeDialogCriticalMinimum"
      @update="updateCriticalMinimum">
    </critical-minimum>

    <change-stock-quantity-dialog
      v-model="quantityDialog"
      :item="editQuantityDialog"
      @update="updateQuantity">
    </change-stock-quantity-dialog>

    <feedback ref="feedback"></feedback>
  </v-card>
</template>
<script>
import { shouldRefreshData, toApiPagination } from '@/components/TableOptionsComparator';
import InventoryItemsFilters from './components/InventoryItemsFilters.vue';
import moment from 'moment';
import MinimumQuantityDialog from './components/InventoryItemPurchaseQuantityDialog.vue';
import StockAddressDialog from './components/InventoryItemStockAddressDialog.vue';
import RemoveInventoryItemsDialog from './components/RemoveInventoryItemsDialog.vue';
import AddInventoryItemsDialog from './components/AddInventoryItemsDialog.vue';
import agent from '@/api/agent';
import CriticalMinimum from './components/InventoryItemCriticalMinimumDialog.vue';
import ChangeStockQuantityDialog from '@/views/inventory/components/ChangeStockQuantityDialog.vue'
import { MANAGER_HIERARCHY} from '@/constants/Roles';

export default {
  components: {
    InventoryItemsFilters,
    MinimumQuantityDialog,
    StockAddressDialog,
    RemoveInventoryItemsDialog,
    AddInventoryItemsDialog,
    CriticalMinimum,
    'change-stock-quantity-dialog': ChangeStockQuantityDialog,
  },
  computed: {
    userIsManagerHierarchy() {
      return MANAGER_HIERARCHY.some(role => this.$auth.check(role));
    },
  },
  data() {
    return{
      itemsDistributionCenter: {
        item: {distributionCenterId: null, productId: null},
        buttonDisabled: true,
        visibleDialogRemove: false,
        dialogRemoveType: null,
        dialogRemoveMessage: null,
        visibleDialogAdd: false,
      },
      distributionCenterIdList: [],
      inventoryItems: [],
      totals: {},
      headers: [
        { text: this.$t('tables.headers.productCode'), align: 'left', sortable: true, value: 'productCode'},
        { text: this.$t('tables.headers.description'), align: 'left', sortable: true, value: 'productDescription' },
        { text: this.$t('tables.headers.productCategory'), align: 'left', sortable: true, value: 'productCategoryName' },
        { text: this.$t('tables.headers.barCode'), align: 'left', sortable: true, value: 'productBarCode' },
        { text: this.$t('tables.headers.associatedPos'), align: 'left', sortable: false, value: 'associatedPointOfSale'},
        { text: this.$t('tables.headers.inventory'), align: 'left', sortable: true, value: 'inventoryName'},
        { text: this.$t('tables.headers.inventoryAddress'), align: 'left', sortable: false, value: 'bin' },
        { text: this.$t('tables.headers.lastInventory'), align: 'left', sortable: true, value: 'dateLastInventory'},
        { text: this.$t('tables.headers.lastPurchaseCost'), align: 'left', sortable: true, value: 'lastPurchaseCost'},
        { text: this.$t('tables.headers.meanCost'), align: 'left', sortable: true, value: 'averageCost' },
        { text: this.$t('tables.headers.totalCost'), align: 'left', sortable: false, value: 'totalCost' },
        { text: this.$t('tables.headers.quantity'), align: 'left', sortable: true, value: 'quantity' },
        { text: this.$t('tables.headers.reserved'), align: 'left', sortable: true, value: 'reservedQuantity' },
        { text: this.$t('tables.headers.minimumCritical'), align: 'left', sortable: true, value: 'minimumQuantity' },
        { text: this.$t('tables.headers.minimumQuantity'), align: 'left', sortable: true, value: 'minimumPurchaseQuantity' },
        { text: this.$t('tables.headers.actions'), align: "center", sortable: false, value: "actions", width: 150},
      ],
      loading: {
        inventoryItems: true,
      },
      form: {},
      search: {
        text: '',
        valid: true
      },
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['quantity'],
        sortDesc: [false],
        mustSort: true
      },
      conversionUnits: [],
      productConversionUnit: {
        productConversionUnitName: '',
        productConversionUnitRatio: 1,
        minimumPurchaseQuantity: null,
        id: null
      },
      stockAdressItem: {
        stockAddress: '',
        id: null
      },
      criticalMinimumItem:{
        criticalMinimum: '',
        id: null
      },
      minimumQuantityDialog: false,
      stockAddressDialog: false,
      alignTotal: false,
      criticalMinimumDialog: false,
      filteredHeaders: [],
      lastPurchaseCost: 0,
      quantityDialog: false,
      editQuantityDialog: {
        inventoryItemId: null,
        currentQuantity: null,
        },
      showEditIcon: true,
      }
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getInventoryItems();
        }
      },
    },
    'form.date'(value) {
      const onlyTodaySorting = ['lastPurchaseCost', 'minimumQuantity', 'minimumPurchaseQuantity']
      const isToday = moment().isSame(value, 'date')

      if (isToday) {
        return this.headers.map(h => {
          if (onlyTodaySorting.includes(h.value)) {
            h.sortable = true;
          }
        })
      }

      this.headers.map(h => {
        if (onlyTodaySorting.includes(h.value)) {
          h.sortable = false;
        }
      })

      if (onlyTodaySorting.some(s => this.options.sortBy.includes(s))) {
        this.options.sortBy.pop()
      }
    }
  },
  mounted() {

    this.getInventoriesCentral();
  },
  methods: {
    updateFilters(form) {
      this.options.page = 1;
      this.form = form;
      this.filteredHeaders = this.form.inventoryTypes.includes("distributionCenter") ? this.headers : this.headers.filter(h => h.value != 'bin' && h.value != 'actions');
      this.getInventoryItems();
    },
    getInventoryItems() {
      this.alignTotal = this.form.inventoryTypes.includes("distributionCenter") ? true: false;
      this.loading.inventoryItems = true;

      let params = toApiPagination(this.options, this.search.text);
      this.insertFiltersFromForm(params);

      this.axios
          .get('api/web/inventory/items', { params: params })
          .then((response) => {
            this.loading.inventoryItems = false;
            this.inventoryItems = response.data.items;
            this.totalItems = response.data.totalItems;
            this.totals = response.data.totals;
        }).catch((error) => this.$refs.feedback.handleError(this.$t('inventory.items.messages.error.consult'), error));
    },
    insertFiltersFromForm(params) {
      const form = this.form;
      const date = new Date(form.date);

      params.inventoryIds = form.inventoryIds;
      params.productId = form.productId;
      params.inventoryTypes = form.inventoryTypes;
      params.date = date;
      params.timezoneOffset = new Date().getTimezoneOffset();

      this.enableButton(params.inventoryIds, params.inventoryTypes);

      return params;
    },
    formatDate(date) {
      return moment(date).isValid() ? moment(date).format('DD/MM/YYYY HH:mm:ss') : "";
    },
    showMinimumQuantityDialog(item) {
      this.minimumQuantityDialog = true;
      this.productConversionUnit.productConversionUnitName = item.productConversionUnitName;
      this.productConversionUnit.productConversionUnitRatio = item.productConversionUnitRatio;
      this.productConversionUnit.minimumPurchaseQuantity = item.minimumPurchaseQuantity;
      this.productConversionUnit.id = item.id;
    },
    closeMinimumQuantityDialog() {
      this.minimumQuantityDialog = false;
      this.productConversionUnit.productConversionUnitName = '';
      this.productConversionUnit.productConversionUnitRatio = 1;
      this.productConversionUnit.minimumPurchaseQuantity = null;
      this.productConversionUnit.id = null;
    },
    showStockAddressDialog(item) {
      this.stockAddressDialog = true;
      this.stockAdressItem.stockAddress = item.bin;
      this.stockAdressItem.id = item.id;
    },
    closeStockAddressDialog() {
      this.stockAddressDialog = false
      this.stockAdressItem.stockAddress = '';
      this.stockAdressItem.id = null;
    },
    showMinimumQuantityDial(item) {
      this.criticalMinimumDialog = true;
      this.productConversionUnit.productConversionUnitName = item.productConversionUnitName;
      this.productConversionUnit.productConversionUnitRatio = item.productConversionUnitRatio;
      this.productConversionUnit.minimumQuantity = item.minimumQuantity;
      this.productConversionUnit.id = item.id;
    },
    closeCriticalMinimumDial() {
      this.criticalMinimumDialog = false;
      this.productConversionUnit.productConversionUnitName = '';
      this.productConversionUnit.productConversionUnitRatio = 1;
      this.productConversionUnit.minimumPurchaseQuantity = null;
      this.productConversionUnit.id = null;
    },
    showCriticalMinimumDial(item) {
      this.criticalMinimumDialog = true;
      this.criticalMinimumItem.minimumQuantity = item.minimumQuantity;
      this.criticalMinimumItem.id = item.id;
    },
    closeDialogCriticalMinimum() {
      this.criticalMinimumDialog = false
      this.criticalMinimumItem.minimumQuantity = '';
      this.criticalMinimumItem.id = null;
    },
    updateEntry() {
      this.$refs.feedback.handleSuccess(this.$t('inventory.items.messages.success.minimumQuantityUpdated'));
      this.getInventoryItems();
    },
    updateEntryStockAddress() {

      this.$refs.feedback.handleSuccess(this.$t('inventory.items.messages.success.addressUpdated'));
      this.getInventoryItems();
    },
    updateCriticalMinimum() {
      this.$refs.feedback.handleSuccess(this.$t('inventory.items.messages.success.minimumCriticalUpdated'));
      this.getInventoryItems();
    },
    updateQuantity() {
      this.$refs.feedback.handleSuccess(this.$t('inventory.items.messages.success.quantityUpdated'));
      this.getInventoryItems();
    },
    getInventoriesCentral(){
          agent.Inventory.getInventories( { centralOnly: true }).then(response => {

          this.distributionCenterIdList = response.map(inventory => inventory.id);
          this.enableButton();

        }).catch();
    },
    enableButton(inventoryId = this.distributionCenterIdList, inventoryType = ['distributionCenter']){
      inventoryType = inventoryType.toString().toLowerCase();
      this.itemsDistributionCenter.buttonDisabled = true;

      if((inventoryId.length === 0 && this.distributionCenterIdList.length === 1 && inventoryType.includes('distributioncenter')) || (inventoryId.length === 1
       && this.distributionCenterIdList.includes(inventoryId[0])))
      {
        this.itemsDistributionCenter.buttonDisabled = false;
        this.itemsDistributionCenter.item.distributionCenterId = inventoryId.length === 1 ? inventoryId[0]: this.distributionCenterIdList[0];
      }
    },
    openDialogRemove(type, item = null){
        if(type == "all"){
          this.itemsDistributionCenter.dialogRemoveType = "all";
          this.itemsDistributionCenter.dialogRemoveMessage = this.$t('inventory.items.dialogs.removeProduct.all');
          this.itemsDistributionCenter.visibleDialogRemove = true;
        }
        else{
          this.itemsDistributionCenter.dialogRemoveType = "specific";
          this.itemsDistributionCenter.dialogRemoveMessage = this.$t('inventory.items.dialogs.removeProduct.specific',  {product: item.productDescription });
          this.itemsDistributionCenter.item.productId = item.productId;
          this.itemsDistributionCenter.visibleDialogRemove = true;
        }
    },
    closeDialog(){
      this.itemsDistributionCenter.visibleDialogRemove = false;
      this.itemsDistributionCenter.visibleDialogAdd = false;
      this.getInventoryItems();
    },
    verifySpecificRemoveEnable(item){
      return (item.inventoryType.toString().toLowerCase() === 'distributioncenter')
    },
    minimumCriticalAlert(item) {
      return item.minimumQuantity === null ? false : item.minimumQuantity >= item.quantity;
    },
    minimumQuantityNull(minimumQuantity) {
      if(minimumQuantity === null){
        return '-'
      }
      else{
        return minimumQuantity
      }
    },
    editQuantity(item) {
      this.editQuantityDialog.inventoryItemId = item.id;
      this.editQuantityDialog.currentQuantity = item.quantity;
      this.quantityDialog = true;
    },
    handleDateChanged(isDifferentDate) {
      this.showEditIcon = !isDifferentDate;
    },
  },
};
</script>

<style scoped>
.red-cell {
  color: red;
  font-weight: bold;
}
</style>
